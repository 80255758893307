import React from 'react';
import styled from 'styled-components';
import AnimatedPage3 from '../../elements/AnimatedPage3';
import { HashLink } from 'react-router-hash-link';

const Wrapper = styled.div`
@media screen and (max-width: 800px){
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 0 0 54px 0;
    display: flex;
    align-items: center; 
    justify-content: top;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
  @media screen and (min-width: 800px){
    position: absolute;
    top: 0;
    width: 95%;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 0 0 54px 0;
    display: flex;
    align-items: center; 
    justify-content: top;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
`

const Content = styled.div`
    @media screen and (max-width: 800px){
    position: relative;
    width: 90%;
    min-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 1rem;
    background: linear-gradient(270deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    & video {
        width: 100%;
        border-radius: 8px;
    }
    & span {
        color: rgb(225, 225, 225, 0.7);
        font-size: 14px;
    }
    & spann {
        color: red;
        opacity: 0.7;
        font-size: 14px; 
    }
    & ul {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0 0 0 1rem;
        color: rgb(225, 225, 225, 0.7);
        font-size: 14px;
    }
    & img {
        width: 100%;
        border-radius: 8px;
        margin: 8px 0;
    }
    }

    @media screen and (min-width: 800px){
    position: relative;
    width: 60%;
    min-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 1rem;
    background: linear-gradient(270deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    & video {
        width: 90%;
        border-radius: 8px;
        margin-bottom: 1rem;
    }
    & span {    
        padding: 5px 10px;
        color: rgb(225, 225, 225, 0.7);
        font-size: 14px;
    }
    & spann {
        color: red;
        padding: 5px 10px;
        opacity: 0.7;
        font-size: 14px; 
    }
    & ul {
        position: relative;
        width: 100%;
        padding: 0 1rem;
        margin: 0;
        color: rgb(225, 225, 225, 0.7);
        font-size: 14px;
    }
    & img {
        width: 90%;
        border-radius: 8px;
        margin: 8px 0;
    }
    }
`

const Left = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
`

const Location = styled.div`
 @media screen and (max-width: 800px){
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 1rem;
 }
 @media screen and (min-width: 800px){
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 70%;
    padding: 0 1rem;
 }
`

const LocationBtn = styled.button`
@media screen and (max-width: 800px){
    position: relative;
    display: flex;
    width: 45%;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    color: rgb(225, 225, 225, 0.7);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.25),
    0 0 5px rgba(255, 255, 255, 0.15),
    0 0 7px rgba(255, 255, 255, 0.07),
    0 0 11px rgb(246, 161, 35,0.15),
    0 0 15px rgb(246, 161, 35,0.15);
    font-size: 14px;
    font-weight: 600;
    background: rgb(255, 225, 225, 0.1);
    border: none;
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2); 
    cursor: pointer;  
    transition: all 0.3s linear;

    & a {
        color: rgb(225, 225, 225, 0.7);
    text-decoration: none;
    }

    & HashLink {
        width: 100%;
    }
}

@media screen and (min-width: 800px){
    position: relative;
    display: flex;
    width: 12rem;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0 10px 10px 10px;
    color: rgb(225, 225, 225, 0.7);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.25),
    0 0 5px rgba(255, 255, 255, 0.15),
    0 0 7px rgba(255, 255, 255, 0.07),
    0 0 11px rgb(246, 161, 35,0.15),
    0 0 15px rgb(246, 161, 35,0.15);
    font-size: 14px;
    font-weight: 600;
    background: rgb(255, 225, 225, 0.1);
    border: none;
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2); 
    cursor: pointer;  
    transition: all 0.3s linear;

    & a {
        color: rgb(225, 225, 225, 0.7);

    text-decoration: none;
    transition: all 0.3s linear;
    }

    &:hover {
        transition: all 0.3s linear;
        & a {
            color: rgb(255, 165, 0, 0.8);
            transition: all 0.3s linear;
        }
    }
}
`

const Title = styled.h2`
@media screen and (max-width: 800px){
    text-align: center;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 165, 0, 0.8);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
}

@media screen and (min-width: 800px){
    text-align: center;
    position: relative;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 165, 0, 0.8);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
}
`

const InnerTitle = styled.h3`
@media screen and (max-width: 800px){
    text-align: center;
    position: relative;
    padding: 5px 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 225, 225, 0.7);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
}

@media screen and (min-width: 800px){
    text-align: center;
    position: relative;
    padding: 1rem 0;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 225, 225, 0.7);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
}
`

const Warning = styled.h3`
@media screen and (max-width: 800px){
    text-align: center;
    position: relative;
    padding: 5px 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 3px;
    color: red;
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
}

@media screen and (min-width: 800px){
    text-align: center;
    position: relative;
    padding: 5px 0;
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 3px;
    color: red;
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
}
`

function About() {
    return (
        <AnimatedPage3>
        <Wrapper>
            <Title>Navigation</Title>
        <Location>
            <LocationBtn><HashLink smooth to="#Ultimate">Ultimate</HashLink></LocationBtn>
            <LocationBtn><HashLink smooth to="#Mempool">Mempool</HashLink></LocationBtn>
            <LocationBtn><HashLink smooth to="#BlockBot">Block Bot</HashLink></LocationBtn>
            <LocationBtn><HashLink smooth to="#PresaleBots">Presale Bots</HashLink></LocationBtn>
            <LocationBtn><HashLink smooth to="#Whalebot">Whalebot</HashLink></LocationBtn>
            <LocationBtn><HashLink smooth to="#NFTBot">Nft Bots</HashLink></LocationBtn>
        </Location>
        <Title>Demo Videos</Title>
        <Content>
        <InnerTitle>Fully Automatic Bypassing of AntiBot</InnerTitle>
        <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/autoSnipe.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>Bypassing maxTxAmount</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/bypass_maxTxAmount.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>Fully Dynamic Anti-Rug</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/antiRug.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>PinkSale snipe on 5 wallets</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/pinkSale.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>Mempool Sniping (through our regular nodes)</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/Mempool_SnipeLiq.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>BlockBot Sniping</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/blockBot.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>Whalebot</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/whaleBot.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>NFTBot Multi Wallet Mempool Snipe</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/nftBot_MultiWallet.mp4"
            type="video/mp4"
             />
        </Content>
        <Content>
            <InnerTitle>NFTBot bypassing maxMint Amount</InnerTitle>
            <video
            // autoPlay
            controls
            playsInline
            loop
            muted
            src="/video/nftBot_antiMaxMint.mp4"
            type="video/mp4"
             />
        </Content>
        <Title>Bot Descriptions</Title>
        <Content id="Ultimate">
            <InnerTitle>Ultimate (100 BNB - 5 NFTs)</InnerTitle>
            <Left>
            <span>The ultimate tier consists of every BSC bot we offer and grants you access to the Proficy Node Network. 
            This tier also grants you full access to any BSC bot we develop in the future.</span>
            <span>It is designed for the user who wants to be fully armed and ready to utilize the complete 
            range of tools to maximize gains.</span>
            <span>This tier eliminates the need to purchase all products individually and rewards the user with a discount of 10 BNB.</span>
            <span>You will receive one of only five available NFTs. You may swap out your ultimate NFT for a collection of the 
            individual NFTs it consists of and sell any single products you have no use for. However, this will 
            burn your ultimate NFT, further decreasing the already very low supply.</span>
            </Left>
        </Content>
        <Content id="Custom">
            <InnerTitle>Custom Bot</InnerTitle>
            <Left>
            <span>If none of our available bots fits your needs, you are welcome to contact us on Telegram and tell us about your 
            wishes. Whether you want a different number of wallets, special methods, or even a bot for a very special use case 
            – we are open to discussion.§§</span>
            </Left>
        </Content>
        <Content id="Mempool">
            <InnerTitle>Mempool Pro</InnerTitle>
            <Left>
            <span>This is our most sophisticated bot, as it will get you into any launch as one of the first transactions once the Proficy 
            Node Network is established. It is based on two parts: local code and your own smart contract on the blockchain. You 
            might have seen snipers on other launches that don’t buy through the PancakeSwap router, but rather through another 
            smart contract, outperforming everyone else – that is what you’re getting here.</span>
            <span>It will scan the mempool until it finds the liquidity addition transaction and then place your transaction right behind. 
            Since this is a very powerful tool, we provide a very low supply to avoid creating too much competition between our community members.</span>
            <span>The greatest benefit of getting into the pro tier is that you’ll get access to the Proficy Node Network, which means maximum sniping performance.</span>
            </Left>
            <Warning>DISCLAIMER!</Warning>
            <Left>
            <spann>THE PROFICY NODE NETWORK IS NOT ESTABLISHED YET. THIS MIGHT LEAD TO INCONSISTENT PERFORMANCE WHEN ATTEMPTING TO SNIPE BLOCK 0 WHILE USING OUR CURRENT NODE SETUP.</spann>
            <spann>(It has the highest priority of all ongoing development and will be implemented immediately once sufficient funds are present)</spann>
            </Left>
            <InnerTitle>Pro Tier (50BNB – 10 NFTs)</InnerTitle>
            <ul>
              <li>Full access to Proficy Node Network</li>
              <li>Mempool Bot (snipes Block 0)</li>
              <li>Can also snipe every other block (like BlockPro)</li>
              <li>Mempool-based functionality</li>
              <li>Snipe launches on PinkSale, DxSale, Fairlaunch, etc.</li>
              <li>Track specific events triggered by the devs (in case liquidity is already added)</li>
              <li>SafeSnipe (never get trapped in antibot measures or honeypots, even if you are not using full auto mode)</li>
              <li>Fully Automatic AntiBot Bypass (never get trapped in antibot measures)</li>
              <li>Bypass MaxTxAmount: do multiple buys/sells in a single transaction</li>
              <li>Fully Dynamic Anti-Rug</li>
              <li>Anti Blacklist</li>
              <li>Automatic sell methods</li>
              <li>Automatic approval</li>
              <li>Manual buy and sell</li>
            </ul>
            <InnerTitle>Mempool High</InnerTitle>
            <span>The Mempool High tier offers the same functionality as the Pro tier. Partial access to the Proficy Node Network makes the difference. 
            This way it will perform way better than anyone with a normal node setup, but still allowing the Pro tier to stay ahead.</span>
            <InnerTitle>High Tier (25BNB – 20 NFTs)</InnerTitle>
            <ul>
              <li>Partial access to Proficy Node Network</li>
              <li>Mempool Bot (snipes block 0)</li>
              <li>Can also snipe every other block (like BlockPro)</li>
              <li>Mempool-based functionality</li>
              <li>Snipe launches on PinkSale, DxSale, Fairlaunch, etc.</li>
              <li>Track specific events triggered by the devs (in case liquidity is already added)</li>
              <li>SafeSnipe (never get trapped in antibot measures, even if you are not using full auto mode)</li>
              <li>Fully Automatic AntiBot Bypass On-Chain (never get trapped in antibot measures)</li>
              <li>Bypass MaxTxAmount: do multiple buys/sells in a single transaction</li>
              <li>Fully Dynamic Anti-Rug</li>
              <li>Anti Blacklist</li>
              <li>Automatic Sell Methods</li>
              <li>Automatic approval</li>
              <li>Manual buy and sell</li>
            </ul>
            <img src="/img/Mempool_1.jpg" alt="Mem1"/>
            <img src="/img/Mempool_2.jpg" alt="Mem2"/>
            <img src="/img/Mempool_3.jpg" alt="Mem3"/>
        </Content>
        <Content id="BlockBot">
            <InnerTitle>BlockBot</InnerTitle>
            <Left>
            <span>The Block Bot is based on mempool but uses slightly different logic. Its backbone is designed in such a way that no node network is needed, 
            although you are scanning the mempool for liquidity addition. Since this is a slightly different 
            version of the Mempool Bot, it won’t allow you to snipe on block 0, but it will guarantee you the absolute first spot in the block 
            after liquidity addition if you choose your gwei correctly.</span>
            <span>Buying the Block Bot also means that you are getting your very own smart contract to snipe through.</span>
            <span>Why would you want that? First of all, you will still be faster than anyone else using bots from competitors or people trying to get 
            in manually. But more importantly, it is a safe bet, because it combines mempool scanning with the usage of high gwei. A pure mempool 
            bot always adjusts the gwei to the exact same as the liquidity addition. In some cases, it happens that the liquidity addition 
            transaction is indexed as one of the very last transactions in a block, thus pushing everyone into the next block. Users who used 
            pure mempool are now in the next block, likely with 5 gwei, while you are there with 50 or even 100 gwei outperforming everyone and 
            getting in first.</span>
            </Left>
            <InnerTitle>Pro Tier (10BNB – 125 NFTs)</InnerTitle>
            <ul>
            <li>Snipes Block 1 (can snipe any other chosen block > 1)</li>
            <li>Mempool-based functionality</li>
            <li>Snipe launches on PinkSale, DxSale, Fairlaunch, etc.</li>
            <li>SafeSnipe (never get trapped in antibot measures, even if you are not using auto mode)</li>
            <li>Automatically bypass AntiBot measures Off-Chain (never get trapped in antibot measures)</li>
            <li>Bypass MaxTxAmount: do multiple buys/sells in a single transaction</li>
            <li>Fully Dynamic Anti-Rug</li>
            <li>Anti Blacklist</li>
            <li>Automatic Sell Methods</li>
            <li>Automatic approval</li>
            <li>Manual buy and sell</li>
            </ul>
        </Content>
        <Content id="PresaleBots">
            <InnerTitle>DxSale Bot / PinkSale Bot / Unicrypt Bot</InnerTitle>
            <Left>
            <span>The DxSale, PinkSale and Unicrypt bots allow you to snipe presales on the DxSale, PinkSale and Unicrypt platforms on the first block of presale start or the same 
            block a whitelisted presale changes to public. Paste the sale ID or URL, set your desired contribution amount to contribute, number of wallets, 
            and what gas to use and press start. It is fully automatic – you don’t have to be present while it is doing its job. Enjoy your life and come 
            back to a few wallets successfully in the presale. On top of that, your tokens will be automatically claimed faster than anyone else could ever do manually.</span>
            <span>
            It doesn’t matter if the presale is public, whitelist only or whitelist + public. The bot will detect if one or more of your wallets are 
            whitelisted, contribute those wallets during the whitelisted phase, and snipe with your remaining wallets when the presale becomes open 
            to the public. To ensure your wallets are not flagged as a bot, you can choose individual gas settings for each wallet.</span>
            <span>On Unicrypt you are able to snipe Round 1. (UNCX/UNCL needed)</span>
            <span>The bots also offer various sell methods, some even fully automatic. The fully Dynamic AntiRug system is also included to protect you from being scammed.</span>
            </Left>
            <InnerTitle>DxMulti/PinkBot/Unicrypt Multi (5BNB – 100 NFTs)</InnerTitle>
            <ul>
              <li>Up to 5 wallets possible</li>
              <li>No need for a dedicated node</li>
              <li>Uses Mempool for various functions</li>
              <li>Auto-Claim function</li>
              <li>Profit Ticker showing current profit</li>
              <li>Sell initial at X profit automatically</li>
              <li>Sell manually: Initial, All Wallets, One Wallet</li>
              <li>Fully Dynamic Anti-Rug</li>
              <li>Anti Blacklist</li>
              <li>Individual gas setting per wallet (you won’t be flagged as a bot)</li>
            </ul>
            <img src="/img/pink_1.jpg" alt="Pink1" />
            <img src="/img/pink_2.jpg" alt="Pink2" />
            <InnerTitle>Presale Pro (12BNB – 100 NFTs)</InnerTitle>
            <Left>
            <span>This version contains all 3 - Pinksale/DxSale and Unicrypt bot, while giving you a 3BNB discount. 
            It will also give you automatic access to any future presale bots for other platforms.</span>
            </Left>
        </Content>
        <Content id="Whalebot">
            <InnerTitle>Whalebot</InnerTitle>
            <Left>
            <span>People love to follow the trades of whales. The Whalebot allows you to track any wallet address, front run their transactions, 
            and put yours right in front of them with your desired amount of BNB. Let them do all the research to find a gem and just wait 
            for them to get a good entry – you’ll be right in front of them without a single second wasted digging through tons of shitcoins 
            to find a good one.</span>
            <span>The Whalebot also supports front-running sells. This comes in handy when you’ve staked a big claim on a coin and want to protect yourself 
            from being dumped on by top holders. Simply track their wallets and get out right before they sell their huge stack.</span>
            </Left>
            <InnerTitle>Multi Whale (5BNB – 100 NFTs)</InnerTitle>
            <ul>
            <li>Track up to 5 Wallets</li>
            <li>Scans Mempool for Tx by tracked wallets</li>
            <li>Front runs Tx on buys &amp; sells</li>
            <li>Allows you to track bots using individual methodIDs</li>
            <li>Set your own Buy/Sell conditions</li>
            </ul>
            <img src="/img/whalebot.jpg" alt="Whalebot" />
        </Content>
        <Content id="NFTBot">
            <InnerTitle>NFT Bot (BSC)</InnerTitle>
            <Left>
            <span>If you’ve ever taken part in an NFT launch, you might have experienced the horror of the gas price shooting 
            up to mint an NFT and your transaction failing with a big loss in gas fees. The NFT Bot will make sure that 
            you’re one of the absolute first to get your hands on your desired NFTs.</span>
            <span>The NFT bot uses mempool techniques to spot when minting is enabled, to get you in as fast as possible.
            If you’re attempting to snipe a mint event that places a limit on NFTs per transaction, the bot will bypass the 
            limit, allowing you to mint up to 50 NFTs in a single transaction by sniping through a unique smart contract.</span>
            <span><b>Note:</b> This feature is already fully functioning, as you can see in the demo video, and just needs to be implemented with fully adjustable user inputs.</span>
            <span>Check the development tab to see what is coming for the NFT Bot in the future.</span>
            </Left>
            <InnerTitle>NFT Bot (2ETH/10BNB – 150 NFTs)</InnerTitle>
            <ul>
              <li>Snipe with up to 5 wallets</li>
              <li>Bypass maxMint (50 mints/Tx instead of just a few)</li>
              <li>Scans Mempool to snipe</li>
              <li>Fully adjustable to every ERC721/ERC1155 contract</li>
              <li>Readjust your gas on all Tx with one click</li>
              <li>Cancel all Tx with one click</li>
              <li>Snipe Block 0 on minting enabled</li>
              <li>Snipe Block 1 with your desired gas settings</li>
            </ul>
            <img src="/img/nftbot_1.jpg" alt="NFT1" />
            <img src="/img/nftbot_2.jpg" alt="NFT2" />
            <img src="/img/nftbot_3.jpg" alt="NFT3" />
        </Content>
        </Wrapper>
        </AnimatedPage3>
    );
}

export default About