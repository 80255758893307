import React from "react";
import { ContactButton } from "./ContactButton.styled";
import { FaDiscord } from 'react-icons/fa';


function GithubBtn() {
    return(
        <ContactButton 
        onClick={() => {
            window.open("https://discord.gg/2PhCAtBD8w", "_blank");
          }}
        >
            <FaDiscord />
        </ContactButton>
    );
}

export default GithubBtn