import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

const RPC_URLS = {
	1: 'https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4',
	56: 'https://bsc-dataseed.binance.org',
	97: 'https://bsc.nodereal.io',
	31337: 'http://127.0.0.1:8545/'
};

export const injected = new InjectedConnector({
	supportedChainIds: [ 1, 56, 97, 31337],
	
});

export const walletconnect = new WalletConnectConnector({
	rpc: {
		1: RPC_URLS[1],
		56: RPC_URLS[56],
		97: RPC_URLS[97],
		31337: RPC_URLS[31337],
	},
	chainId: [1, 56, 97, 31337],
	qrcode: true,
	pollingInterval: 15000
});


export function resetWalletConnector(connector) {
	if (connector && connector instanceof WalletConnectConnector) {
		connector.walletConnectProvider = undefined;
	}
}
