import React from "react";
import AnimatedPage3 from '../elements/AnimatedPage3';
import { Wrapper, PageTitle, Abouts, Single } from './Styles';
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function SwapInfo() {
    return (
        <AnimatedPage3>
        <Wrapper>
        <PageTitle>Swap</PageTitle>
        <Abouts>
            <Single>
        <img src="/img/swap/swap.png" alt="Swap"/>
            </Single>
        <span>1. <b>Chain Selection</b>: Easily switch between chains by clicking on these icons. BSC is currently supported, but ETH, AVAX, and FTM are actively in development and will be added soon.</span>
        <span>2. <b><Link to="/swap/toolsInfo">Supplemental Tools</Link></b>: Proficy offers the following supplemental tools included with the swap:</span>
        <ul>
            <li>
            <span><b><HashLink smooth to='/swap/toolsInfo#Portfolio'>Portfolio</HashLink></b>: View your entire portfolio with the ability to favorite, delete, filter out honeypots, and sell all tokens within a given range with the "sell dust" feature.</span>
            </li>
            <li>
            <span><b><HashLink smooth to='/swap/toolsInfo#Transfer'>Transfer</HashLink></b>: Transfer your tokens to external wallets or between newly created wallets in ProficySwap.</span>
            </li>
            <li>
            <span><b><HashLink smooth to='/swap/toolsInfo#TokenData'>Token Data</HashLink></b>: View token info such as holders, market cap, liquidity lock information, user trades, and top holders.</span>
            </li>
            <li>
            <span><b>Charts</b>: View token charts directly in ProficySwap.</span>
            </li>
        </ul>
        <span>3. <b>Swap Setup</b>: Click this icon to open the <Link to="/swap/setupInfo">SETUP</Link> window.</span>
        <span>4. <b>Socials</b>: Learn more about Proficy by joining us on the following platforms:</span>
        <ul>
            <li>
            <span>Telegram:</span> <a href='https://t.me/ProficyNetworkPortal' target='_blank' rel="noreferrer">https://t.me/ProficyNetworkPortal</a>
            </li>
            <li>
            Discord: <a href='https://discord.gg/Ske27Qnmmn' target='_blank' rel="noreferrer">https://discord.gg/Ske27Qnmmn</a>
            </li>
        </ul>
        <span>5. <b>Tax Info</b>: Buy Tax / Sell Tax for each token displayed. This info updates in real time so you can easily watch and buy or sell if taxes are lowered. Note: you must have an amount in the input for the tax to update automatically.</span>
        <span>6. <b>General Swap Info</b>: Standard swap information is displayed here such as balance and price updates of the tokens selected. Click on the token symbol to choose a new token. Click on the chart icons to open DEX Screener charts for the selected token. Clicking the SWAP button will immediately send a transaction through a Proficy private node (or your own private node if you choose) immediately without the need for confirmation. This means a transaction should go through immediately without having to confirm and wait like you have to do in other swaps.</span>
        <span>7. <b>Swap Settings</b>: ProficySwap offers the following customized settings:</span>
        <ul>
            <li>
        <span><b><HashLink smooth to='/swap/advancedInfo#SafeBuy'>SafeBuy</HashLink></b>: If checked, SafeBuy will automatically protect you from buying honeypots, antibot, or high tax tokens.</span>
        </li>
        <li>
        <span><b>Bypass maxTx:</b> If this is checked, the swap will bypass maxTxAmount and do multiple buy/sell calls in one single Tx (be aware, every call costs gas).</span>         
        </li> 
        <li>
        <span><b>Gas Price:</b> Easily change your gas price (gwei) here without having to click through multiple windows like you would have to in MetaMask.</span>
        </li>
        </ul>
        <span>8. <b>Swap Info</b>: The following swap information is displayed here:</span>
        <ul>
            <li>
            <span><b>Route</b>: The best possible route that results in the most tokens received.</span>
            </li>
            <li>
            <span><b>Slippage</b>: Price change you are willing to accept while your Tx is processed.</span>
            </li>
            <li>
            <span><b>Verified</b>: If checked, ProficySwap will automatically check if the "to" (bottom) token is verified on the chain explorer (e.g., BscScan or Etherscan). If the scan website is slow or under mainteance, uncheck this checkbox (be aware, bypassing maxTx is not possible then).</span>
            </li>
            <li>
            <span><b>MaxTxAmount</b>: The maximum amount of tokens that can be bought/sold on the "to" token.</span>
            </li>
            <li>
            <span><b>Minimum received</b>: The minimum amount you receive/maximum amount you spend, if slippage is fully used.</span>
            </li>
            <li>
            <span><b>Price impact</b>: The price impact your Tx will have on the liquidity pool of the "to" token.</span>
            </li>
        </ul>
        </Abouts>
        </Wrapper>
        </AnimatedPage3>
    );
}