import React from "react";
import AnimatedPage3 from '../elements/AnimatedPage3';
import { Wrapper, PageTitle, Abouts, Title, Spacer, Single, Cut } from './Styles';

export default function AdvancedInfo() {
    return (
        <AnimatedPage3>
        <Wrapper>
        <PageTitle>ADVANCED USAGE</PageTitle>
        <Abouts id="ManualSniper">
            <Title>Manual Sniper</Title>
 <img src="/img/swap/manual.png" alt="Manual"/>
        <span>If you want to buy a launch and be one of the first to buy when liquidity is added or antibot measures are finished, you can put that token in the bottom "to" token and wait until the warning disappears. ProficySwap will automatically check every block until trading is enabled and it's safe to buy. Note: you must have an input in the top token for it to scan for liquidity being added.</span>
        </Abouts>
        <Spacer />
        <Abouts id="SafeBuy">
            <Title>SafeBuy</Title>
        <img src="/img/swap/safebuy.png" alt="SafeBuy"/>
        <span>In this example, the maximum buy fee is set to 5% in the settings tab on the right. After clicking swap, SafeBuy protects from buying since the selected token has a buy tax of 10%. This feature protects you from buying high tax tokens, honeypots, and tokens that have antibot measures in place.</span>        
        </Abouts>
        <Spacer />
        <Abouts id="BypassMaxTx">
            <Title>Bypass maxTx</Title>
        <Cut><Single><img src="/img/swap/maxtx.png" alt="MaxTx"/></Single></Cut>
        <span>Many tokens restrict buyers directly after launch by setting low maximum transaction amounts. ProficySwap allows you to bypass these limits by using a custom smart contract to make multiple transactions in one. Click on the dropdown next to the Bypass maxTx to view how many calls will be made in a single transaction or override this value yourself. Be aware that every call costs gas so your transaction fees will be higher than normal.</span>
        </Abouts>
        <Spacer />
        <Abouts id="SellDust">
            <Title>Sell Dust</Title>
        <Cut><Single> <img src="/img/swap/selldust1.png" alt="SellDust"/></Single></Cut>
        <span>To sell your dust, select a lower and upper range and click the Sell Dust button. Note: it's better to filter out honeypots before selling your dust.</span>
        <img src="/img/swap/selldust2.png" alt="SellDust"/>
        <span>After clicking Sell Dust, ProficySwap will simulate a buy and sell on each of the filtered tokens to see if the transaction would succeed or if it's worth the cost of gas to sell the tokens. Select all tokens and click Delete to move them to your deleted list.</span>
        <img src="/img/swap/selldust3.png" alt="SellDust"/>
        <span>Confirm that you would like to sell all the tokens displayed or unselect any tokens you'd like to keep.</span>
        <img src="/img/swap/selldust4.png" alt="SellDust"/>
        <span>ProficySwap will sell all selected dust tokens at once, displaying if each transaction succeeded or failed and the total recovered amount. In this example, 0.17 BNB was recovered from selling dust tokens.</span>
        <Cut><Single><img src="/img/swap/selldust5.png" alt="SellDust"/> </Single></Cut>
        <span>Checking the wallet in BscScan, you can see that all tokens were sold within seconds.</span>
        </Abouts>
        <Spacer />
        <Abouts id="Speedtest">
            <Title>Speedtest</Title>
        <img src="/img/swap/speedtest.png" alt="SpeedTest"/>
        <span>Clicking the SPEEDTEST button in SETUP will automatically test all Proficy private nodes and your own node if available and automatically select the fastest node for you. This ensures you have the fastest transaction speeds and swap updates. Using Proficy private nodes means you will have an advantage when networks are congested and others are relying on public RPC nodes.</span>
        </Abouts>
        <Spacer />
        <Abouts id="ChangeDesign">
            <Title>Change Design</Title>
        <img src="/img/swap/design.png" alt="SpeedTest"/>
        <span>The design of ProficySwap is highly customizable. Click the Change Design button in your settings to choose any color you desire.</span>
        </Abouts>
        </Wrapper>
        </AnimatedPage3>
    );
}