import { motion } from "framer-motion"
import styled from "styled-components";

const Wrapper = styled.div`
    position: absolute;
    margin-top: 4.7rem;
    width: 95%;
    height: calc(100% - 4.7rem);
    padding-bottom: 9rem;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 99;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: 800px){
        width: calc(100% - 25rem);
        left: 12.5rem;
    }

@media screen and (max-width: 480px) {
    .choose-app {
        height: 90%;
    }
}
`

const pageAnim = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.25,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.25,
        }
    },
};

const AnimatedBotsPage = ({children}) => {
    return (
        
        <Wrapper as={motion.div}
        variants={pageAnim}
        initial='initial'
        animate='animate'
        exit='exit'
        >
            {children}
        </Wrapper>       
    )
}

export default AnimatedBotsPage