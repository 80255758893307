import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Backdrop from '../Backdrop';
import { CgCloseO } from 'react-icons/cg';
import Connect from '../../web3/Connect';

const WalletModalStyled = styled.div`
    position: fixed;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -1px;
    top: 8rem;
    width: 22rem;
    height: 70%;
    background: rgb(68,68,68);
    border-radius: 2rem 0 0 2rem;
    background: linear-gradient(90deg, rgba(68,68,68,1) 0%, rgba(1,1,1,1) 100%);
    box-shadow: 3px 1px 30px 8px rgba(255,255,255,0.45);
    z-index: 150;

    @media screen and (max-width: 580px) {
        width: 80%;
        height: 65%;
    }
`

const CloseBtnStyled = styled.button`
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgba(255, 225, 225, 0.7);
    border: none;
    background: transparent;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s linear;

    @media screen and (max-width: 800px){
        right: 0.5rem;
    }

    &:hover {
        color: rgba(218, 14, 7, 0.8);
        transition: all 0.3s linear;
    }
`

const dropIn = {
    hidden: {
        x: "352px",
        opacity: 0,
    },
    visible: {
        x: "0",
        opacity: 1,
        transition: {
            type: 'spring',
            bounce: 0.15,
            duration: 0.4,
        }
    },
    exit: {
        x: "352px",
        opacity: 0,
    },
};

const WalletModal = ({ handleClose }) => {

    return (
        <Backdrop onClick={handleClose}>
            <WalletModalStyled
            as={motion.div}
            onClick={(e) => e.stopPropagation()}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
            >
               <CloseBtnStyled onClick={handleClose}><CgCloseO /></CloseBtnStyled> 
               <Connect handleClose={ handleClose } />
            </WalletModalStyled>
        </Backdrop>
    );

};

export default WalletModal