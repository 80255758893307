import React from "react";
import AnimatedPage3 from "../elements/AnimatedPage3";
import { BrowserView, MobileView } from "react-device-detect";
import { Pager, Title, BG, Slide } from "./Styles";
import { motion } from "framer-motion";
import Slider from "./Slider";
import SliderMobile from "./SliderMobile";
import bg1 from "./img/bg1.png";
import bg2 from "./img/bg2.png";
import bg3 from "./img/bg3.png";
import bg4 from "./img/bg4.png";
import bg5 from "./img/bg5.png";
 
export default function Intro() {
  const imageAnim = {
    initial: {
      scale: 0,
      opacity: 0,
      x: -1000,
    },
    animate: {
      scale: 1,
      opacity: 1,
      left: 0,
      x: 0,
      transition: {
        duration: 0.4,
      },
    },
    exit: {
      scale: 0,
      opacity: 0,
      x: -1000,
      transition: {
        duration: 0.4,
      },
    },
  };

  const textAnim = {
    initial: {
      scale: 0,
      opacity: 0,
      y: -1000,
    },
    animate: {
      scale: 1,
      opacity: 1,
      left: 0,
      y: 0,
      transition: {
        duration: 0.4,
      },
    },
    exit: {
      scale: 0,
      opacity: 0,
      y: -1000,
      transition: {
        duration: 0.4,
      },
    },
  };

  const slideData = [
    {
      image: bg1,
      title: "ALL-IN-ONE, MULTICHAIN APP FOR FAST, SAFE TRADING",
    },
    {
      image: bg3,
      title: "SNIPE LAUNCHES & BYPASS MAX TRANSACTION",
    },
    {
      image: bg4,
      title: "PORTFOLIO & BUILT-IN WALLET",
    },
    {
      image: bg5,
      title: "SELL ALL YOUR DUST WITH JUST A FEW CLICKS",
    },
    {
      image: bg2,
      title: "CRUCIAL ON-CHAIN TOKEN DATA & BUILT-IN CHARTS",
    },
  ];

  return (
    <AnimatedPage3>
      <BrowserView>
        <Pager>
          <Slider>
            {slideData.map((slides, index) => {
              return (
                <div slides={slides} key={index}>
                  <Slide key={`slide_${index}`}>
                    <Title
                      as={motion.div}
                      variants={textAnim}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      {slideData[index].title}
                    </Title>
                    <BG
                      as={motion.div}
                      variants={imageAnim}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      background={slideData[index].image}
                    ></BG>
                  </Slide>
                </div>
              );
            })}
          </Slider>
        </Pager>
      </BrowserView>
      <MobileView>
        <Pager>
          <SliderMobile>
            {slideData.map((slides, index) => {
              return (
                <div slides={slides} key={index}>
                  <Slide key={`slide_${index}`}>
                    <Title
                      as={motion.div}
                      variants={textAnim}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      {slideData[index].title}
                    </Title>
                    <BG
                      as={motion.div}
                      variants={imageAnim}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      background={slideData[index].image}
                    ></BG>
                  </Slide>
                </div>
              );
            })}
          </SliderMobile>
        </Pager>
      </MobileView>
    </AnimatedPage3>
  );
}
