import React, { useState, useEffect, useRef } from "react";
import {
  cleanCheck,
  checkAddress,
  getPrices,
  getMaxChars,
} from "../helpers/helpers";

import {
  PopUp,
  Title,
  ConfirmBtn4,
  Red,
  Green,
  CloseBtn,
  Input,
  Input2,
  Space,
  Select,
  PromoInput,
  Row,
  ConfirmBtn,
} from "../Styles";
import { BsCheck2Circle } from "react-icons/bs";
import { FaRegWindowClose } from "react-icons/fa";
import { VscCheckAll } from "react-icons/vsc";

import { Notyf } from "notyf";
import "../../bots/components/notification.css";

const notyf = new Notyf({
  duration: 8000,
  position: {
    x: "right",
    y: "top",
  },
  dismissible: true,
  ripple: true,
  types: [
    {
      type: "error",
      background: "#262626e1",
    },
    {
      type: "warning",
      background: "#262626e1",
      icon: false,
      duration: 20000,
    },
    {
      type: "success",
      background: "#262626e1",
      duration: 300000,
    },
  ],
});

const Popup = (props) => {
  const myRef = useRef();
  const [showError, setShowError] = useState(false);
  const [prices, setPrices] = useState({
    price1: "",
    price2: "",
    price3: "",
    price4: "",
  });
  const [maxChars, setMaxChars] = useState(55);

  useEffect(() => {
    getPrices(props.signer, setPrices);
    getMaxChars(props.signer, setMaxChars);
  });

  if (props.flag === "prio") {
    return (
      <PopUp ref={myRef}>
        <CloseBtn
          onClick={() => {
            props.setShowPopup(false);
          }}
        >
          <FaRegWindowClose />
        </CloseBtn>
        <Title>Increase Priority</Title>
        <Input
          placeholder="Fee (BNB)"
          id="priority"
          onChange={async () => {
            document.getElementById("priority").value = await cleanCheck(
              document.getElementById("priority").value
            );
          }}
        ></Input>
        <Space />
        <ConfirmBtn4
          onClick={() => {
            props.increasePrio(props.ID, props.signer);
          }}
        >
          <Green>
            <BsCheck2Circle />
          </Green>
          &nbsp;Confirm
        </ConfirmBtn4>
      </PopUp>
    );
  }
  if (props.flag === "revoke") {
    return (
      <PopUp ref={myRef}>
        <CloseBtn
          onClick={() => {
            props.setShowPopup(false);
          }}
        >
          <FaRegWindowClose />
        </CloseBtn>
        <Title>Are you sure you want to revoke promo?</Title>
        <ConfirmBtn4
          onClick={() => {
            props.revokePromo(props.ID, props.signer);
          }}
        >
          <Red>
            <BsCheck2Circle />
          </Red>
          &nbsp;Confirm
        </ConfirmBtn4>
      </PopUp>
    );
  }
  if (props.flag === "extend") {
    return (
      <PopUp ref={myRef}>
        <CloseBtn
          onClick={() => {
            props.setShowPopup(false);
          }}
        >
          <FaRegWindowClose />
        </CloseBtn>
        <Title>Extend Promo</Title>
        <Select id="duration">
          <option value="0" defaultValue={"0"}>
            1 Day - {prices.price1} BNB
          </option>
          <option value="1">2 Day - {prices.price2} BNB</option>
          <option value="2">3 Day - {prices.price3} BNB</option>
          <option value="3">1 Week - {prices.price4} BNB</option>
        </Select>
        <Space />
        <ConfirmBtn4
          onClick={() => {
            props.extendPromo(
              props.slot,
              document.getElementById("duration").value
            );
          }}
        >
          <Green>
            <BsCheck2Circle />
          </Green>
          &nbsp;Confirm
        </ConfirmBtn4>
      </PopUp>
    );
  }
  if (props.flag === "change") {
    return (
      <PopUp ref={myRef}>
        <CloseBtn
          onClick={() => {
            props.setShowPopup(false);
          }}
        >
          <FaRegWindowClose />
        </CloseBtn>
        <Title>Change Promo</Title>
        <PromoInput
          placeholder={"Promotion Message (max. " + maxChars + " characters.)"}
          spellCheck="false"
          id="message"
          maxLength={maxChars}
        ></PromoInput>
        <Row>
          <Input placeholder="Website" id="website"></Input>
          <Input2
            placeholder="Contract Address"
            id="contractAddress"
            onChange={() => {
              checkAddress(
                document.getElementById("contractAddress").value,
                setShowError
              );
            }}
          ></Input2>
        </Row>
        <Space />
        <ConfirmBtn
          onClick={() => {
            if (
              showError &&
              document.getElementById("contractAddress").value !== ""
            ) {
              return notyf.error("Invalid contract address! Try again");
            }
            if (document.getElementById("contractAddress").value === "") {
              return notyf.error("Type in contract address!");
            }
            if (
              document.getElementById("website").value === "" ||
              document.getElementById("website").value === null
            ) {
              return notyf.error(
                "You must enter link of your socials or website!"
              );
            }
            if (
              document.getElementById("message").value === "" ||
              document.getElementById("message").value === null
            ) {
              return notyf.error("Input your promotion message!");
            }

            props.changePromo(
              props.slot,
              props.signer,
              document.getElementById("message").value,
              document.getElementById("website").value,
              document.getElementById("contractAddress").value
            );
          }}
        >
          <VscCheckAll />
          &nbsp;Change Promotion
        </ConfirmBtn>
      </PopUp>
    );
  }
};

export default Popup;
