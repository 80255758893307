import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Intro from "./Intro";
import SwapHeader from "./SwapHeader";
import { BrowserView, MobileView } from "react-device-detect";
import SwapInfo from "./SwapInfo";
import SetupInfo from "./SetupInfo";
import ToolsInfo from "./ToolsInfo";
import AdvancedInfo from "./AdvancedInfo";
import Changelog from "./Changelog";
import Mint from "./Mint";
import Header from "../bots/Header";
import Nav from "./Nav";
import Downloads from "./Download";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../web3/connectors";

function Bots() {
  const location = useLocation();

  const web3reactContext = useWeb3React();

  const checktMetamask = async () => {
    if (localStorage.isWalletConnected === "true") {
      try {
        await web3reactContext.activate(injected);
      } catch (ex) {
        console.log(ex);
      }
    }
  };

  useEffect(() => {
    checktMetamask();
  }, []);

  return (
    <>
      <MobileView>
        <SwapHeader />
        <AnimatePresence exitBeforeEnter>
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<Intro />} />
            <Route path="/swapInfo" element={<SwapInfo />} />
            <Route path="/setupInfo" element={<SetupInfo />} />
            <Route path="/toolsInfo" element={<ToolsInfo />} />
            <Route path="/advancedInfo" element={<AdvancedInfo />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/changelog" element={<Changelog />} />
            <Route path="/mint/*" element={<Mint />} />
          </Routes>
        </AnimatePresence>
      </MobileView>
      <BrowserView>
        <Header />
        <Nav />
        <AnimatePresence exitBeforeEnter>
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<Intro />} />
            <Route path="/swapInfo" element={<SwapInfo />} />
            <Route path="/setupInfo" element={<SetupInfo />} />
            <Route path="/toolsInfo" element={<ToolsInfo />} />
            <Route path="/advancedInfo" element={<AdvancedInfo />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/changelog" element={<Changelog />} />
            <Route path="/mint/*" element={<Mint />} />
          </Routes>
        </AnimatePresence>
      </BrowserView>
    </>
  );
}

export default Bots;
