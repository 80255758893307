import React from 'react';
import Logo from '../elements/Logo'
import styled from 'styled-components';

const StyledHeader = styled.div`
    position: fixed;
    top: -2rem;
    margin:0 2% 0 2%;
    background: linear-gradient(180deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    width: 96%;
    height: 7rem;
    border-radius: 0 0 2em 2em;
    z-index: 100;
`

const LogoDiv = styled.div`
    position: absolute;
    left: 0;
    height: 7rem;
    width: 11rem;
    margin-left: 1rem;
    transition: all 0.3s linear;

    @media screen and (max-width: 800px){
        left: -1rem;
        margin-left: 1rem;
    }
`

function Header() {
    return (
        <StyledHeader>
            <LogoDiv>
            <Logo />
            </LogoDiv>
        </StyledHeader>
    );
} 

export default Header