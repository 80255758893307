import { motion } from "framer-motion"


const pageAnim = {
    initial: {
        x: -100,
        opacity: 0,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.8,
        }
    },
    exit: {
        x: -100,
        opacity: 0,
    },
};

const AnimatedPage = ({children}) => {
    return (
        <motion.div
        variants={pageAnim}
        initial='initial'
        animate='animate'
        exit='exit'
        >
            {children}
        </motion.div>       
    )
}

export default AnimatedPage