import React from "react";
import { ContactButton } from "./ContactButton.styled";
import { FaTelegram } from 'react-icons/fa';

function TelegramBtn() {
    return(
        <ContactButton
        onClick={() => {
            window.open("https://t.me/ProficySwap", "_blank");
          }}
        >
            <FaTelegram />
        </ContactButton>
    );
}

export default TelegramBtn