import React, { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import WalletModal from '../../elements/WalletModal';


const ConnectButton = styled.button`

  align-items: center;
  right: 0;
  bottom: 15px;
  padding: 12px;
  margin: 0 15px 0 0;
  border: 2px solid rgb(90, 90, 90);
  border-radius: 8px 8px 15px 15px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    padding: 10px;
  }
`

function ConnectBtn() {

  const [WalletModalOpen, setWalletModalOpen] = useState(false);
  const close = () => setWalletModalOpen(false);
  const open = () => setWalletModalOpen(true);

    return(
      <div>
      <ConnectButton as={motion.button} 
      onClick={() => (WalletModalOpen ? close() : open())} >
    Connect Wallet First
      </ConnectButton>  
      { WalletModalOpen && <WalletModal WalletModalOpen={WalletModalOpen} handleClose={close}/>}
      <AnimatePresence
      initial={false}
      exitBeforeEnter={false}
      onExitComplete={() => null}
      >
       { WalletModalOpen && <WalletModal WalletModalOpen={WalletModalOpen} handleClose={close}/>}
      </AnimatePresence>
      </div>
    );
}

export default ConnectBtn