import React, { useState }  from 'react';
import { motion, AnimatePresence } from "framer-motion";
import parse from 'html-react-parser';
import { Question, Answer } from './Styles';

const answerAnim = {
  initial: {
      y: -60,
      opacity: 0,
  },
  animate: {
      y: 0,
      opacity: 1,
      transition: {
          duration: 0.3,
      }
  },
  exit: {
      y: -60,
      opacity: 0,
      transition: {
        duration: 0.3,
    }
  },
};

const NftFAQ = ({ nftData, id }) => {

  const {
    question,
    answer,
  } = nftData;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
    <motion.div>
      <AnimatePresence>
        <Question as={motion.div}
        key={`nq_${id}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <motion.div>
          {parse(question)}
          </motion.div>

        </Question>

        {isOpen && (
          <motion.div
          key={`na_${id}`}
          variants={answerAnim}
          initial='initial'
          animate='animate'
          exit='exit'
          >
          <Answer>{parse(answer)}</Answer>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
    </>
  );
};

export default NftFAQ;