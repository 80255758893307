import React, {useEffect} from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import MakePromo from "./MakePromo";
import Header from "../bots/Header";
import { useWeb3React } from '@web3-react/core';
import { injected } from "../web3/connectors";

function Promo() {

    const location = useLocation();
    const web3reactContext = useWeb3React(); 

	const checktMetamask = async () => {
		if (localStorage.isWalletConnected === 'true') {
		try {
			await web3reactContext.activate(injected);
		} catch (ex) {
			console.log(ex);
		}
	}
	};

	useEffect(() => {
        checktMetamask()
    }, [])


    return (
        <>
        <Header />
        <Routes key={location.pathname} location={location}>
            <Route  path='/' element={<MakePromo />}/> 
            <Route  path='/ref/*' element={<MakePromo />}/> 
        </Routes>
        </>
    );
}

export default Promo