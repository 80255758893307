import './style.css';
import { Routes, Route } from 'react-router-dom';
import { Wave1, Wave2} from './background/Waves';
import Background from './background/Background';
import Footer from './elements/Footer';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import Landing from './landing/Landing';
import Bots from './bots/Bots';
import Swap from './swap/Swap';
import Promo from './promo/Promo';
import Tos from './swap/Tos'; 


function App() {

    const getLibrary = (provider) => {
		const library = new Web3Provider(provider, 'any');
		library.pollingInterval = 15000;
		return library;
  };

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <div className='App'>
            <Routes>
                <Route path='/' element={<Landing />}/>
                <Route path='/bots/*' element={<Bots />}/>
                <Route path='/swap/*' element={<Swap />}/>
                <Route path='/promo/*' element={<Promo />}/>
                <Route path='/tos' element={<Tos />}/> 
            </Routes>
            <Footer />
        <Wave1 />
        <Wave2 />
        <div className='background'>
          <Background />
        </div> 
        </div>
        </Web3ReactProvider>
    );
}

export default App