import React from 'react';
import AnimatedPage3 from '../../elements/AnimatedPage3';
import styled from 'styled-components';
import { FaWindows } from 'react-icons/fa';
import { SiApple } from 'react-icons/si';
import { SiLinux } from 'react-icons/si';
import { MdUpdate } from 'react-icons/md';
import { BrowserView, MobileView } from 'react-device-detect';
import Tilt from 'react-parallax-tilt';

const macDownload = "https://www.dropbox.com/s/csd05twixadxt1p/ProficySetup.dmg?dl=1";
const winDownload = "https://www.dropbox.com/s/q85glly1sc6cqj4/ProficySetup.exe?dl=1";
const linDownload = "https://www.dropbox.com/s/6mdxbzr9h5qx3kb/ProficySetup.tar.xz?dl=1";
const winDownloadEth = "https://www.dropbox.com/s/hbgh6vh0nh09d64/ProficyEthSetup.exe?dl=1";
    

const Wrapper = styled.div`

    position: absolute;
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-direction: column;
`

const Changelog = styled.div`
    top: -20px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
@media screen and (max-width: 800px){
    height: 3rem;
    & a {
        font-size: 1.4rem;
        font-weight: 600;
        text-decoration: none;
        color: rgba(255, 165, 0, 0.7);
        text-shadow:
            0 0 2px rgba(255, 255, 255, 0.3),
            0 0 5px rgba(255, 255, 255, 0.2),
            0 0 7px rgba(255, 255, 255, 0.1),
            0 0 11px rgb(246, 161, 35,0.25),
            0 0 15px rgb(246, 161, 35,0.2);
    }
    & svg {
        font-size: 1.8rem;
        color: rgba(255, 225, 225, 0.6);
        
    }
}

@media screen and (min-width: 800px){
    height: 3rem;
    transform: scale(1);
    transition: all 0.3s linear;
    & a {
        font-size: 1.4rem;
        font-weight: 600;
        text-decoration: none;
        color: rgba(255, 165, 0, 0.7);
        text-shadow:
            0 0 2px rgba(255, 255, 255, 0.3),
            0 0 5px rgba(255, 255, 255, 0.2),
            0 0 7px rgba(255, 255, 255, 0.1),
            0 0 11px rgb(246, 161, 35,0.25),
            0 0 15px rgb(246, 161, 35,0.2);
    }
    & svg {
        font-size: 1.8rem;
        color: rgba(255, 225, 225, 0.6);
        
    }

    &:hover {
        transform: scale(1.05);
        transition: all 0.3s linear;
    }
}
`

const DownloadWrapper = styled.div`

    @media screen and (max-width: 800px){
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    backdrop-filter: blur(5px);
    background: linear-gradient(180deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 2em;
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 8rem;
    z-index: 100;
    transition: all 0.3s linear;      
    }

    @media screen and (min-width: 800px){
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    background: linear-gradient(240deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 2em;
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    width: 32rem;
    height: 8rem;
    z-index: 100;
    opacity: 0.8;
    transition: all 0.3s linear;  
    
    & svg {
        font-size: 6rem;
        color: rgba(255, 225, 225, 0.6);
        transition: all 0.3s linear;  
    }
    
    &:hover {
        opacity: 1;
        transition: all 0.3s linear;   
        & svg {
        color: rgb(255, 165, 0, 0.6);  
        transition: all 0.3s linear;  
    }  
    }}
`

const OS = styled.svg`
    @media screen and (max-width: 800px){
    position: absolute;
    top: 1.5rem;
    color: rgb(255, 225, 225, 0.4);
    font-size: 5rem;
    }
    @media screen and (min-width: 800px){
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: rgb(255, 225, 225, 0.4);
    font-size: 5rem;
    }
`
const BSCBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 800px){
    position: absolute;
    right: 0;
    top: 0;
    width: 9rem;
    height: 40%;
    margin: 10px;
    background: rgba(225,225,225,0.25);
    border: none;
    box-shadow: 0px -1px 35px 12px rgba(0,0,0,0.25);
    border-radius: 8px 20px 8px 8px;
    color: rgba(255, 165, 0, 0.7);
    text-shadow:
            0 0 2px rgba(255, 255, 255, 0.3),
            0 0 5px rgba(255, 255, 255, 0.2),
            0 0 7px rgba(255, 255, 255, 0.1),
            0 0 11px rgb(246, 161, 35,0.25),
            0 0 15px rgb(246, 161, 35,0.2);
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
        transform: scale(1.02);
        transition: all 0.3s linear;
    }

    &:active {
        transform: scale(0.98);
        transition: all 0.3s linear;
    }
}
@media screen and (min-width: 800px){
    position: absolute;
    right: 0;
    top: 0;
    width: 9rem;
    height: 40%;
    margin: 10px;
    background: rgba(225,225,225,0.25);
    border: none;
    box-shadow: 0px -1px 35px 12px rgba(0,0,0,0.25);
    border-radius: 8px 20px 8px 8px;
    color: rgba(255, 165, 0, 0.7);
    text-shadow:
            0 0 2px rgba(255, 255, 255, 0.3),
            0 0 5px rgba(255, 255, 255, 0.2),
            0 0 7px rgba(255, 255, 255, 0.1),
            0 0 11px rgb(246, 161, 35,0.25),
            0 0 15px rgb(246, 161, 35,0.2);
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
        transform: scale(1.02);
        transition: all 0.3s linear;
    }

    &:active {
        transform: scale(0.98);
        transition: all 0.3s linear;
    }
}
`

const ETHBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 800px){
    position: absolute;
    right: 0;
    bottom: 0;
    width: 9rem;
    height: 40%;
    margin: 10px;
    background: rgba(225,225,225,0.25);
    border: none;
    box-shadow: 0px -1px 35px 12px rgba(0,0,0,0.25);
    border-radius: 8px 8px 20px 8px;
    color: rgb(0, 189, 240);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
        transform: scale(1.02);
        transition: all 0.3s linear;
    }

    &:active {
        transform: scale(0.98);
        transition: all 0.3s linear;
    }
}
@media screen and (min-width: 800px){
    position: absolute;
    right: 0;
    bottom: 0;
    width: 9rem;
    height: 40%;
    margin: 10px;
    background: rgba(225,225,225,0.25);
    border: none;
    box-shadow: 0px -1px 35px 12px rgba(0,0,0,0.25);
    border-radius: 8px 8px 20px 8px;
    color: rgb(0, 189, 240);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
        transform: scale(1.02);
        transition: all 0.3s linear;
    }

    &:active {
        transform: scale(0.98);
        transition: all 0.3s linear;
    }
}
`

function Downloads() {
    return (
        <>
            <MobileView>
        <AnimatedPage3>
            <Wrapper>
        <Changelog><MdUpdate /><a href='https://github.com/proficy/documentation/blob/main/README.md#changelog' target='_blank' rel="noreferrer">Changelog</a></Changelog>
            <DownloadWrapper>
                <OS><FaWindows /></OS>
                <BSCBtn
                onClick={() => window.open(winDownload, "https://www.dropbox.com/s/q85glly1sc6cqj4/ProficySetup.exe?dl=1")}
                >BSC</BSCBtn>
                <ETHBtn
                onClick={() => window.open(winDownloadEth, "_blank")}
                >ETH</ETHBtn>
            </DownloadWrapper>
            <DownloadWrapper>
            <OS><SiApple /></OS>
            <BSCBtn
                onClick={() => window.open(macDownload, "https://www.dropbox.com/s/csd05twixadxt1p/ProficySetup.dmg?dl=1")}
            >BSC</BSCBtn>
            <ETHBtn
                 onClick={() => window.open(macDownload, "_blank")}
            >ETH</ETHBtn>
            </DownloadWrapper>
            <DownloadWrapper>
            <OS><SiLinux /></OS>
            <BSCBtn
                onClick={() => window.open(linDownload, "https://www.dropbox.com/s/6mdxbzr9h5qx3kb/ProficySetup.tar.xz?dl=1")}
            >BSC</BSCBtn>
            <ETHBtn
                onClick={() => window.open(linDownload, "_blank")}
            >ETH</ETHBtn>
            </DownloadWrapper>
            </Wrapper>
            </AnimatedPage3>
            </MobileView>
            <BrowserView>
            <AnimatedPage3>
            <Wrapper>
        <Changelog><MdUpdate /><a href='https://github.com/proficy/documentation/blob/main/README.md#changelog' target='_blank' rel="noreferrer">Changelog</a></Changelog>
        <Tilt className="downloadCard" tiltMaxAngleX={5} tiltMaxAngleY={5} glareEnable={false} glareMaxOpacity={0.15} glareBorderRadius="2em" 
        transitionSpeed={600} transitionEasing={true} >
            <DownloadWrapper>
                <OS><FaWindows /></OS>
                <BSCBtn
                onClick={() => window.open(winDownload, "https://www.dropbox.com/s/q85glly1sc6cqj4/ProficySetup.exe?dl=1")}
                >BSC</BSCBtn>
                <ETHBtn
                onClick={() => window.open(winDownloadEth, "_blank")}
                >ETH</ETHBtn>
            </DownloadWrapper>
            </Tilt>
            <Tilt className="downloadCard" tiltMaxAngleX={5} tiltMaxAngleY={5} glareEnable={false} glareMaxOpacity={0.15} glareBorderRadius="2em" 
        transitionSpeed={600} transitionEasing={true} >
            <DownloadWrapper>
            <OS><SiApple /></OS>
            <BSCBtn
                onClick={() => window.open(macDownload, "https://www.dropbox.com/s/csd05twixadxt1p/ProficySetup.dmg?dl=1")}
            >BSC</BSCBtn>
            <ETHBtn
                 onClick={() => window.open(macDownload, "_blank")}
            >ETH</ETHBtn>
            </DownloadWrapper>
            </Tilt>
            <Tilt className="downloadCard" tiltMaxAngleX={5} tiltMaxAngleY={5} glareEnable={false} glareMaxOpacity={0.15} glareBorderRadius="2em" 
        transitionSpeed={600} transitionEasing={true} >
            <DownloadWrapper>
            <OS><SiLinux /></OS>
            <BSCBtn
                onClick={() => window.open(linDownload, "https://www.dropbox.com/s/6mdxbzr9h5qx3kb/ProficySetup.tar.xz?dl=1")}
            >BSC</BSCBtn>
            <ETHBtn
                onClick={() => window.open(linDownload, "_blank")}
            >ETH</ETHBtn>
            </DownloadWrapper>
            </Tilt> 
            </Wrapper>
            </AnimatedPage3>
            </BrowserView>
        </>
    );
}

export default Downloads