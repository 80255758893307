import React from 'react';
import SwapMint from './SwapMint2';
import AnimatedBotsPage from '../elements/AnimatedBotsPage';

function Mint() {

    return (
            <AnimatedBotsPage>
            <SwapMint /> 
            </AnimatedBotsPage>
    );
}

export default Mint

