import React from "react";
import { GiCardExchange } from 'react-icons/gi';
import Tilt from 'react-parallax-tilt'
import { LandingPanel, LandingLogo, LandingName,
LandingInfo, LandingBtn } from "./LandingPanel.styled";
import { Link } from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';



function SwapPanel() {
    return (
        <>
        <BrowserView>
        <Tilt className="landing-pan" perspective={500} tiltMaxAngleX={10} tiltMaxAngleY={10} glareEnable={false} glareMaxOpacity={0.15} glareBorderRadius="2rem" 
        transitionSpeed={250} transitionEasing={true}>
            <LandingPanel>
                <LandingLogo>
                <GiCardExchange />
            </LandingLogo>
            <LandingName>Swap</LandingName> 
            <LandingInfo>
                ProficySwap is a local application that frees users from the limitations
                of overburdened servers and allows easy access to multiple chains.
            </LandingInfo>
            <Link to="/swap">
            <LandingBtn renderAs="button">
                <span>LEARN MORE</span>
            </LandingBtn>
            </Link>
            </LandingPanel>
        </Tilt>
        </BrowserView>
        <MobileView>
            <div className="landing-pan">
            <LandingPanel>
                <LandingLogo>
                <GiCardExchange />
            </LandingLogo>
            <LandingName>Swap</LandingName> 
            <LandingInfo>
                ProficySwap is a local application that frees users from the limitations
                of overburdened servers and allows easy access to multiple chains.
            </LandingInfo>
            <Link to="/swap">
            <LandingBtn renderAs="button">
                <span>LEARN MORE</span>
            </LandingBtn>
            </Link>
            </LandingPanel>
            </div>
        </MobileView>
        </>
    );
}

export default SwapPanel