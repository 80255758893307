import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  GiTrade,
  GiArrowScope,
  GiDustCloud,
  GiSpeedometer,
  GiHammerDrop,
} from "react-icons/gi";
import { ImCog, ImPower } from "react-icons/im";
import { FaTools, FaSuitcase, FaDatabase, FaSearchengin } from "react-icons/fa";
import { BiDownArrow, BiUpArrow, BiTransferAlt } from "react-icons/bi";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FiDownloadCloud } from "react-icons/fi";
import { MdOutlineAltRoute } from "react-icons/md";
import { SiMaterialdesign } from "react-icons/si";
import { HashLink } from "react-router-hash-link";
import { MdUpdate } from "react-icons/md";

const LeftMenuStyled = styled.div`
  position: absolute;
  left: -1vw;
  top: 6rem;
  width: 13rem;
  height: 75%;
  max-height: calc(100% - 12rem);
  overflow-y: scroll;
  scrollbar-width: none;
  border-radius: 0 2rem 2rem 0;
  background: rgb(68, 68, 68);
  background: linear-gradient(
    270deg,
    rgba(68, 68, 68, 0.6) 0%,
    rgba(1, 1, 1, 0.6) 100%
  );
  box-shadow: 3px 1px 30px 8px rgba(255, 255, 255, 0.15);
  z-index: 150;

  @media screen and (max-width: 800px) {
    height: 60%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MenuList = styled.ul`
  position: relative;
  margin-top: 2rem;
  overflow: none;
  max-height: 80%;
  left: 0;
  padding-inline-start: 0;
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: rgb(225, 225, 225, 0.7);
  list-style: none;
  text-decoration: none;

  & a {
    color: rgb(225, 225, 225, 0.7);
    text-decoration: none;
    display: flex;
  }
`;

const Item = styled.li`
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 10px 0 10px 25px;
  height: 3rem;
  transition: all 0.3s linear;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.3),
    0 0 5px rgba(255, 255, 255, 0.2), 0 0 7px rgba(255, 255, 255, 0.1),
    0 0 11px rgb(246, 161, 35, 0.25), 0 0 15px rgb(246, 161, 35, 0.2);
  padding-bottom: 4px;

  @media screen and (min-width: 800px) {
    &:hover {
      background: rgb(225, 225, 225, 0.05);
      transition: all 0.3s linear;
    }
  }

  @media screen and (max-width: 800px) {
    &:hover {
      background: rgb(225, 225, 225, 0.05);
      transition: all 0.3s linear;
    }
  }
`;

const ItemSmol = styled.li`
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 10px 0 10px 25px;
  height: 3rem;
  transition: all 0.3s linear;
  padding-bottom: 4px;

  @media screen and (min-width: 800px) {
    &:hover {
      background: rgb(225, 225, 225, 0.05);
      transition: all 0.3s linear;
    }
  }

  @media screen and (max-width: 800px) {
    &:hover {
      background: rgb(225, 225, 225, 0.05);
      transition: all 0.3s linear;
    }
  }
`;

const Icon = styled.svg`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  width: 32px;
  color: rgb(255, 153, 0, 0.7);
  transition: all 0.3s linear;
`;

const ArrowIcon = styled.svg`
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 18px;
  color: rgb(255, 225, 225, 0.4);
  transition: all 0.3s linear;
`;

const ToolsMenu = () => {
  return (
    <>
      <HashLink smooth to="/swap/toolsInfo#Portfolio">
        <ItemSmol>
          <Icon>
            <FaSuitcase />
          </Icon>
          &nbsp; Portfolio
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/toolsInfo#Transfer">
        <ItemSmol>
          <Icon>
            <BiTransferAlt />
          </Icon>
          &nbsp; Transfer
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/toolsInfo#TokenData">
        <ItemSmol>
          <Icon>
            <FaDatabase />
          </Icon>
          &nbsp; Token Data
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/toolsInfo#Search">
        <ItemSmol>
          <Icon>
            <FaSearchengin />
          </Icon>
          &nbsp; Search
        </ItemSmol>
      </HashLink>
    </>
  );
};

const AdvancedMenu = () => {
  return (
    <>
      <HashLink smooth to="/swap/advancedInfo#ManualSniper">
        <ItemSmol>
          <Icon>
            <GiArrowScope />
          </Icon>
          &nbsp; Manual Sniper
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/advancedInfo#SafeBuy">
        <ItemSmol>
          <Icon>
            <AiFillSafetyCertificate />
          </Icon>
          &nbsp; SafeBuy
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/advancedInfo#BypassMaxTx">
        <ItemSmol>
          <Icon>
            <MdOutlineAltRoute />
          </Icon>
          &nbsp; Bypass MaxTx
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/advancedInfo#SellDust">
        <ItemSmol>
          <Icon>
            <GiDustCloud />
          </Icon>
          &nbsp; Sell Dust
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/advancedInfo#Speedtest">
        <ItemSmol>
          <Icon>
            <GiSpeedometer />
          </Icon>
          &nbsp; Speedtest
        </ItemSmol>
      </HashLink>
      <HashLink smooth to="/swap/advancedInfo#ChangeDesign">
        <ItemSmol>
          <Icon>
            <SiMaterialdesign />
          </Icon>
          &nbsp; Change Design
        </ItemSmol>
      </HashLink>
    </>
  );
};

const Nav = () => {
  const [toolsMenu, setToolsMenu] = useState("Closed");
  const [advancedMenu, setAdvancedMenu] = useState("Closed");

  return (
    <>
      <LeftMenuStyled>
        <MenuList>
          <Link to="/swap/mint">
            <Item>
              <Icon>
                <GiHammerDrop />
              </Icon>
              &nbsp; Mint
            </Item>
          </Link>
          <Link to="/swap/downloads">
            <Item>
              <Icon>
                <FiDownloadCloud />
              </Icon>
              &nbsp; Download
            </Item>
          </Link>
          <Link to="/swap/changelog">
            <Item>
              <Icon>
                <MdUpdate />
              </Icon>
              &nbsp; Changelog
            </Item>
          </Link>
          <Link to="/swap/swapInfo">
            <Item>
              <Icon>
                <GiTrade />
              </Icon>
              &nbsp; Swap
            </Item>
          </Link>
          <Link to="/swap/setupInfo">
            <Item>
              <Icon>
                <ImCog />
              </Icon>
              &nbsp; Setup
            </Item>
          </Link>
          {toolsMenu === "Closed" && (
            <Item onClick={() => setToolsMenu("Open")}>
              <Icon>
                <FaTools />
              </Icon>
              &nbsp; Tools &nbsp;
              <ArrowIcon>
                <BiDownArrow />
              </ArrowIcon>
            </Item>
          )}
          {toolsMenu === "Open" && (
            <>
              {" "}
              <Item onClick={() => setToolsMenu("Closed")}>
                <Icon>
                  <FaTools />
                </Icon>
                &nbsp; Tools &nbsp;
                <ArrowIcon>
                  <BiUpArrow />
                </ArrowIcon>
              </Item>
              <ToolsMenu />
            </>
          )}
          {advancedMenu === "Closed" && (
            <Item onClick={() => setAdvancedMenu("Open")}>
              <Icon>
                <ImPower />
              </Icon>
              &nbsp; Advanced &nbsp;
              <ArrowIcon>
                <BiDownArrow />
              </ArrowIcon>
            </Item>
          )}
          {advancedMenu === "Open" && (
            <>
              {" "}
              <Item onClick={() => setAdvancedMenu("Closed")}>
                <Icon>
                  <ImPower />
                </Icon>
                &nbsp; Advanced &nbsp;
                <ArrowIcon>
                  <BiUpArrow />
                </ArrowIcon>
              </Item>{" "}
              <AdvancedMenu />
            </>
          )}
        </MenuList>
      </LeftMenuStyled>
    </>
  );
};

export default Nav;
