import { motion } from "framer-motion"
import styled from "styled-components";

const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    scrollbar-width: none;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 99;

    &::-webkit-scrollbar {
      display: none;
    }

@media screen and (max-width: 480px) {
    .choose-app {
        height: 90%;
    }
}

@media screen and (min-width: 1400px) {
    .choose-app {
        overflow-y: hidden;
        height: 80%;
    }
}
`

const Crapper = styled.div`
    position: relative;
    left: 0;
    top: 4.9rem;
    width: 100%;
    height: 87%;
    padding: 2rem;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 99;

    &::-webkit-scrollbar {
      display: none;
    }

@media screen and (max-width: 480px) {
    .choose-app {
        height: 92%;
    }
}

@media screen and (min-width: 1400px) {
    .choose-app {
        overflow-y: hidden;
        height: 80%;
    }
}
`

const pageAnim = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.25,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.25,
        }
    },
};

const AnimatedPage3 = (props) => {
    return (
        <Wrapper as={motion.div}
        variants={pageAnim}
        initial='initial'
        animate='animate'
        exit='exit'
        >
            <Crapper>
            {props.children}
            </Crapper>
        </Wrapper>       
    )
}

export default AnimatedPage3