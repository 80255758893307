import React from 'react';
import Header from './Header';
import AppSelect from './components/AppSelect';


export default function Landing() {
  
  return (
    <>
    <Header />
    <AppSelect />
    </>
  );
}