import React from "react";
import List from "./PromoList.js";
import QueuedPromos from "./QueuedPromos.js";
import ActivePromos from "./ActivePromos.js";
import { useWeb3React } from "@web3-react/core";

import { Container, Overflow, Space, Title, Row } from "../Styles";

const UserPromos = ({ userPromos, account, provider, signer }) => {
  let queuedPromos = [];
  let activePromos = [];
  const web3ReactContext = useWeb3React();
  if (!web3ReactContext.active) {
    return (
      <Container>
        <Row>
          <Title>Connect your wallet to acces dApp.</Title>
        </Row>
      </Container>
    );
  }
  if (userPromos) {
    queuedPromos = userPromos[1].filter((val) => val[4] !== "");
    activePromos = userPromos[0].filter((val) => val[4] !== "");
  } else {
    return (
      <Container>
        <Row>
          <Title>Click create new to create your first promotion.</Title>
        </Row>
      </Container>
    );
  }
  if (
    userPromos[0].filter((val) => val[4] !== "").length +
      userPromos[1].filter((val) => val[4] !== "").length ===
    0
  ) {
    return (
      <Container>
        <Row>
          <Title>Click create new to create your first promotion.</Title>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Overflow>
        <Row>
          <Title>My Queued Promotions</Title>
        </Row>
        <List>
          {queuedPromos.map((element, id) => {
            return (
              <QueuedPromos
                key={id}
                id={id}
                queuedPromos={element}
                signer={signer}
              />
            );
          })}
        </List>
        <Space />
        <Row>
          <Title>My Active Promotions</Title>
        </Row>
        <List>
          {activePromos.map((element, id) => {
            return (
              <ActivePromos
                key={id}
                id={id}
                activePromos={element}
                account={account}
                provider={provider}
                signer={signer}
              />
            );
          })}
        </List>
      </Overflow>
    </Container>
  );
};

export default UserPromos;
