import React, { useState, useEffect } from "react";
import Popup from "./Popup";
import { Contract, ethers } from "ethers";
import {
  timeConvert,
  getPrices,
  handleButton,
  initProxy,
} from "../helpers/helpers";

import {
  Span,
  ConfirmBtn2,
  ConfirmBtn3,
  Green,
  Red,
  Table,
  Row,
  SocialIcon,
  Socials,
} from "../Styles";
import { HiOutlineIdentification, HiOutlineRefresh } from "react-icons/hi";
import { TiMessage } from "react-icons/ti";
import {
  FaExternalLinkAlt,
  FaTelegram,
  FaDiscord,
  FaGlobe,
} from "react-icons/fa";
import { SiSolidity } from "react-icons/si";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BiListPlus } from "react-icons/bi";

import { BsBoxArrowUpRight } from "react-icons/bs";
import { shortenAddress } from "../../web3/helpers";

import { Notyf } from "notyf";
import "../../bots/components/notification.css";

const notyf = new Notyf({
  duration: 8000,
  position: {
    x: "right",
    y: "top",
  },
  dismissible: true,
  ripple: true,
  types: [
    {
      type: "error",
      background: "#262626e1",
    },
    {
      type: "warning",
      background: "#262626e1",
      icon: false,
      duration: 20000,
    },
    {
      type: "success",
      background: "#262626e1",
      duration: 300000,
    },
  ],
});

const ActivePromos = ({ id, activePromos, account, provider, signer }) => {
  const [currentBlock, setCurrentBlock] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [flagState, setFlagState] = useState();
  const [prices, setPrices] = useState({
    price1: "",
    price2: "",
    price3: "",
    price4: "",
  });

  useEffect(() => {
    async function getBlock() {
      try {
        let currentBlock = await provider.getBlock();
        setCurrentBlock(currentBlock.number);
      } catch (err) {
        console.log(err);
      }
    }
    let block = getBlock();
    if (signer) {
      getPrices(provider, setPrices);
    }
  }, []);

  async function extendPromo(slot, duration) {
    let proxyContract = await initProxy(signer);
    let price;
    if (duration == 0) {
      price = ethers.utils.parseUnits(prices.price1);
      duration = 28800;
    }
    if (duration == 1) {
      price = ethers.utils.parseUnits(prices.price2);
      duration = 28800 * 2;
    }
    if (duration == 2) {
      price = ethers.utils.parseUnits(prices.price3);
      duration = 28800 * 3;
    }
    if (duration == 3) {
      price = ethers.utils.parseUnits(prices.price4);
      duration = 28800 * 7;
    }
    try {
      let tx = await proxyContract.extendPromotion(slot, duration, {
        value: price,
        gasLimit: 1000000,
      });
      var TxHash = tx.hash;
      var receipt = await tx.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "You have successfully <b>extended promo time</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  }

  async function changePromo(slot, signer, _message, _socials, _CA) {
    let proxyContract = await initProxy(signer);

    try {
      let tx = await proxyContract.changePromotion(
        slot,
        _message,
        _socials,
        _CA
      );
      var TxHash = tx.hash;
      var receipt = await tx.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "You have successfully <b>changed promo details</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  }

  const contractAddress = activePromos.contractAddress;
  var tokenAddress = "https://testnet.bscscan.com/address/" + contractAddress;
  const endTime = parseInt(activePromos.endTime._hex);
  const slot = parseInt(activePromos.slot);
  const ID = parseInt(activePromos.id._hex);
  const message = activePromos.message;
  const socials = activePromos.socials;
  let timeRemaining = (endTime - currentBlock) * 3;
  !isNaN(currentBlock)
    ? timeRemaining <= 0
      ? (timeRemaining = 0)
      : (timeRemaining = timeConvert(timeRemaining))
    : (timeRemaining = "NA");

  return (
    <div>
      <div className="centerDiv">
        {showPopup && (
          <Popup
            flag={flagState}
            ID={ID}
            slot={slot}
            signer={signer}
            setShowPopup={setShowPopup}
            extendPromo={extendPromo}
            changePromo={changePromo}
          />
        )}
      </div>
      <div className="promoTextArea" spellCheck="false" key={`card_${id}`}>
        <Table>
          <tbody>
            <tr>
              <td>
                <Span>
                  <b>
                    <HiOutlineIdentification />
                    &nbsp;PromoID
                  </b>
                </Span>
                :
              </td>
              <td>{ID}</td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <TiMessage />
                    &nbsp;Message
                  </b>
                </Span>
                :
              </td>
              <td>{message}</td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <FaExternalLinkAlt />
                    &nbsp;Socials
                  </b>
                </Span>
                :
              </td>
              <td>
                <Socials>
                  <a
                    href={"https://" + socials}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {socials}&nbsp;
                    <BsBoxArrowUpRight />
                  </a>
                </Socials>
              </td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <SiSolidity />
                    &nbsp;Contract
                  </b>
                </Span>
                :
              </td>
              <td>
                <a
                  href={tokenAddress}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shortenAddress(contractAddress)}&nbsp;
                  <BsBoxArrowUpRight />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <AiOutlineFieldTime />
                    &nbsp;Remaining Time
                  </b>
                </Span>
                :
              </td>
              <td>{timeRemaining} h</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Row>
        <ConfirmBtn2
          onClick={() => {
            handleButton("extend", setShowPopup, setFlagState);
          }}
        >
          <Green>
            <BiListPlus />
          </Green>
          &nbsp;Extend Promotion
        </ConfirmBtn2>
        <ConfirmBtn3
          onClick={() => {
            handleButton("change", setShowPopup, setFlagState);
          }}
        >
          <Red>
            <HiOutlineRefresh />
          </Red>
          &nbsp;Change Promotion
        </ConfirmBtn3>
      </Row>
    </div>
  );
};

export default ActivePromos;
