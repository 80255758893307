import React from 'react';
import styled from 'styled-components';
import AnimatedPage3 from '../../elements/AnimatedPage3';
import { BrowserView, MobileView } from 'react-device-detect';

const Wrapper = styled.div`
@media screen and (max-width: 800px){
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 0 0 54px 0;
    display: flex;
    align-items: center; 
    justify-content: top;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
  @media screen and (min-width: 800px){
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 0 0 54px 0;
    display: flex;
    align-items: center; 
    justify-content: top;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
`

const PageTitle = styled.h1`
    position: relative;
    margin: 0;
    padding: 1rem;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 165, 0, 0.8);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
`
const Title = styled.h1`
    text-align: center;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 165, 0, 0.8);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
    opacity: 0.9;
`

const Left = styled.div`
    position: relative;
    left: 0px;
    width: 100%;
    align-items: left;
    justify-content: left;
`

const Plan = styled.div`
    @media screen and (max-width: 800px){
    position: relative;
    width: 85vw;
    min-height: 4rem;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: top;
    background: linear-gradient(210deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    transition: all 0.3s linear;

    & ul {
        padding: 0 10px 0 10px;
        margin: 0 0 0 5px;
    }


    & img {
        display: flex;
        width: 100%;
        height: 70%;
        border-radius: 4px;
    }
}

@media screen and (min-width: 800px){
    position: relative;
    width: 60%;
    min-height: 4rem;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(210deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    transition: all 0.3s linear;

    & ul {
        padding: 0 10px 0 10px;
        margin: 0 0 0 5px;
    }


    & img {
        display: flex;
        width: 100%;
        height: 70%;
        border-radius: 4px 4px 15px 15px;
    }
}
`

const Spacer = styled.div`
    width: 100%;
    height: 1rem;
`

function Development() {
    return (
        <>
        <MobileView>
        <AnimatedPage3>
        <Wrapper>
        <PageTitle>
        Current Development
        </PageTitle>
        <Plan>
            * The content in this document is displayed in order of priority. 
              These priorities are subject to change.
        </Plan>
        <Title>Proficy Node Network</Title>
        <Plan>
        Building the Proficy Node Network is our number one priority. This was our main intention 
        when starting this project. It is absolutely essential to establish the network in order to 
        provide our users the best possible and consistent snipe results in block 0.
        </Plan>
        <Title>
        Release of the token tracker
        </Title>
        <Plan><ul>
        <li> Automatically sell initial at X</li>
        <li> Automatically sell all at X</li>
        <li> Sell % of current holdings manually</li>
        <li> Stop-Loss at %</li>
        <li> View all trades on a token with one click</li>
        <li> View the chart of a token with one click</li>
        <li> Overview of investment, profits, tokenfees etc.</li>
        </ul>
        <Spacer />
        <img src="/img/history.jpg" alt="History" />
        </Plan>
        <Title>
        Release of the multi AntiRug
        </Title>
        <Plan>
        This allows you to have fully dynamic AntiRug on all tokens you bought through our
        bots. Fully dynamic means, it will scan each contract individually for functions that
        could be used in a malicious way by the devs. It will spot any attempt to rug you and
        frontrun the transactions of the devs. It protects you from the following:
        <Spacer />
              <li>Devs changing a token into a honeypot by changing maxTxAmount, fees, trading enabled</li>
              <li>Devs removing liquidity</li>
              <li>Devs trying to blacklist your wallet</li>
        </Plan>
        <Title>
        Stable coin pairs
        </Title>
        <Plan>
        You will be able to snipe on pairs like BUSD, USDT, USDC, etc.
        </Plan>
        <Title>
        ETH Whalebot
        </Title>
        <Plan>
        The Whalebot as you know it in BSC will also be
        available on ETH.
        </Plan>
        <Title>
        ETH Mempool/BlockBot
        </Title>
        <Plan>
        The Mempool and Block bots will also be available on ETH.
        </Plan>
        <Title>
        AVAX Whalebot
        </Title>
        <Plan>
        The Whalebot as you know it in BSC will also be
        available on AVAX.
        </Plan>
        <Title>
        Support sniping IDO launchpads
        </Title>
        <Plan>
        This feature will allow you to snipe launches on less popular platforms.
        </Plan>
        <Title>
        Support other routers
        </Title>
        <Plan>
        You will be able to snipe on other routers like ApeSwap
        </Plan>
        <Title>
        UI Improvements
        </Title>
        <Plan>
        With more and more features coming to the bots, we will
        restructure the design of the UI to give you a seamless and
        pleasant experience while using the app.
        </Plan>
        <PageTitle>
        Future Development
        </PageTitle>
        <Title>
        Miner Extractable Value (MEV) methods for ETH
        </Title>
        <Plan>
        If the demand for ETH-based bots rises, we will implement MEV 
        methods to provide the best possible results while sniping on ETH.
        </Plan>
        <Title>
        Solana NFT Bot
        </Title>
        <Plan>
        Similar to the ETH/BSC NFT bots, we want to build an NFT bot for
        the Solana chain.
        </Plan>
        <Title>
        Telegram Bot
        </Title>
        <Plan>
        The Telegram bot is the perfect tool for fair launches where the developers won’t reveal the contract until it is live, 
        which happens fairly often. Everyone will be scrambling around trying to copy the contract address and buy as fast as 
        possible. This is where the Telegram bot comes into play – it scans the Telegram channel for a message from admins 
        containing a contract address and will automatically buy for your desired amount of BNB. Buying faster than this on 
        such a fair launch is only possible if you are part of dev’s village.
        <Spacer />
        <ul>
              <li>Scans Telegram channel for contract address posted by admins</li>
              <li>Automatically buys your desired amount</li>
              <li>Bypass MaxTxAmount</li>
              <li>Sell methods</li>
              <li>Anti-Rug methods</li>
              <li>Automatic approval</li>
              <li>Manual buy and sell</li>
            </ul>
        </Plan>
        <Title>
        Spam Bot
        </Title>
        <Plan>
        The spambot is another approach to getting in as fast as possible on the launch of a token. 
        It also consists of local code and your own smart contract. Different to the Mempool bot, 
        it doesn’t scan the txpool looking for the right moment to send your transaction. Instead, 
        a whole swarm of wallets spams a transaction every few milliseconds through your smart contract, 
        until one is able to successfully buy when liquidity is added.
        <Spacer />
        This is an effective way to get in as early as possible, but since there is no advanced logic involved, 
        it comes with a higher cost in gas fees, since every failed transaction costs you about 5 cents. So timing 
        is key with this bot – start it as short as possible before liquidity is added and it will only cost you a 
        few dollars. You can also tweak the frequency of the transactions to your personal sweet spot.
        <Spacer />
        It will come with similar sell methods, anti-rug, etc., like the other bots.
        </Plan>
        <Title>
        Support FTM, Polygon, ONE, or other chains
        </Title>
        <Plan>
        If there is significant demand for this, we will look into establishing bots for these chains.
        </Plan>
        <Title>
        Support sniping on unverified and hidden NFT contracts
        </Title>
        <Plan>
        This feature will allow you to snipe on unverified contracts with no contract address handed out 
        to the public before minting is enabled.
        </Plan>
        </Wrapper>
        </AnimatedPage3>
        </MobileView>
        
        <BrowserView>
        <AnimatedPage3>
        <Wrapper>
        <PageTitle>
        Current Development
        </PageTitle>
        <Plan>
            * The content in this document is displayed in order of priority. 
              These priorities are subject to change.
        </Plan>
        <Title>Proficy Node Network</Title>
        <Plan>
        Building the Proficy Node Network is our number one priority. This was our main intention 
        when starting this project. It is absolutely essential to establish the network in order to 
        provide our users the best possible and consistent snipe results in block 0.
        </Plan>
        <Title>
        Release of the token tracker
        </Title>
        <Plan>
        <Left>
        <ul>
        <li> Automatically sell initial at X</li>
        <li> Automatically sell all at X</li>
        <li> Sell % of current holdings manually</li>
        <li> Stop-Loss at %</li>
        <li> View all trades on a token with one click</li>
        <li> View the chart of a token with one click</li>
        <li> Overview of investment, profits, tokenfees etc.</li>
        </ul>
        </Left>
        <Spacer />
        <img src="/img/history.jpg" alt="History" />
        </Plan>
        <Title>
        Release of the multi AntiRug
        </Title>
        <Plan>
        This allows you to have fully dynamic AntiRug on all tokens you bought through our
        bots. Fully dynamic means, it will scan each contract individually for functions that
        could be used in a malicious way by the devs. It will spot any attempt to rug you and
        frontrun the transactions of the devs. It protects you from the following:
        <Spacer />
            <Left>
              <li>Devs changing a token into a honeypot by changing maxTxAmount, fees, trading enabled</li>
              <li>Devs removing liquidity</li>
              <li>Devs trying to blacklist your wallet</li>
            </Left>
        </Plan>
        <Title>
        Stable coin pairs
        </Title>
        <Plan>
        You will be able to snipe on pairs like BUSD, USDT, USDC, etc.
        </Plan>
        <Title>
        ETH Whalebot
        </Title>
        <Plan>
        The Whalebot as you know it in BSC will also be
        available on ETH.
        </Plan>
        <Title>
        ETH Mempool/BlockBot
        </Title>
        <Plan>
        The Mempool and Block bots will also be available on ETH.
        </Plan>
        <Title>
        AVAX Whalebot
        </Title>
        <Plan>
        The Whalebot as you know it in BSC will also be
        available on AVAX.
        </Plan>
        <Title>
        Support sniping IDO launchpads
        </Title>
        <Plan>
        This feature will allow you to snipe launches on less popular platforms.
        </Plan>
        <Title>
        Support other routers
        </Title>
        <Plan>
        You will be able to snipe on other routers like ApeSwap
        </Plan>
        <Title>
        UI Improvements
        </Title>
        <Plan>
        With more and more features coming to the bots, we will
        restructure the design of the UI to give you a seamless and
        pleasant experience while using the app.
        </Plan>
        <PageTitle>
        Future Development
        </PageTitle>
        <Title>
        Miner Extractable Value (MEV) methods for ETH
        </Title>
        <Plan>
        If the demand for ETH-based bots rises, we will implement MEV 
        methods to provide the best possible results while sniping on ETH.
        </Plan>
        <Title>
        Solana NFT Bot
        </Title>
        <Plan>
        Similar to the ETH/BSC NFT bots, we want to build an NFT bot for
        the Solana chain.
        </Plan>
        <Title>
        Telegram Bot
        </Title>
        <Plan>
        The Telegram bot is the perfect tool for fair launches where the developers won’t reveal the contract until it is live, 
        which happens fairly often. Everyone will be scrambling around trying to copy the contract address and buy as fast as 
        possible. This is where the Telegram bot comes into play – it scans the Telegram channel for a message from admins 
        containing a contract address and will automatically buy for your desired amount of BNB. Buying faster than this on 
        such a fair launch is only possible if you are part of dev’s village.
        <Spacer />
        <Left>
            <ul>
              <li>Scans Telegram channel for contract address posted by admins</li>
              <li>Automatically buys your desired amount</li>
              <li>Bypass MaxTxAmount</li>
              <li>Sell methods</li>
              <li>Anti-Rug methods</li>
              <li>Automatic approval</li>
              <li>Manual buy and sell</li>
            </ul>
            </Left>
        </Plan>
        <Title>
        Spam Bot
        </Title>
        <Plan>
        The spambot is another approach to getting in as fast as possible on the launch of a token. 
        It also consists of local code and your own smart contract. Different to the Mempool bot, 
        it doesn’t scan the txpool looking for the right moment to send your transaction. Instead, 
        a whole swarm of wallets spams a transaction every few milliseconds through your smart contract, 
        until one is able to successfully buy when liquidity is added.
        <Spacer />
        This is an effective way to get in as early as possible, but since there is no advanced logic involved, 
        it comes with a higher cost in gas fees, since every failed transaction costs you about 5 cents. So timing 
        is key with this bot – start it as short as possible before liquidity is added and it will only cost you a 
        few dollars. You can also tweak the frequency of the transactions to your personal sweet spot.
        <Spacer />
        It will come with similar sell methods, anti-rug, etc., like the other bots.
        </Plan>
        <Title>
        Support FTM, Polygon, ONE, or other chains
        </Title>
        <Plan>
        If there is significant demand for this, we will look into establishing bots for these chains.
        </Plan>
        <Title>
        Support sniping on unverified and hidden NFT contracts
        </Title>
        <Plan>
        This feature will allow you to snipe on unverified contracts with no contract address handed out 
        to the public before minting is enabled.
        </Plan>
        </Wrapper>
        </AnimatedPage3>
        </BrowserView>
        </>
    );
}

export default Development