import React from "react";
import AnimatedPage3 from '../elements/AnimatedPage3';
import { Wrapper, PageTitle, Abouts, Title, Spacer } from './Styles';
import { HashLink } from "react-router-hash-link";

export default function ToolsInfo() {
    return (
        <AnimatedPage3>
        <Wrapper>
        <PageTitle>Supplemental Tools</PageTitle>
        <Abouts id="Portfolio">
            <Title>Portfolio</Title>
        <img src="/img/swap/portfolio.png" alt="Portfolio"/>
        <span>1. View your current portfolio and balances with worth of tokens in USD. Click the headers to sort by token name, highest or lowest balances, or favorite tokens.</span>
        <span>2. Click these buttons to view your overall portfolio, refresh your tokens with updated balanaces, view favorites, and view deleted tokens.</span>
        <span>3. Set a lower and upper limit in USD to filter your portfolio by.</span>
        <span>4. Click this button to "<HashLink smooth to='/swap/advancedInfo#SellDust'>Sell Dust</HashLink>", which will allow you to immediately sell all tokens within the given lower and upper limits.</span>
        <span>5. Filter your tokens by range, filter honeypots, or delete all within the given range.</span>
        </Abouts>
        <Spacer />
        <Abouts id="Transfer">
            <Title>Transfer</Title>
        <img src="/img/swap/transfer.png" alt="Transfer"/>
        <span>Easily transfer your tokens to external wallets or newly created Proficy wallets. Your entire filtered portfolio is displayed. Click on the arrow next to select which token to transfer. ProficySwap will calculate optimal gas prices and limits if possible. Enter the wallet to transfer to and the token amount and click send to complete the transfer.</span>
        </Abouts>
        <Spacer />
        <Abouts id="TokenData">
            <Title>Token Data</Title>
        <img src="/img/swap/tokendata.png" alt="TokenData"/>
        <span>1. <b>General</b>: View general information about the token. Click the contract address or contract owner address to view additional information on BscScan.</span>
        <span>2. <b>Liquidity</b>: View liquidity information and current worth of liquidity pairs in USD. ProficySwap also displays where liquidity is locked and for how long it's locked if available. If liquidity locks are not displayed, double check the liquidity pair holders list in BscScan to make sure.</span>
        <span>3. <b>User Trades</b>: View your most recent trades on the selected token.</span>
        <span>4. <b>Top Holders</b>: View the top holders and percentage of supply owned.</span>
        </Abouts>
        <Spacer />
        <Abouts id="Search">
            <Title>Search</Title>
        <img src="/img/swap/tokensearch.png" alt="TokenSearch"/>
        <span>ProficySwap offers the fastest token search available. Click on a token symbol from the main swap view to open token search and search by token name, symbol, or contract address. The tokens with the highest amounts of liquidity are displayed first. This information is updated in real time with new tokens and updated liquidity amounts. Only tokens with greater than $1000 in liquidity are displayed. Click on Name, Date, or Liquidity to filter on these items.</span>
        </Abouts>
        </Wrapper>
        </AnimatedPage3>
    );
}