import React, { useState, useEffect } from "react";
import { RightArrow, LeftArrow, DotsDiv, Dot, DotHighlighted } from "./Styles";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";

function SliderMobile({ children }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    const isLastSlide = currentSlide === 4;
    const newSlide = isLastSlide ? 0 : currentSlide + 1;
    setCurrentSlide(newSlide);
  };

  const previousSlide = () => {
    const isFirstSlide = currentSlide === 0;
    const newSlide = isFirstSlide ? 4 : currentSlide - 1;
    setCurrentSlide(newSlide);
  };

  return (
    <>
      {children.map((item, index) => {
        var slide = children.filter((slide) => index === currentSlide);
        return <div key={index}>{slide[currentSlide]}</div>;
      })}

      <RightArrow
        onClick={() => {
          nextSlide();
        }}
      >
        <BiRightArrow />
      </RightArrow>
      <LeftArrow onClick={previousSlide}>
        <BiLeftArrow />
      </LeftArrow>
      <DotsDiv>
        {currentSlide === 0 ? (
          <DotHighlighted />
        ) : (
          <Dot onClick={() => setCurrentSlide(0)} />
        )}
        {currentSlide === 1 ? (
          <DotHighlighted />
        ) : (
          <Dot onClick={() => setCurrentSlide(1)} />
        )}
        {currentSlide === 2 ? (
          <DotHighlighted />
        ) : (
          <Dot onClick={() => setCurrentSlide(2)} />
        )}
        {currentSlide === 3 ? (
          <DotHighlighted />
        ) : (
          <Dot onClick={() => setCurrentSlide(3)} />
        )}
        {currentSlide === 4 ? (
          <DotHighlighted />
        ) : (
          <Dot onClick={() => setCurrentSlide(4)} />
        )}
      </DotsDiv>
    </>
  );
}
export default SliderMobile;
