import { motion } from 'framer-motion';
import styled from 'styled-components';

const BackdropStyled = styled.div`
    position: fixed;
    top: 0;
    left: -0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: rgb(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 150;
`

const Backdrop = ({ children, onClick }) => {

    return (
        <BackdropStyled 
        as={motion.div}
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit= {{ opacity: 0 }}
        >
            {children}
        </BackdropStyled>
    );

};

export default Backdrop