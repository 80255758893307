import React, { useState } from "react";
import { Contract, ethers } from "ethers";
import Popup from "./Popup";
import { handleButton, initProxy } from "../helpers/helpers";

import {
  Span,
  ConfirmBtn2,
  ConfirmBtn3,
  Green,
  Red,
  Table,
  Row,
  Socials,
} from "../Styles";
import { HiOutlineIdentification, HiUserGroup } from "react-icons/hi";
import { TiMessage } from "react-icons/ti";
import { FaExternalLinkAlt } from "react-icons/fa";
import { SiSolidity } from "react-icons/si";
import { AiOutlineFieldTime } from "react-icons/ai";
import {
  CgArrowsExpandUpRight,
  CgArrowTopRightR,
  CgCloseR,
} from "react-icons/cg";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { shortenAddress } from "../../web3/helpers";

import { Notyf } from "notyf";
import "../../bots/components/notification.css";

const notyf = new Notyf({
  duration: 8000,
  position: {
    x: "right",
    y: "top",
  },
  dismissible: true,
  ripple: true,
  types: [
    {
      type: "error",
      background: "#262626e1",
    },
    {
      type: "warning",
      background: "#262626e1",
      icon: false,
      duration: 20000,
    },
    {
      type: "success",
      background: "#262626e1",
      duration: 300000,
    },
  ],
});

const QueuedPromos = ({ id, queuedPromos, signer }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [flagState, setFlagState] = useState();

  const contractAddress = queuedPromos.contractAddress;
  const duration = parseInt(queuedPromos.endTime._hex);
  const ID = parseInt(queuedPromos.id._hex);
  const message = queuedPromos.message;
  const priority = ethers.utils.formatUnits(queuedPromos.priority._hex);
  const slot = parseInt(queuedPromos.slot._hex);
  const socials = queuedPromos.socials;

  var tokenAddress = "https://testnet.bscscan.com/address/" + contractAddress;

  async function increasePrio(promoID, signer) {
    let proxyContract = await initProxy(signer);

    if (document.getElementById("priority").value === "") {
      return notyf.error("Enter value that is greater than 0!");
    }
    let priority = ethers.utils.parseUnits(
      document.getElementById("priority").value
    );
    try {
      let tx = await proxyContract.increasePriority(promoID, {
        value: priority,
        gasLimit: 1000000,
      });
      var TxHash = tx.hash;
      var receipt = await tx.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "You have successfully <b>Increased priority of your ad</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  }

  async function revokePromo(promoID, signer) {
    let proxyContract = await initProxy(signer);
    try {
      let tx = await proxyContract.revokePromotion(promoID, {
        gasLimit: 1000000,
      });
      var TxHash = tx.hash;
      var receipt = await tx.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "You have successfully <b>revoked your promotion</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  }

  return (
    <div>
      <div>
        {showPopup && (
          <Popup
            flag={flagState}
            ID={ID}
            signer={signer}
            setShowPopup={setShowPopup}
            increasePrio={increasePrio}
            revokePromo={revokePromo}
          />
        )}
      </div>
      <div key={`card_${id}`}>
        <Table>
          <tbody>
            <tr>
              <td>
                <Span>
                  <b>
                    <HiOutlineIdentification />
                    &nbsp;PromoID
                  </b>
                </Span>
                :
              </td>
              <td>{ID}</td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <HiUserGroup />
                    &nbsp;Queue
                  </b>
                </Span>
                :
              </td>
              <td>Position {slot}</td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <TiMessage />
                    &nbsp;Message
                  </b>
                </Span>
                :
              </td>
              <td>{message}</td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <FaExternalLinkAlt />
                    &nbsp;Socials link
                  </b>
                </Span>
                :
              </td>
              <td>
                <Socials>
                  <a
                    href={"https://" + socials}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {socials}&nbsp;
                    <BsBoxArrowUpRight />
                  </a>
                </Socials>
              </td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <SiSolidity />
                    &nbsp;Contract
                  </b>
                </Span>
                :
              </td>
              <td>
                <a
                  href={tokenAddress}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shortenAddress(contractAddress)}&nbsp;
                  <BsBoxArrowUpRight />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <AiOutlineFieldTime />
                    &nbsp;Duration
                  </b>
                </Span>
                :
              </td>
              <td>{(duration * 3) / 3600} h</td>
            </tr>
            <tr>
              <td>
                <Span>
                  <b>
                    <CgArrowsExpandUpRight />
                    &nbsp;Priority
                  </b>
                </Span>
                :
              </td>
              <td>{priority} BNB</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Row>
        <ConfirmBtn2
          onClick={() => {
            handleButton("prio", setShowPopup, setFlagState);
          }}
        >
          <Green>
            <CgArrowTopRightR />
          </Green>
          &nbsp;Increase Priority
        </ConfirmBtn2>
        <ConfirmBtn3
          onClick={() => {
            handleButton("revoke", setShowPopup, setFlagState);
          }}
        >
          <Red>
            <CgCloseR />
          </Red>
          &nbsp;Revoke Promotion
        </ConfirmBtn3>
      </Row>
    </div>
  );
};

export default QueuedPromos;
