import React from 'react';
import styled from 'styled-components';
import TelegramBtn from './buttons/TelegramBtn';
import GithubBtn from './buttons/GithubBtn';
import DiscordBtn from './buttons/DiscordBtn'; 

const StyledFooter = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #010101;
    width: 100%;
    height: 46px;
    bottom: -5px;
    padding-bottom:15px;
    z-index: 100;
`

function Footer() {
    return (
        <StyledFooter>
            <TelegramBtn />
            <GithubBtn />
            <DiscordBtn />
        </StyledFooter>
    );
} 

export default Footer