import React from "react";
import BotsHeader from "./BotsHeader";
import { Routes, Route, useLocation } from 'react-router-dom';
import BotsMintBsc from './pages/MintBsc';
import BotsMintEth from './pages/MintEth';
import BotsAbout from './pages/About';
import BotsDevelopment from './pages/Development';
import BotsDownloads from './pages/Downloads';
import BotsFAQ from './pages/FAQ';
import Header from './Header';
import { AnimatePresence } from "framer-motion";
import { BrowserView, MobileView } from 'react-device-detect';
import Nav from "./Nav";


function Bots() {

    const location = useLocation();

    return (
        <>
        <MobileView>
        <BotsHeader />
        <AnimatePresence exitBeforeEnter>
        <Routes key={location.pathname} location={location}>
            <Route  path='/' element={<BotsMintBsc />}/>    
            <Route path='/mintEth' element={<BotsMintEth />}/>
            <Route path='/about' element={<BotsAbout />}/>
            <Route path='/development' element={<BotsDevelopment />}/>
            <Route path='/downloads' element={<BotsDownloads />}/>
            <Route path='/FAQ' element={<BotsFAQ />}/>
        </Routes>
        </AnimatePresence>
        </MobileView>
        <BrowserView>
        <Header />
        <Nav />
        <AnimatePresence exitBeforeEnter>
        <Routes key={location.pathname}location={location}>
            <Route  path='/' element={<BotsMintBsc />}/>    
            <Route path='/mintEth' element={<BotsMintEth />}/>
            <Route path='/about' element={<BotsAbout />}/>
            <Route path='/development' element={<BotsDevelopment />}/>
            <Route path='/downloads' element={<BotsDownloads />}/>
            <Route path='/FAQ' element={<BotsFAQ />}/>
        </Routes>
        </AnimatePresence>
        </BrowserView>
        </>
    );
}

export default Bots