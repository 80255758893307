import React from 'react';
import BotCards from '../cardsEth/BotCards';
import AnimatedPage3 from '../../elements/AnimatedPage3';
import cardData from '../cardsEth/cardData.json'

function MintEth() {
    return (
        <AnimatedPage3>
        {cardData.map((item, id1) => {
        return <BotCards key={id1} cardData={item} />;
        })}
        </AnimatedPage3>
    );
}

export default MintEth