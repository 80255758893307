import React from "react";
import AnimatedPage3 from '../elements/AnimatedPage3';
import { Wrapper, PageTitle, Left, Right, Abouts } from './Styles';
import { HashLink } from "react-router-hash-link";

export default function SetupInfo() {
    return (
        <AnimatedPage3>
        <Wrapper>
        <PageTitle>Setup</PageTitle>
        <Abouts>
        <img src="/img/swap/setup.png" alt="Setup"/>
        <span>1. <b>Custom Websocket URL</b>: If you host your own private node, enter the websocket URL here. Otherwise leave this blank and use a Proficy hosted private node.</span>
        <span>2. <b>Standard Inputs</b>: Standard inputs the app will load on startup. Top input is gwei and bottom input is slippage.</span>
        <span>3. <b>Max Fees</b>: The maximum txFees you are willing to accept on a trade if SafeBuy is active. Top input is buy fee and bottom input is sell fee.</span>
        <span>4. <b>Node Selection</b>: Choose one of Proficy node's hosted around the globe or select your own node in this dropdown.</span>
        <span>5. <b>Auto-Approve/Always on Top</b>: If auto-approve is active, it will silently approve a token you just bought which will allow you to immediately sell if needed. Always On Top will allow you to keep ProficySwap pinned to the top so you can always have the swap open while you're viewing charts or browsing the web.</span>
        <span>6. <b>Settings Buttons</b>:</span>
        <ul>
            <li>
            <span><b><HashLink smooth to='/swap/advancedInfo#Speedtest'>Speedtest</HashLink></b>: The SPEEDTEST will find the best endpoint for you.</span>
            </li>
            <li>
            <span><b>Update</b>: Click this to see if your current version of ProficySwap is up-to-date. If not, the latest version will automatically start downloading to your default Downloads folder.</span>
            </li>
            <li>
            <span><b>Save</b>: Save your current swap settings.</span>
            </li>
            <li>
            <span><b>Change Password</b>: Change your current password that is used to logged into the app. It will be automatically encrypted.</span>
            </li>
            <li>
            <span><b><HashLink smooth to='/swap/advancedInfo#ChangeDesign'>Change Design</HashLink></b>: Configure your background colors to anything you desire.</span>
            </li>
        </ul>
        <span>7. <b>Proficy Wallet</b>: Create a new wallet with the ability to export the seed phrase and private key.</span>
        <span>8. <b>Imported Wallet</b>: Import a wallet here. You can input your private key only and ProficySwap will automatically detect your wallet address. Your private key will be encrypted and safely stored locally on your computer. Note that your private key is different from your seed phrase. Follow these instructions to export your private key for use in the ProficySwap app: <a href='https://metamask.zendesk.com/hc/en-us/articles/360015289632-How-to-Export-an-Account-Private-Key' target='_blank' rel="noreferrer">https://metamask.zendesk.com/hc/en-us/articles/360015289632-How-to-Export-an-Account-Private-Key</a> .</span>
        </Abouts>
        </Wrapper>
        </AnimatedPage3>
    );
}