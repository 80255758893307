import React, {useEffect} from 'react';
import BotCards from '../cardsBsc/BotCards';
import AnimatedBotsPage from '../../elements/AnimatedBotsPage';
import cardData from '../cardsBsc/cardData.json';
import { useWeb3React } from '@web3-react/core';
import { injected } from "../../web3/connectors";

function MintBsc() {

    const web3reactContext = useWeb3React(); 

	const checktMetamask = async () => {
		if (localStorage.isWalletConnected === 'true') {
		try {
			await web3reactContext.activate(injected);
		} catch (ex) {
			console.log(ex);
		}
	}
	};

	useEffect(() => {
        checktMetamask()
    }, [])

    return (
            <AnimatedBotsPage>
            {cardData.map((item, id,) => {
            return <BotCards key={id} cardData={item} />;
            })}
            </AnimatedBotsPage>
    );
}

export default MintBsc