import { Contract, ethers } from "ethers";

export const proxyABI = require("./pricebotProxyABI.json");
export const pricebotABI = require("./pricebotABI.json");
const pricebotProxyAddress = "0x06bf30f877e2276a667D52D9e1A0925B50d4F366";
const pricebotAddress = "0x5b3F92BE2000aA1CA789F21ea5694270D7847299";

// const pricebotProxyAddress = '0x1Ca63E88CC14243C519CD5e9cD45D258Ea81E076';
// const pricebotAddress = '0x72F28ae66ba530ae881D46dce92E7Fca6aF7AB27';

export async function initProxy(signer) {
  let proxyContract = new ethers.Contract(
    pricebotProxyAddress,
    proxyABI,
    signer
  );
  return proxyContract;
}

export async function initPricebot(signer) {
  let pricebotContract = new ethers.Contract(
    pricebotAddress,
    pricebotABI,
    signer
  );
  return pricebotContract;
}

export const checkAddress = async (input, setShowError) => {
  if (input !== "") {
    try {
      ethers.utils.getAddress(input);
      setShowError(false);
    } catch (err) {
      setShowError(true);
      return;
    }
  }
  if (input === "") {
    setShowError(false);
  }
};

export const cleanCheck = async (input) => {
  try {
    let clean = input
      .replace(/^\./, "0.")
      .replace(/[^.\d]|^\.$/g, "")
      .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
    return clean;
  } catch (err) {
    console.log(err);
  }
};

export function handleButton(flag, setShowPopup, setFlagState) {
  if (flag == "prio") {
    setShowPopup(true);
    setFlagState(flag);
  }
  if (flag == "revoke") {
    setShowPopup(true);
    setFlagState(flag);
  }
  if (flag == "extend") {
    setShowPopup(true);
    setFlagState(flag);
  }
  if (flag == "change") {
    setShowPopup(true);
    setFlagState(flag);
  }
}

export const getPrices = async (signer, setPrices) => {
  let proxyContract = await initProxy(signer);
  let price1 = ethers.utils.formatUnits(await proxyContract.oneDay());
  let price2 = ethers.utils.formatUnits(await proxyContract.twoDays());
  let price3 = ethers.utils.formatUnits(await proxyContract.threeDays());
  let price4 = ethers.utils.formatUnits(await proxyContract.week());
  setPrices((prevPrices) => {
    return { ...prevPrices, price1, price2, price3, price4 };
  });
};

export function timeConvert(num) {
  var hours = num / 3600;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  rminutes < 10 ? (rminutes = `0${rminutes}`) : (rminutes = rminutes);
  rminutes == 60 ? (rminutes = "00") : (rminutes = rminutes);
  return `${rhours}:${rminutes}`;
}

export const getMaxChars = async (signer, setMaxChars) => {
  let pricebotContract = await initPricebot(signer);
  setMaxChars(await pricebotContract.charLength());
};
