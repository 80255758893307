import React from "react";
import { GiVintageRobot } from 'react-icons/gi';
import Tilt from 'react-parallax-tilt';
import { LandingPanel, LandingLogo, LandingName,
LandingInfo, LandingBtn } from "./LandingPanel.styled";
import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

function BotsPanel() {
    return (
        <>
        <BrowserView>
        <Tilt className="landing-pan" tiltMaxAngleX={10} tiltMaxAngleY={10} glareEnable={false} glareMaxOpacity={0.15} glareBorderRadius="2rem" 
        transitionSpeed={600} transitionEasing={true} >
        <LandingPanel>
           <LandingLogo>
            <GiVintageRobot />
           </LandingLogo>
           <LandingName>Bots</LandingName> 
           <LandingInfo>
           Let ProficyBots do the work for you with whale tracking, sniping, anti-rug, and more.
           </LandingInfo>
           <Link to="/bots">
            <LandingBtn renderAs="button">
                <span>LEARN MORE</span>
            </LandingBtn>
            </Link>
           </LandingPanel>
       </Tilt> 
       </BrowserView>
       <MobileView>
        <div className="landing-pan">
        <LandingPanel>
           <LandingLogo>
            <GiVintageRobot />
           </LandingLogo>
           <LandingName>Bots</LandingName> 
           <LandingInfo>
           Let ProficyBots do the work for you with whale tracking, sniping, anti-rug, and more.
           </LandingInfo>
           <Link to="/bots">
            <LandingBtn renderAs="button">
                <span>LEARN MORE</span>
            </LandingBtn>
            </Link>
           </LandingPanel>
        </div>  
       </MobileView>
       </>
    );
}

export default BotsPanel