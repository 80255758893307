import styled from "styled-components";

export const Dash = styled.div`
  @media screen and (max-width: 930px) {
    position: absolute;
    top: 10rem;
    width: 80%;
    max-width: 80%;
    left: 10%;
    min-height: 7rem;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
    z-index: 4;

    & b {
      font-weight: 600;
    }

    & a {
      color: rgb(255, 225, 225, 0.9);
      text-decoration: none;
      border-bottom: 1px dotted;
    }
  }

  @media screen and (min-width: 930px) {
    position: absolute;
    width: 60%;
    max-width: 60%;
    top: 10rem;
    left: 20%;
    min-height: 24rem;
    max-height: 24rem;
    padding: 0;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
    z-index: 4;

    & b {
      font-weight: 600;
    }

    & a {
      color: rgb(255, 225, 225, 0.9);
      text-decoration: none;
      border-bottom: 1px dotted;
    }
  }
`;
export const InfoDiv = styled.div`
  position: absolute;
  display: flex;
  bottom: -6rem;
  left: 10%;
  padding: 5px;
  margin: 0;
  width: 80%;
  height: 6rem;
  background: linear-gradient(
    210deg,
    rgba(68, 68, 68, 0.2) 0%,
    rgba(11, 11, 11, 0.2) 40%
  );
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-top: none;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;

  & ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    width: 90%;
    left: 5%;
  }
`;

export const User = styled.div`
  @media screen and (max-width: 930px) {
    position: absolute;
    width: 93%;
    left: 3%;
    top: calc(-5rem - 2px);
    margin-top: 2rem;
    min-height: 3rem;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-bottom: none;
    border-radius: 1rem 1rem 0 0;
    z-index: 3;
  }
  @media screen and (min-width: 930px) {
    position: absolute;
    min-width: 60%;
    left: 20%;
    top: calc(-3rem - 2px);
    height: 3rem;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-bottom: none;
    border-radius: 1rem 1rem 0 0;
    z-index: 3;
  }
`;

export const UserBtn = styled.button`
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  width: 42%;
  height: 2rem;
  margin: 3px;
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-radius: 5px;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3), 0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35, 0.35), 0 0 15px rgb(246, 161, 35, 0.3);
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  transition: all 0.3s linear;
  z-index: 3;

  & svg {
    position: relative;
    top: 4px;
    font-size: 22px;
    width: 22px;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    font-size: 14px;
    & svg {
      position: relative;
      top: 4px;
      font-size: 16px;
      width: 16px;
    }
  }
`;

export const Title = styled.span`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  color: rgb(255, 165, 0, 0.8);
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3), 0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35, 0.35), 0 0 15px rgb(246, 161, 35, 0.3);
  opacity: 0.8;
`;

export const Red = styled.span`
  color: rgb(255, 2, 2, 0.7);
`;

export const Green = styled.span`
  color: rgb(2, 255, 31, 0.7);
`;

export const Blue = styled.span`
  color: rgb(29, 255, 248, 0.7);
`;

export const Container = styled.div`
  width: 100%;
  min-height: 60%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: 930px) {
    gap: 8px;
  }
`;

export const PromoInput = styled.textarea`
  resize: none;
  width: 64%;
  height: 6rem;
  padding: 1rem;
  background: rgb(0, 0, 0, 0.2);
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-radius: 1rem;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  color: rgb(225, 225, 225, 0.8);

  @media screen and (max-width: 930px) {
    width: 84%;
  }
`;

export const Input = styled.input`
  resize: none;
  min-width: 31%;
  min-height: 2rem;
  max-width: 31%;
  max-height: 2rem;
  padding: 5px;
  background: rgb(0, 0, 0, 0.2);
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  color: rgb(225, 225, 225, 0.8);

  @media screen and (max-width: 930px) {
    min-width: 84%;
    margin-bottom: 8px;
  }
`;

export const Input2 = styled.input`
  resize: none;
  min-width: 31%;
  min-height: 2rem;
  max-width: 31%;
  max-height: 2rem;
  padding: 5px;
  margin-left: 2%;
  background: rgb(0, 0, 0, 0.2);
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  color: rgb(225, 225, 225, 0.8);

  @media screen and (max-width: 930px) {
    min-width: 84%;
    margin-left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 930px) {
    flex-direction: row;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;

export const Select = styled.select`
  resize: none;
  min-width: 31%;
  min-height: 2rem;
  max-width: 31%;
  margin-left: 2%;
  max-height: 2rem;
  padding: 5px;
  background: rgb(25, 25, 25, 1);
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  color: rgb(225, 225, 225, 0.8);

  @media screen and (max-width: 930px) {
    min-width: 84%;
    left: 8%;
    margin-bottom: 8px;
  }
`;

export const ConfirmBtn = styled.button`
  align-items: center;
  padding: 12px;
  border: 2px solid rgb(90, 90, 90);
  border-radius: 8px 8px 15px 15px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    padding: 10px;
  }
`;

export const ConfirmBtn2 = styled.button`
  align-items: center;
  padding: 12px;
  border: 2px solid rgb(90, 90, 90);
  border-radius: 15px 15px 15px 15px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 930px) {
    font-size: 17px;
    padding: 10px;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: 930px) {
    margin-right: 0.5rem;
  }
`;

export const ConfirmBtn3 = styled.button`
  align-items: center;
  padding: 12px;
  border: 2px solid rgb(90, 90, 90);
  border-radius: 15px 15px 15px 15px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    padding: 10px;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 800px) {
    margin-left: 0.5rem;
  }
`;

export const Span = styled.span`
  opacity: 0.5;
`;

export const PopUp = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: linear-gradient(
    210deg,
    rgba(68, 68, 68) 0%,
    rgba(11, 11, 11) 100%
  );
  border: none;
  border-radius: 1rem;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  z-index: 200;
`;

export const ConfirmBtn4 = styled.button`
  align-items: center;
  padding: 12px;
  margin-bottom: 8rem;
  border: 2px solid rgb(90, 90, 90);
  border-radius: 15px 15px 15px 15px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  & svg {
    font-size: 24px;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    padding: 10px;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 2rem;
  width: 2rem;
  border: none;
  background: none;
  color: rgb(255, 2, 2, 0.7);
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s linear;
  }
`;

export const Space = styled.div`
  height: 1rem;
`;

export const Table = styled.table`
  border-top: 2px dotted rgb(225, 225, 225, 0.4);
  border-bottom: 2px dotted rgb(225, 225, 225, 0.4);
  table-layout: fixed;
  & tr,
  td {
    word-wrap: break-word;
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    margin: 1rem 0 1rem;
  }
  @media screen and (min-width: 930px) {
    width: 74%;
    margin: 1rem 0 1rem 13%;
  }
`;

export const Overflow = styled.div`
  height: 23rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;

  @media screen and (max-width: 800px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (min-width: 800px) {
    ::-webkit-scrollbar {
      width: 16px;
      background-color: none;
    }

    ::-webkit-scrollbar-track {
      background-color: rgb(50, 50, 50);
      margin-block: 1rem;
      border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(255, 168, 72);
      background: linear-gradient(
        45deg,
        rgba(255, 168, 72, 0.6) 0%,
        rgba(255, 87, 0, 0.6) 100%
      );
      border: 4px rgb(50, 50, 50) solid;
      border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: rgb(175, 175, 175);
    }

    ::-webkit-scrollbar-thumb:active {
      background-color: rgb(150, 150, 150);
    }
  }
`;

export const Scrollable = styled.div`
  @media screen and (max-width: 930px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: calc(100% + 10rem);
    left: auto;
    overflow-y: scroll;
  }
`;

export const Pad = styled.div`
  position: absolute;
  width: 100%;
  min-height: 5rem;
  bottom: -5rem;
  left: 0;
`;

export const SocialIcon = styled.button`
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: rgba(255, 153, 0, 0.7);
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border: 2px solid rgb(225, 225, 225, 0.4);
  padding: 3px;
  background: none;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    border: 2px solid rgb(160, 160, 160);
    color: rgba(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  &:active {
    transform: scale(0.9);
    transition: all 0.3s linear;
  }
`;

export const Socials = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const RefLink = styled.div`
  display: flex;
  font-size: 14px;
  color: rgba(225, 225, 225, 0.6);
  & b {
    color: rgba(255, 153, 0, 0.6);
    cursor: pointer;
    text-decoration: underline dotted;
  }
`;
