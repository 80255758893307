import React from "react";
import AnimatedPage3 from "../elements/AnimatedPage3";
import { Wrapper, PageTitle, Abouts } from "./Styles";

const Changelog = () => {
  return (
    <AnimatedPage3>
      <Wrapper>
        <PageTitle>Changelog</PageTitle>
        <Abouts>
        <span>
            2021-11-27 <b>v1.0.5</b>
          </span>
          <ul>
            <li>
              <span>Added contract functions to token data</span>
            </li>
            <li>
              <span>Sped up info icons on hover</span>
            </li>
            <li>
              <span>Fixed a bug with approving on Uniswap V3</span>
            </li>
          </ul>
        <span>
            2021-11-22 <b>v1.0.4</b>
          </span>
          <ul>
            <li>
              <span>
                Fixed portfolio updates when switching accounts
              </span>
            </li>
            <li>
              <span>Correct USD worth in portfolio for USDC/USDT pairs</span>
            </li>
            <li>
              <span>Fixed auto approve</span>
            </li>
            <li>
              <span>Resolved max transfer issues</span>
            </li>
          </ul>
          <span>
            2021-11-20 <b>v1.0.3</b>
          </span>
          <ul>
            <li>
              <span>
                Automatic updates and built-in changelogs. This means you will
                never have to manually download and update again
              </span>
            </li>
            <li>
              <span>Speed up token search results</span>
            </li>
            <li>
              <span>Improved honeypot detection UI</span>
            </li>
            <li>
              <span>Added ability to cancel transaction on ETH</span>
            </li>
          </ul>
          <span>
            2021-11-10 <b>v1.0.0</b>
          </span>
          <ul>
            <li>
              <span>ProficySwap initial release</span>
            </li>
          </ul>
        </Abouts>
      </Wrapper>
    </AnimatedPage3>
  );
};

export default Changelog;
