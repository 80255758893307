import React, { useState, useEffect } from "react";
import Tilt from "react-parallax-tilt";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import Abi from "../web3/abi/Abi3.json";
import { Notyf } from "notyf";
import "../bots/components/notification.css";
import { BrowserView, MobileView } from "react-device-detect";
import {
  BotCard, 
  CardTitle,
  CheckboxDiv,
  Row,
  Row2,
  Row3,
  Image,
  Label,
  Checkbox,
  Checkmark,
  Price,
  Minted,
  Input,
  MintBtn,
  QuestionBtn,
  QuestionBtn2,
  RefInfo,
  CloseBtn,
  RenewBtn,
  Text,
  Column,
  Row4,
  Text2,
  SubText,
} from "./StyledCard";
import { GiCardExchange } from "react-icons/gi";
import { FaQuestionCircle, FaRegWindowClose } from "react-icons/fa";
import { BsCheck2Circle } from "react-icons/bs";
import Loader from "../elements/Loader";

const contractAddress = "0x4983d1F8B21c0e73e7C20a46ad79280558d58c35";

export default function SwapMint() {
  const [checkbox, setCheckbox] = useState("");
  const [subDuration, setSubDuration] = useState("");
  const [ref, setRef] = useState("");
  const [refMint, setRefMint] = useState();
  const [refInfoOpen, setRefInfoOpen] = useState(false);
  const web3reactContext = useWeb3React();

  const [data, setData] = useState({
    maxSupply: "",
    minted: "",
    priceBUSD0: "0",
    priceBUSD1: "0",
    priceBNB0: "0",
    priceBNB1: "0",
    minRefs: "",
    userRefs: "",
    subTimeLeft: "",
    gotMint: "",
  });
  const [nonLoggedData, setNonLoggedData] = useState({
    priceBUSD0: "0",
    priceBUSD1: "0",
    priceBNB0: "0",
    priceBNB1: "0",
  });
  const WSS = "https://bsc.nodereal.io";

  const provider = new ethers.providers.JsonRpcProvider(WSS);
  const contract = new ethers.Contract(contractAddress, Abi, provider);
  var ChainID = web3reactContext.chainId;
  const notyf = new Notyf({
    duration: 8000,
    position: {
      x: "right",
      y: "top",
    },
    dismissible: true,
    ripple: true,
    types: [
      {
        type: "error",
        background: "#262626e1",
      },
      {
        type: "warning",
        background: "#262626e1",
        icon: false,
        duration: 20000,
      },
      {
        type: "success",
        background: "#262626e1",
        duration: 300000,
      },
    ],
  });

  var fullLink = window.location.href;
  var link = fullLink.replace("https://www.proficy.io/swap/mint", "");
  var refLink = link.replace("/ref/", "");

  const getRef = () => {
    if (refLink.length > 5) {
      setRef(String(refLink));
    }
  };

  const getRefMint = () => {
    if (ref === "") {
      setRefMint("0x0000000000000000000000000000000000000000");
    } else setRefMint(ref);
  };

  async function getNonLoggedData() {
    if (web3reactContext.active === false) {
      const prices = await contract.getPrices();
      const priceBUSD = prices.priceUSD[0];
      const priceBNB = prices.priceBNB[0];

      setNonLoggedData({
        priceBUSD0: Number(priceBUSD[0]),
        priceBUSD1: Number(priceBUSD[1]),
        priceBNB0: ethers.utils.formatUnits(priceBNB[0]),
        priceBNB1: ethers.utils.formatUnits(priceBNB[1]),
      });
    }
  }

  async function getData() {
    if (web3reactContext.active) {
      const maxSupply = await contract.MAX();
      const minted = await contract.totalSupply();
      const prices = await contract.getPrices();
      const priceBUSD = prices.priceUSD[0];
      const priceBNB = prices.priceBNB[0];
      const minRefs = await contract.minReferrals();
      const userRefs = await contract.referralMap(web3reactContext.account);
      const user = await contract.getUser(web3reactContext.account);

      setData({
        maxSupply: Number(maxSupply),
        minted: Number(minted),
        priceBUSD0: Number(priceBUSD[0]),
        priceBUSD1: Number(priceBUSD[1]),
        priceBNB0: ethers.utils.formatUnits(priceBNB[0]),
        priceBNB1: ethers.utils.formatUnits(priceBNB[1]),
        mintPriceBNB0: priceBNB[0],
        mintPriceBNB1: priceBNB[1],
        minRefs: Number(minRefs),
        userRefs: Number(userRefs[0]),
        gotMint: Number(user[0]),
        subTimeLeft: Number(user[1][0]) * 3,
      });
    }
  }

  function TimeLeft() {
    var seconds = data.subTimeLeft;
    var days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    var hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;

    return (
      <>
        (for {days} days & {hours} hours)
      </>
    );
  }

  var mint = async () => {
    var getContract = (library, account) => {
      var signer = library.getSigner(account).connectUnchecked();
      var contract = new ethers.Contract(contractAddress, Abi, signer);
      return contract;
    };
    try {
      var myContract = getContract(
        web3reactContext.library,
        web3reactContext.account
      );
      if (refMint === web3reactContext.account) {
        return notyf.error("You can not refer yourself!");
      }
      if (checkbox === "") {
        return notyf.error("Select plan first!");
      }
      if (ethers.utils.isAddress(refMint) === false) {
        return notyf.error("Referral is not a valid address!");
      }
      var response = await myContract.mintNFT(refMint, subDuration, {
        value: checkbox,
        gasLimit: 1000000,
      });
      var TxHash = response.hash;
      var receipt = await response.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "You successfully minted <b>ProficySwap NFT</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  };

  var mintRef = async () => {
    var getContract = (library, account) => {
      var signer = library.getSigner(account).connectUnchecked();
      var contract = new ethers.Contract(contractAddress, Abi, signer);
      return contract;
    };
    try {
      var myContract = getContract(
        web3reactContext.library,
        web3reactContext.account
      );
      if (checkbox === "") {
        return notyf.error("Select plan first!");
      }
      var response = await myContract.renewSubscription(subDuration, {
        value: checkbox,
        gasLimit: 1000000,
      });
      var TxHash = response.hash;
      var receipt = await response.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "You successfully renewed <b>ProficySwap Subscribtion</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  };

  var mintFree = async () => {
    var getContract = (library, account) => {
      var signer = library.getSigner(account).connectUnchecked();
      var contract = new ethers.Contract(contractAddress, Abi, signer);
      return contract;
    };
    try {
      var myContract = getContract(
        web3reactContext.library,
        web3reactContext.account
      );
      var response = await myContract.exchangeReferrals({
        gasLimit: 1000000,
      });

      var TxHash = response.hash;
      var receipt = await response.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "Thanks again, Partner! <b>Now you can freely use ProficySwap</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getData();
    getRef();
    getNonLoggedData();
    getRefMint();
    console.log(refMint);
  }, [
    data.subTimeLeft,
    data.minted,
    web3reactContext.account,
    mint,
    mintRef,
    mintFree,
  ]);

  function ConnectionError() {
    return notyf.error("Connect your wallet to BSC MainNet first");
  }

  if (web3reactContext.active === false) {
    return (
      <>
        <BrowserView>
          <React.Fragment>
            <Tilt
              className="swap-card"
              tiltMaxAngleX={5}
              tiltMaxAngleY={5}
              transitionSpeed={600}
              transitionEasing={true}
            >
              <BotCard>
                <CardTitle>Mint NFT For Swap Access</CardTitle>
                <Row>
                  <CheckboxDiv>
                    <Label>
                      <Column>
                        <Row4>
                          <Checkbox
                            type="radio"
                            name="mintSelect"
                            value={nonLoggedData.mintPriceBNB0}
                            onChange={(e) => {
                              setCheckbox(e.target.value);
                              setSubDuration(201600);
                            }}
                          />
                          <Checkmark />
                          &nbsp;One Week
                        </Row4>
                        <Price>
                          (${nonLoggedData.priceBUSD0} /{" "}
                          {nonLoggedData.priceBNB0.substring(0, 4)} BNB)
                        </Price>
                      </Column>
                    </Label>
                    <Label>
                      <Column>
                        <Row4>
                          <Checkbox
                            type="radio"
                            name="mintSelect"
                            value={nonLoggedData.mintPriceBNB1}
                            onChange={(e) => {
                              setCheckbox(e.target.value);
                              setSubDuration(864000);
                            }}
                          />
                          <Checkmark />
                          &nbsp;One Month
                        </Row4>
                        <Price>
                          (${nonLoggedData.priceBUSD1} / (
                          {nonLoggedData.priceBNB1.substring(0, 4)} BNB)
                        </Price>
                      </Column>
                    </Label>
                  </CheckboxDiv>
                  <Image>
                    <GiCardExchange />
                  </Image>
                </Row>
                <MintBtn style={{ margin: "0" }} onClick={ConnectionError}>
                  <BsCheck2Circle />
                  &nbsp;MINT
                </MintBtn>
              </BotCard>
            </Tilt>
          </React.Fragment>
        </BrowserView>
        <MobileView>
          <React.Fragment>
            <div className="swap-card">
              <BotCard>
                <CardTitle>Swap Access</CardTitle>
                <Row>
                  <CheckboxDiv>
                    <Label>
                      <Column>
                        <Row4>
                          <Checkbox
                            type="radio"
                            name="mintSelect"
                            value={nonLoggedData.mintPriceBNB0}
                            onChange={(e) => {
                              setCheckbox(e.target.value);
                              setSubDuration(201600);
                            }}
                          />
                          <Checkmark />
                          &nbsp;One Week
                        </Row4>
                        <Price>
                          (${nonLoggedData.priceBUSD0} /{" "}
                          {nonLoggedData.priceBNB0.substring(0, 4)} BNB)
                        </Price>
                      </Column>
                    </Label>
                    <Label>
                      <Column>
                        <Row4>
                          <Checkbox
                            type="radio"
                            name="mintSelect"
                            value={nonLoggedData.mintPriceBNB1}
                            onChange={(e) => {
                              setCheckbox(e.target.value);
                              setSubDuration(864000);
                            }}
                          />
                          <Checkmark />
                          &nbsp;One Month
                        </Row4>
                        <Price>
                          (${nonLoggedData.priceBUSD1} / (
                          {nonLoggedData.priceBNB1.substring(0, 4)} BNB)
                        </Price>
                      </Column>
                    </Label>
                  </CheckboxDiv>
                  <Image>
                    <GiCardExchange />
                  </Image>
                </Row>
                <MintBtn style={{ margin: "0" }} onClick={ConnectionError}>
                  <BsCheck2Circle />
                  &nbsp;MINT
                </MintBtn>
              </BotCard>
            </div>
          </React.Fragment>
        </MobileView>
      </>
    );
  }

  if (data.minted === "") {
    return (
      <>
        <BrowserView>
          <React.Fragment>
            <Tilt
              className="swap-card"
              tiltMaxAngleX={5}
              tiltMaxAngleY={5}
              transitionSpeed={600}
              transitionEasing={true}
            >
              <BotCard>
                <Loader />
              </BotCard>
            </Tilt>
          </React.Fragment>
        </BrowserView>
        <MobileView>
          <React.Fragment>
            <div className="bot-card">
              <BotCard>
                <Loader />
              </BotCard>
            </div>
          </React.Fragment>
        </MobileView>
      </>
    );
  }
  return (
    <>
      <BrowserView>
        <React.Fragment>
          <Tilt
            className="swap-card"
            tiltMaxAngleX={5}
            tiltMaxAngleY={5}
            transitionSpeed={600}
            transitionEasing={true}
          >
            <BotCard>
              {data.userRefs >= data.minRefs && data.minRefs !== "" ? (
                <>
                  <CardTitle>Thanks Partner!</CardTitle>
                  <Row3>
                    <b>
                      As a thank you for Your co-operation, You get acces to
                      ProficySwap for a full month for free!
                    </b>{" "}
                    <Text>
                      (<b>{data.minRefs}</b>/<b>{data.userRefs}</b>&nbsp;
                      referrals will be used for this mint)
                    </Text>{" "}
                  </Row3>
                  {web3reactContext.active && ChainID === 56 ? (
                    <RenewBtn onClick={mintFree}>
                      <BsCheck2Circle />
                      &nbsp;Mint for FREE!
                    </RenewBtn>
                  ) : (
                    <RenewBtn onClick={ConnectionError}>
                      <BsCheck2Circle />
                      &nbsp;Mint for FREE!
                    </RenewBtn>
                  )}
                </>
              ) : (
                <>
                  {data.gotMint === 0 ? (
                    <>
                      <Column>
                        <CardTitle>Mint NFT For Swap Access</CardTitle>
                        <Minted>
                          {data.maxSupply - data.minted} NFTs available.
                        </Minted>
                      </Column>
                    </>
                  ) : (
                    <>
                      {data.subTimeLeft >>> 0 ? (
                        <Column>
                          <CardTitle>Extend Subscription</CardTitle>
                          <Minted>
                            <Text>
                              Your Subscription is &nbsp;<b>ACTIVE</b>
                            </Text>
                          </Minted>
                          <Minted>
                            <Text2>
                              <TimeLeft />
                            </Text2>
                          </Minted>
                          <Minted>
                            You have <b>{data.userRefs} referrals.</b>
                            <QuestionBtn2 onClick={() => setRefInfoOpen(true)}>
                              <FaQuestionCircle />
                            </QuestionBtn2>
                          </Minted>
                        </Column>
                      ) : (
                        <Column>
                          <CardTitle>Extend Subscription</CardTitle>
                          <Minted>
                            <Text>
                              Your Subscription is &nbsp;<b>NOT ACTIVE</b>
                            </Text>
                          </Minted>
                          <Minted>
                            You have <b>{data.userRefs} referrals.</b>
                            <QuestionBtn2 onClick={() => setRefInfoOpen(true)}>
                              <FaQuestionCircle />
                            </QuestionBtn2>
                          </Minted>
                        </Column>
                      )}
                    </>
                  )}
                  <Row>
                    {refInfoOpen ? (
                      <RefInfo>
                        <CloseBtn onClick={() => setRefInfoOpen(false)}>
                          <FaRegWindowClose />
                        </CloseBtn>
                        <CardTitle>Referral System</CardTitle>
                        <ul>
                          <b>Someone referred you to ProficySwap?</b>
                          <li>
                            Enter his/her wallet address and that person will be
                            able to mint free NFT/or extend subscription for a
                            month after <b>{data.minRefs}</b> referrals.
                          </li>
                          <b>Want free access to swap?</b>
                          <li>
                            Refer friends with a <b>this link</b> to use our
                            platform to earn free mint/extension for a month
                            after <b>{data.minRefs}</b> referrals. So far you
                            got <b>{data.userRefs}</b> referrals.
                          </li>
                        </ul>
                      </RefInfo>
                    ) : (
                      <></>
                    )}
                    <CheckboxDiv>
                      <Label>
                        <Column>
                          <Row4>
                            <Checkbox
                              type="radio"
                              name="mintSelect"
                              value={data.mintPriceBNB0}
                              onChange={(e) => {
                                setCheckbox(e.target.value);
                                setSubDuration(201600);
                              }}
                            />
                            <Checkmark />
                            &nbsp;One Week
                          </Row4>
                          <Price>
                            ({data.priceBUSD0}$ /{" "}
                            {data.priceBNB0.substring(0, 4)} BNB)
                          </Price>
                        </Column>
                      </Label>
                      <Label>
                        <Column>
                          <Row4>
                            <Checkbox
                              type="radio"
                              name="mintSelect"
                              value={data.mintPriceBNB1}
                              onChange={(e) => {
                                setCheckbox(e.target.value);
                                setSubDuration(864000);
                              }}
                            />
                            <Checkmark />
                            &nbsp;One Month
                          </Row4>
                          <Price>
                            ({data.priceBUSD1}$ / (
                            {data.priceBNB1.substring(0, 4)} BNB)
                          </Price>
                        </Column>
                      </Label>
                    </CheckboxDiv>
                    <Image>
                      <GiCardExchange />
                    </Image>
                  </Row>
                  {data.gotMint === 0 ? (
                    <Row2>
                      <SubText>Referrer Address:</SubText>
                      <Input
                        spellCheck={false}
                        defaultValue={ref}
                        onChange={(e) => setRef(e.target.value)}
                      ></Input>
                      <QuestionBtn onClick={() => setRefInfoOpen(true)}>
                        <FaQuestionCircle />
                      </QuestionBtn>
                      {web3reactContext.active && ChainID === 56 ? (
                        <MintBtn onClick={mint}>
                          <BsCheck2Circle />
                          &nbsp;MINT
                        </MintBtn>
                      ) : (
                        <MintBtn onClick={ConnectionError}>
                          <BsCheck2Circle />
                          &nbsp;MINT
                        </MintBtn>
                      )}
                    </Row2>
                  ) : (
                    <>
                      {web3reactContext.active && ChainID === 56 ? (
                        <RenewBtn onClick={mintRef}>
                          <BsCheck2Circle />
                          &nbsp;Renew Subscription
                        </RenewBtn>
                      ) : (
                        <RenewBtn onClick={ConnectionError}>
                          <BsCheck2Circle />
                          &nbsp;Renew Subscription
                        </RenewBtn>
                      )}
                    </>
                  )}
                </>
              )}
            </BotCard>
          </Tilt>
        </React.Fragment>
      </BrowserView>
      <MobileView>
        <React.Fragment>
          <div className="bot-card">
            <BotCard>
              {data.userRefs >= data.minRefs && data.minRefs !== "" ? (
                <>
                  <CardTitle>Thanks Partner!</CardTitle>
                  <Row3>
                    <b>
                      As a thank you for Your co-operation, You get acces to
                      ProficySwap for a full month for free!
                    </b>{" "}
                    <Text>
                      (<b>{data.minRefs}</b>/<b>{data.userRefs}</b>&nbsp;
                      referrals will be used for this mint)
                    </Text>{" "}
                  </Row3>
                  {web3reactContext.active && ChainID === 56 ? (
                    <RenewBtn onClick={mintFree}>
                      <BsCheck2Circle />
                      &nbsp;Mint for FREE!
                    </RenewBtn>
                  ) : (
                    <RenewBtn onClick={ConnectionError}>
                      <BsCheck2Circle />
                      &nbsp;Mint for FREE!
                    </RenewBtn>
                  )}
                </>
              ) : (
                <>
                  {data.gotMint === 0 ? (
                    <>
                      <Column>
                        <CardTitle>Swap Access</CardTitle>
                        <Minted>
                          {data.maxSupply - data.minted} NFTs available.
                        </Minted>
                      </Column>
                    </>
                  ) : (
                    <>
                      {data.subTimeLeft >>> 0 ? (
                        <Column>
                          <CardTitle>Extend Subscription</CardTitle>
                          <Minted>
                            <Text>
                              Your Subscription is &nbsp;<b>ACTIVE</b>
                            </Text>
                          </Minted>
                          <Minted>
                            <Text2>
                              <TimeLeft />
                            </Text2>
                          </Minted>
                          <Minted>
                            You have <b>{data.userRefs} referrals.</b>
                            <QuestionBtn2 onClick={() => setRefInfoOpen(true)}>
                              <FaQuestionCircle />
                            </QuestionBtn2>
                          </Minted>
                        </Column>
                      ) : (
                        <Column>
                          <CardTitle>Extend Subscription</CardTitle>
                          <Minted>
                            <Text>
                              Your Subscription is &nbsp;<b>NOT ACTIVE</b>
                            </Text>
                          </Minted>
                          <Minted>
                            You have <b>{data.userRefs} referrals.</b>
                            <QuestionBtn2 onClick={() => setRefInfoOpen(true)}>
                              <FaQuestionCircle />
                            </QuestionBtn2>
                          </Minted>
                        </Column>
                      )}
                    </>
                  )}
                  <Row>
                    {refInfoOpen ? (
                      <RefInfo>
                        <CloseBtn onClick={() => setRefInfoOpen(false)}>
                          <FaRegWindowClose />
                        </CloseBtn>
                        <CardTitle>Referral System</CardTitle>
                        <ul>
                          <b>Someone referred you to ProficySwap?</b>
                          <li>
                            Enter his/her wallet address and that person will be
                            able to mint free NFT/or extend subscription for a
                            month after <b>{data.minRefs}</b> referrals.
                          </li>
                          <b>Want free access to swap?</b>
                          <li>
                            Refer friends with a <b>this link</b> to use our
                            platform to earn free mint/extension for a month
                            after <b>{data.minRefs}</b> referrals. So far you
                            got <b>{data.userRefs}</b> referrals.
                          </li>
                        </ul>
                      </RefInfo>
                    ) : (
                      <></>
                    )}
                    <CheckboxDiv>
                      <Label>
                        <Column>
                          <Row4>
                            <Checkbox
                              type="radio"
                              name="mintSelect"
                              value={data.mintPriceBNB0}
                              onChange={(e) => {
                                setCheckbox(e.target.value);
                                setSubDuration(201600);
                              }}
                            />
                            <Checkmark />
                            &nbsp;One Week
                          </Row4>
                          <Price>
                            ({data.priceBUSD0}$ /{" "}
                            {data.priceBNB0.substring(0, 4)} BNB)
                          </Price>
                        </Column>
                      </Label>
                      <Label>
                        <Column>
                          <Row4>
                            <Checkbox
                              type="radio"
                              name="mintSelect"
                              value={data.mintPriceBNB1}
                              onChange={(e) => {
                                setCheckbox(e.target.value);
                                setSubDuration(864000);
                              }}
                            />
                            <Checkmark />
                            &nbsp;One Month
                          </Row4>
                          <Price>
                            ({data.priceBUSD1}$ / (
                            {data.priceBNB1.substring(0, 4)} BNB)
                          </Price>
                        </Column>
                      </Label>
                    </CheckboxDiv>
                    <Image>
                      <GiCardExchange />
                    </Image>
                  </Row>
                  {data.gotMint === 0 ? (
                    <Row2>
                      <SubText>Referrer Address:</SubText>
                      <Input
                        spellCheck={false}
                        defaultValue={ref}
                        onChange={(e) => setRef(e.target.value)}
                      ></Input>
                      <QuestionBtn onClick={() => setRefInfoOpen(true)}>
                        <FaQuestionCircle />
                      </QuestionBtn>
                      {web3reactContext.active && ChainID === 56 ? (
                        <MintBtn onClick={mint}>
                          <BsCheck2Circle />
                          &nbsp;MINT
                        </MintBtn>
                      ) : (
                        <MintBtn onClick={ConnectionError}>
                          <BsCheck2Circle />
                          &nbsp;MINT
                        </MintBtn>
                      )}
                    </Row2>
                  ) : (
                    <>
                      {web3reactContext.active && ChainID === 56 ? (
                        <RenewBtn onClick={mintRef}>
                          <BsCheck2Circle />
                          &nbsp;Renew Subscription
                        </RenewBtn>
                      ) : (
                        <RenewBtn onClick={ConnectionError}>
                          <BsCheck2Circle />
                          &nbsp;Renew Subscription
                        </RenewBtn>
                      )}
                    </>
                  )}
                </>
              )}
            </BotCard>
          </div>
        </React.Fragment>
      </MobileView>
    </>
  );
}
