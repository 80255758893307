import styled from "styled-components";

export const ContactButton = styled.button`
    position: relative;
    display: inline-flex;
    border: 2px solid rgb(90, 90, 90);
    background: rgba(0, 0, 0, 0.05);
    margin-right: 10px;    
    width: 38px;
    height: 38px;
    border-radius: 50px;
    padding: 6px;
    font-size: 22px;
    color: rgba(255, 153, 0, 0.7);
    transform: scale(1);
    transition: all 0.3s linear;
    cursor: pointer;
    z-index: 110;

    &:hover {
        border: 2px solid rgb(160, 160, 160);
        color: rgba(255, 153, 0, 0.9);
        transform: scale(1.05);
        transition: all 0.3s linear;
    }

    &:active {
        transform: scale(0.9);
        transition: all 0.3s linear;
    }
`