import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { injected, walletconnect, resetWalletConnector } from './connectors';
import { NoEthereumProviderError, UserRejectedRequestError } from '@web3-react/injected-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorWC } from '@web3-react/walletconnect-connector';
import styled from 'styled-components';
import React, {useEffect} from 'react';
import meta from '../elements/img/meta.png';
import wConnect from '../elements/img/wConnect.png';
import { shortenAddress } from '../web3/helpers';
import { CgLogOff } from 'react-icons/cg';
import { BiError } from 'react-icons/bi'
import { motion } from 'framer-motion';

const ConnectBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 4rem;
    margin-bottom: 1rem;
    background: transparent;
    border: none;
    box-shadow: 0px -1px 35px 12px rgba(0,0,0,0.25);
    text-shadow:
            0 0 2px rgba(255, 255, 255, 0.6),
            0 0 5px rgba(255, 255, 255, 0.4),
            0 0 7px rgba(255, 255, 255, 0.2),
            0 0 11px rgb(246, 161, 35,0.5),
            0 0 15px rgb(246, 161, 35,0.4);
    border-radius: 15px;
    color: rgba(225, 225, 225, 0.7);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s linear;

    @media screen and (max-width: 800px) {
        width: 85%;
        margin-bottom: 0.5rem;
    }
    
    & span {
        transform: scale(1);
        transition: all 0.3s linear;
    }

    &:hover {
        color: rgba(255, 165, 0, 0.9);
        transition: all 0.3s linear;
        
        & span {
            transform: scale(1.02);
            transition: all 0.3s linear;
        }
    }
`

const ConnectedAs = styled.div`
    padding-top: 1rem;
    font-size: 16px;
    font-weight: 500;
    color: rgba(225, 225, 225, 0.8);
`

const ConnectedOn = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: rgba(225, 225, 225, 0.8);
`
const ConnectionInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 10rem;
    bottom: 60px;
    height: 1rem;
`

const MetaLogo = styled.img`
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
`

const WCLogo = styled.img`
    width: 3rem;
    height: 2rem;
    margin-right: 1rem;
`
const Addy = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: rgba(225, 225, 225, 0.8);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.3),
    0 0 5px rgba(255, 255, 255, 0.2),
    0 0 7px rgba(255, 255, 255, 0.1),
    0 0 11px rgb(246, 161, 35,0.25),
    0 0 15px rgb(246, 161, 35,0.2);
`

const ETH = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: rgba(51, 153, 255);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.3),
    0 0 5px rgba(255, 255, 255, 0.2),
    0 0 7px rgba(255, 255, 255, 0.1),
    0 0 11px rgb(246, 161, 35,0.25),
    0 0 15px rgb(246, 161, 35,0.2);
`
const BSC = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 204, 0);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.3),
    0 0 5px rgba(255, 255, 255, 0.2),
    0 0 7px rgba(255, 255, 255, 0.1),
    0 0 11px rgb(246, 161, 35,0.25),
    0 0 15px rgb(246, 161, 35,0.2);
`
const ErrorMessage = styled.div`
position: relative;
bottom: -1rem;
width: 100%;
max-height: 3.5rem;
text-shadow:
    0 0 2px rgba(255, 255, 255, 0.1),
    0 0 5px rgba(255, 255, 255, 0.0.5),
    0 0 7px rgba(255, 255, 255, 0.03),
    0 0 11px rgb(246, 161, 35,0.06),
    0 0 15px rgb(246, 161, 35,0.05);
`

const ErrorMsg = styled.div`
font-size: 16px;
font-weight: 600;
color: rgb(255, 0, 0, 0.7);
padding: 0 10px 0 10px;
text-align: center;
`
const ErrorText = styled.div`
font-weight: 400;
color: rgb(225, 225, 225, 0.7);
padding: 0 10px 0 10px;
text-align: center;
`

const Connect = ({ handleClose }) => {
	const web3reactContext = useWeb3React(); 
    const { error } = useWeb3React();

    function getErrorMessage(error) {
        if (error instanceof UnsupportedChainIdError) {
            return (
                <motion.div
                animate={{opacity: [0, 1] , tranformX: [200, 0]}}     
                transition={{ duration: 0.6 }}>
                <ErrorMsg><BiError transform='scale(2)' />&nbsp;&nbsp;ERROR:</ErrorMsg>
                <ErrorText> You're connected to an unsupported network.</ErrorText>
                </motion.div>
            ); 
        }
        else if (error instanceof NoEthereumProviderError) {
            return (
                <motion.div
                animate={{opacity: [0, 1] , tranformX: [200, 0]}}     
                transition={{ duration: 0.6 }}>
                <ErrorMsg><BiError transform='scale(2)' />&nbsp;&nbsp;ERROR:</ErrorMsg>
                <ErrorText> No injected wallet found in your browser.</ErrorText>
                </motion.div>
            );
        }
        else if (error instanceof UserRejectedRequestError) {
            return (
                <motion.div
                animate={{opacity: [0, 1] , tranformX: [200, 0]}}     
                transition={{ duration: 0.6 }}>
                <ErrorMsg><BiError transform='scale(2)' />&nbsp;&nbsp;ERROR:</ErrorMsg>
                <ErrorText> User rejected request.</ErrorText>
                </motion.div>
            );
        }
        else if (error instanceof UserRejectedRequestErrorWC) {
            return (
                <motion.div
                animate={{opacity: [0, 1] , tranformX: [200, 0]}}     
                transition={{ duration: 0.6 }}>
                <ErrorMsg><BiError transform='scale(2)' />&nbsp;&nbsp;ERROR:</ErrorMsg>
                <ErrorText> User rejected request.</ErrorText>
                </motion.div>
            );
        }

        return null;
    };

	const connectMetamask = async () => {
		try {
			await web3reactContext.activate(injected);
            localStorage.setItem('isWalletConnected', true);
		} catch (ex) {
			console.log(ex);
		}
	};

	const connectWalletConnect = async () => {
		try {
			resetWalletConnector(walletconnect);
			await web3reactContext.activate(walletconnect);
		} catch (ex) {
			console.log(ex);
		}
	};

    const disconnectWallet = async () => {
        try {
            web3reactContext.deactivate(injected, walletconnect);
            localStorage.setItem('isWalletConnected', false);
        } catch (ex) {
            console.log(ex);
        }
    };

    var ChainName = web3reactContext.chainId;
    let Name;

    if (ChainName === 1) {
        Name = <ETH>Ethereum Mainnet</ETH>
    }
    
    else if (ChainName === 56)  {
        Name = <BSC>Binance Smart Chain</BSC>
    }

    else if (ChainName === 97)  {
        Name = <BSC>BSC TestNet</BSC>
    }

    else if (ChainName === 31337)  {
        Name = <BSC>Hardhat</BSC>
    }

	return (
		<>
        {web3reactContext.active ?<ConnectBtn onClick={() => {disconnectWallet(); handleClose();}}><CgLogOff transform='scale(1.5)' color='rgb(255, 0, 0, 0.7)' />&nbsp; <span>Disconnect</span></ConnectBtn> :
         <ConnectBtn onClick={() => {connectMetamask(); handleClose();}} ><MetaLogo src={meta} /><span>Metamask</span></ConnectBtn>}
        {web3reactContext.active ?<></> : <ConnectBtn onClick={() => {connectWalletConnect(); handleClose();}}><WCLogo src={wConnect} /><span>WalletConnect</span></ConnectBtn>}
        <motion.div
                animate={{opacity: [0, 1] , tranformX: [200, 0]}}     
                transition={{ duration: 0.6 }}>
        <ErrorMessage>{getErrorMessage(error)}</ErrorMessage> 
        </motion.div>
        <motion.div
                animate={{opacity: [0, 1], tranformX: [200, 0]}}     
                transition={{ duration: 0.6 }}>
        <ConnectionInfo>
        <>{web3reactContext.active ? <ConnectedAs>Connected as: <Addy>{shortenAddress(web3reactContext.account)}</Addy></ConnectedAs> : <></>}</>
        <>{web3reactContext.active ? <ConnectedOn>on: {Name}</ConnectedOn> : <></>}</>
        </ConnectionInfo>
        </motion.div>
		</>
	);
};
export default Connect;