import styled, { keyframes } from 'styled-components';

const Wave1Anim = keyframes`
    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: 1853px;
    }
`

const Wave2Anim = keyframes`
    0% {
        background-position-x: 1853px;
    }
    100% {
        background-position-x: 0px;
    }
`

export const Wave1 = styled.div`
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: 43px;
    background-color: transparent !important;
    background: url(/img/wave.png);
    background-size: 1853px 43px;
    opacity: 1;
    animation: ${Wave1Anim} 30s linear infinite;
    z-index: 99;
`

export const Wave2 = styled.div`
    position: fixed;
    opacity: 0.2;
    left: 0;
    bottom: 40px;
    width: 100%;
    height: 67px;
    background-color: transparent !important;
    background: url(/img/wave.png);
    background-size: 1853px 67px;
    animation: ${Wave2Anim} 45s linear infinite;
    z-index: 2;
`

