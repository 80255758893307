import React from "react";
import { ContactButton } from "./ContactButton.styled";
import { FaGithub } from 'react-icons/fa';


function GithubBtn() {
    return(
        <ContactButton 
        onClick={() => {
            window.open("https://github.com/proficy/swap-documentation", "_blank");
          }}
        >
            <FaGithub />
        </ContactButton>
    );
}

export default GithubBtn