import React from 'react';
import styled from 'styled-components'

const LogoImg = styled.img`
    position: relative;
    bottom: -30px;
    width: 3rem;
    height: 3rem;
    margin: 12px 5px 0 10px;
    opacity: 0.6;

    @media screen and (max-width: 800px) {
        bottom: -35px;
        width: 2.5rem;
        height: 2.5rem;
    }
`
const LogoName = styled.span`
    position: absolute;
    bottom: 40px;
    color: rgba(255, 153, 0, 0.902);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.3),
    0 0 5px rgba(255, 255, 255, 0.2),
    0 0 7px rgba(255, 255, 255, 0.1),
    0 0 11px rgb(246, 161, 35,0.25),
    0 0 15px rgb(246, 161, 35,0.2);
    font-size: 20px;
    font-weight: 600;
    line-height: 0;
    letter-spacing: 0.2rem;
    @media screen and (max-width: 800px) {
        font-size: 18px;
    }
`

function Logo() {
    return(
        <>
        <LogoImg img src="/img/PROFICY.png" ></LogoImg>
        <LogoName>PROFICY</LogoName>
        </>
    )
}

export default Logo