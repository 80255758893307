import React from "react";
import AnimatedPage3 from "../elements/AnimatedPage3";
import { PageTitle, Abouts } from "./Styles";
import styled from "styled-components";

const Wrapper = styled.div`
  @media screen and (max-width: 800px) {
    position: absolute;
    top: 0;
    width: 95%;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 0 0 74px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
  @media screen and (min-width: 800px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-height: 100vh;
    width: calc(100vw - 13rem);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 0 74px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
`;

const StaticPageTitle = styled(PageTitle)`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
`;

const Tos = () => {
  return (
    <AnimatedPage3>
      <Wrapper>
      <StaticPageTitle>Terms of Service</StaticPageTitle>
        <Abouts>
          <h2>Introduction</h2>
          <p>
            The Terms of Use for PROFICY Services establish guidelines for using the online products and services offered by PROFICY. The parties associated with these terms are PROFICY, which provides a range of services including but not limited to Telegram bots, channels, groups, and applications created and disseminated by PROFICY, hereinafter referred to as either “PROFICY Services” or "PROFICY." The other parties are individuals or entities using these services and applications across platforms like Telegram, hereinafter referred to as "users."
            Your use of PROFICY Services signifies your agreement to these terms and conditions.
          </p>

          <h2>Limited Liability</h2>
          <p>
            While we do our best to regularly update the content surrounding all PROFICY services, the information provided may occasionally be incomplete or outdated. We offer these materials without any guarantees or claims regarding their accuracy. These materials are subject to change without prior notice and errors may occasionally occur, particularly in relation to pricing information. PROFICY is not liable for any consequences arising from such errors, nor does the presence of errors establish a binding agreement. Our service may include hyperlinks to third-party websites or services. While we aim to provide links to reputable and ethical websites and channels, we have no control over the content and nature of these external sites and channels. As such, the presence of hyperlinks to external sites and channels should not be seen as an endorsement or recommendation from PROFICY. Additionally, third-party site owners and the content of external sites and channels can change without notice, which may occur before we have the opportunity to remove a potentially problematic link. We advise you to review the Privacy Policies and Terms of Service of any external sites you visit, as these policies and terms are beyond our control. Unless mandated by law, the owners and contributors of PROFICY shall not be held liable for any damages, including but not limited to loss of use, loss of profits, or loss of data, arising from the use of PROFICY services.
          </p>

          <h2>General Disclaimer</h2>
          <p>
            All PROFICY services and their contents are provided on an "as-is" and "as-available" basis, without any warranty or representations of any kind, whether express or implied. PROFICY acts as a distributor and not a publisher of the content supplied by third parties. Therefore, PROFICY does not exercise editorial control over such content and makes no warranty or representation regarding the accuracy, reliability, or currency of any information, content, service, or merchandise provided through or accessible via PROFICY services. PROFICY explicitly disclaims all warranties and representations concerning any content transmitted on or in connection with PROFICY services or on linked sites, as well as the products provided as part of, or related to, PROFICY services. These disclaimed warranties include but are not limited to any warranties of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. Any oral advice or written information provided by PROFICY, its affiliates, employees, officers, directors, agents, or similar parties does not constitute a warranty. Price and availability information is subject to change without notice. While PROFICY strives to provide a seamless and reliable service, we cannot guarantee that PROFICY services will be uninterrupted, free from corruption, timely, or error-free.
          </p>

          <h2>Possible Risks</h2>
          <p>
            PROFICY shall not assume liability for any losses, damages, or claims arising from incidents falling under the purview of the following categories:
            User Errors: Inadvertent mistakes made by users of PROFICY's software or services, such as forgotten credentials, payments sent to incorrect PROFICY addresses, or unintentional deletion of wallets.
            Software Issues: Problems pertaining to the website or any software or service associated with PROFICY, such as corrupted wallet files, improperly constructed transactions, utilization of insecure cryptographic libraries, or the presence of malware affecting the website or related software or services.
            Hardware Failures: Technical malfunctions in the hardware utilized by users of PROFICY's software or services, including data loss caused by faulty or damaged storage devices.
            Security Concerns: Instances where users encounter security-related challenges while utilizing PROFICY's software or services, such as unauthorized access to user wallets or accounts.
            Third-Party Factors: The actions, omissions, or events involving third parties, for instance, the insolvency of service providers, information security breaches targeting service providers, or fraudulent activities carried out by third parties.
          </p>

          <h2>Copyright Disclaimer</h2>
          <p>
            PROFICY owns all proprietary rights related to these materials. Unless otherwise indicated, the reproduction, distribution, or any form of utilization of these materials is strictly prohibited without explicit written consent from PROFICY unless specifically allowed under mandatory legal regulations (such as the right to cite).
          </p>

          <h2>Errors and Omissions</h2>
          <p>
            PROFICY cannot assume responsibility for any content, code, or inaccuracies. Our services are provided without warranties or guarantees. We are not liable for any special, direct, indirect, consequential, or incidental damages, or any damages whatsoever, whether arising from contract, negligence, or other torts, in connection with the use of PROFICY or its contents. We reserve the right to update, remove, or modify the content of PROFICY services at any time, without prior notice.
          </p>

          <h2>Changes to Disclaimer</h2>
          <p>
            PROFICY reserves the right to modify, revise, or implement alterations to this document to ensure its alignment with our services and guidelines. Any such adjustments will be prominently displayed on this platform unless otherwise legally mandated. Continued use of PROFICY services implies your acceptance and adherence to the revised Disclaimer. However, if you do not wish to consent to the updated Disclaimer or any subsequent modifications, you have the option to delete your account.
          </p>

          <h2>Your Consent</h2>
          <p>
            We value your trust and update our Disclaimer periodically to ensure the utmost transparency regarding the data collected and its usage when you browse our website. By utilizing our service or creating an account, you acknowledge and accept our Disclaimer, along with its associated terms and conditions.
          </p>

          <h2>Tax Obligations</h2>
          <p>
            PROFICY users maintain sole responsibility for identifying applicable taxes related to transactions conducted through PROFICY. The creators or collaborators of PROFICY bear no responsibility for assessing the tax implications associated with transactions facilitated by PROFICY services.
          </p>

          <h2>Arbitration</h2>
          <p>
            Use of PROFICY signifies the user’s consent to resolve any arising disputes related to PROFICY or this Disclaimer through arbitration. This arbitration clause excludes disputes relating to copyright infringement, logo usage, trademark infringement, trade names, trade secrets, or patent issues.
          </p>
          
        </Abouts>
      </Wrapper>
    </AnimatedPage3>
  );
};

export default Tos;
