import React from 'react';
import Logo from '../elements/Logo'
import ConnectBtn from '../elements/buttons/ConnectBtn';
import styled from 'styled-components';
import LeftMenuBtn from './components/LeftMenuBtn';
import { Link } from 'react-router-dom';

const StyledHeader = styled.div`
    position: fixed;
    top: -2rem;
    left: 0;
    background: linear-gradient(180deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    width: 96%;
    height: 7rem;
    margin: 0 2% 0 2%;
    border-radius: 0 0 2em 2em;
    z-index: 100;
`
const LogoDiv = styled.div`
    position: absolute;
    left: 2rem;
    height: 7rem;
    width: 11rem;
    margin-left: 1rem;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s linear;
    }

    @media screen and (max-width: 800px){
        left: 1rem;
        margin-left: 1rem;
    }
`

function SwapHeader() {
    return (
        <StyledHeader>
            <LeftMenuBtn />
            <Link to="/"><LogoDiv> <Logo /> </LogoDiv></Link>
            <ConnectBtn />
        </StyledHeader>
    );
} 

export default SwapHeader