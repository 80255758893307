import React, { useState } from 'react';
import GeneralFAQ from '../faq/GeneralFAQ';
import AnimatedPage3 from '../../elements/AnimatedPage3';
import { BrowserView, MobileView } from 'react-device-detect';
import faqData from '../faq/faqData.json';
import refData from '../faq/refData.json';
import basicData from '../faq/basicData.json';
import nodesData from '../faq/nodesData.json';
import memData from '../faq/memData.json';
import preData from '../faq/preData.json';
import nftData from '../faq/nftData.json';
import { MobWrapper, Title, Question, Answer, Disclaimer, DiscAnswer } from '../faq/Styles';
import { motion, AnimatePresence } from "framer-motion";
import RefFAQ from '../faq/RefFAQ';
import BasicFAQ from '../faq/BasicFAQ';
import NodesFAQ from '../faq/NodesFAQ';
import MemFAQ from '../faq/MemFAQ';
import PreFAQ from '../faq/PreFAQ';
import NftFAQ from '../faq/NftFAQ';

const answerAnim = {
  initial: {
      y: -100,
      opacity: 0,
  },
  animate: {
      y: 0,
      opacity: 1,
      transition: {
          duration: 0.3,
      }
  },
  exit: {
      y: -100,
      opacity: 0,
      transition: {
        duration: 0.3,
    }
  },
};

function FAQ() {

  const [isOpen, setIsOpen] = useState(true);

    return (
      <>
      <MobileView>
            <AnimatedPage3>
              <MobWrapper>
             <motion.div>
    <AnimatePresence>
      <Question as={motion.div}
      key={'disclaimer'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <motion.div> 
       <Title>DISCLAIMER!</Title>
        </motion.div>

      </Question>

      {isOpen && (
        <motion.div
        key={'disclaimerText'}
        variants={answerAnim}
        initial='initial'
        animate='animate'
        exit='exit'
        >
        <Answer>
        <span>
        PROFICY is not liable for any loss of funds through the use of the available bots or connecting your wallet to our minting dashboard. This includes every possible scenario (for example bad trading habits, a user being victim of a cyber attack, servers being attacked, buying cryptocurrencies run by malicious developers etc.)
                  <br/>We explcitly recommend NOT TO USE YOUR MAIN WALLET for any of our bots or to mint an NFT through our website. This is common practice with these kind of products and should always be kept this way.
                  <br/>We at PROFICY took every available measure to prevent anything bad to happen on our end.
                  <br/><br/>THE PROFICY NODE NETWORK IS NOT ESTABLISHED YET. THIS MIGHT LEAD TO INCONSISTENT PERFORMANCE ON THE MEMPOOL BOTS WHILE USING OUR CURRENT NODE SETUP. ALL OTHER BOTS DON'T REQUIRE THE PROFICY NODE NETWORK AND WORK EXCELLENT WITH THE CURRENT SETUP.
                  </span>
        </Answer>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
  <Title>GENERAL</Title>
            {faqData.map((item, id) => {
            return <GeneralFAQ key={id} faqData={item} />;
            })}
      <Title>REFERRAL PROGRAM</Title>
      {refData.map((item, id) => {
            return <RefFAQ key={id} refData={item} />;
            })}
      <Title>BASIC KNOWLEDGE</Title>
      {basicData.map((item, id) => {
            return <BasicFAQ key={id} basicData={item} />;
            })}
      <Title>NODES</Title>
      {nodesData.map((item, id) => {
            return <NodesFAQ key={id} nodesData={item} />;
            })}
      <Title>MEMPOOL/BLOCK BOTS</Title>
      {memData.map((item, id) => {
            return <MemFAQ key={id} memData={item} />;
            })}
      <Title>PRESALE BOTS</Title>
      {preData.map((item, id) => {
            return <PreFAQ key={id} preData={item} />;
            })}
      <Title>NFT BOTS</Title>
      {nftData.map((item, id) => {
            return <NftFAQ key={id} nftData={item} />;
            })}
      <Title>WHALEBOTS</Title>
      <motion.div>
    <AnimatePresence>
      <Question as={motion.div}
      key={'whale'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <motion.div> 
          <span>
          Can I change my wallet when a whale noticed I track him?
          </span>
        </motion.div>

      </Question>

      {!isOpen && (
        <motion.div
        key={'whaleText'}
        variants={answerAnim}
        initial='initial'
        animate='animate'
        exit='exit'
        >
        <Answer>
        <span>
        Yes. You can send your NFT to another wallet and keep going from there.
        </span>
        </Answer>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
            </MobWrapper>
            </AnimatedPage3>
    </MobileView>


          <BrowserView>
          <AnimatedPage3>
            <MobWrapper>
           <motion.div>
  <AnimatePresence>
    <Disclaimer as={motion.div}
    key={'disclaimer'}
      onClick={() => setIsOpen(!isOpen)}
    >
      <motion.div> 
     <Title>DISCLAIMER!</Title>
      </motion.div>

    </Disclaimer>

    {isOpen && (
      <motion.div
      key={'disclaimerText'}
      variants={answerAnim}
      initial='initial'
      animate='animate'
      exit='exit'
      >
      <DiscAnswer>
      <span>
      PROFICY is not liable for any loss of funds through the use of the available bots or connecting your wallet to our minting dashboard. This includes every possible scenario (for example bad trading habits, a user being victim of a cyber attack, servers being attacked, buying cryptocurrencies run by malicious developers etc.)
                <br/>We explcitly recommend NOT TO USE YOUR MAIN WALLET for any of our bots or to mint an NFT through our website. This is common practice with these kind of products and should always be kept this way.
                <br/>We at PROFICY took every available measure to prevent anything bad to happen on our end.
                <br/><br/>THE PROFICY NODE NETWORK IS NOT ESTABLISHED YET. THIS MIGHT LEAD TO INCONSISTENT PERFORMANCE ON THE MEMPOOL BOTS WHILE USING OUR CURRENT NODE SETUP. ALL OTHER BOTS DON'T REQUIRE THE PROFICY NODE NETWORK AND WORK EXCELLENT WITH THE CURRENT SETUP.
                </span>
      </DiscAnswer>
      </motion.div>
    )}
  </AnimatePresence>
</motion.div>
<Title>GENERAL</Title>
          {faqData.map((item, id) => {
          return <GeneralFAQ key={id} faqData={item} />;
          })}
    <Title>REFERRAL PROGRAM</Title>
    {refData.map((item, id) => {
          return <RefFAQ key={id} refData={item} />;
          })}
    <Title>BASIC KNOWLEDGE</Title>
    {basicData.map((item, id) => {
          return <BasicFAQ key={id} basicData={item} />;
          })}
    <Title>NODES</Title>
    {nodesData.map((item, id) => {
          return <NodesFAQ key={id} nodesData={item} />;
          })}
    <Title>MEMPOOL/BLOCK BOTS</Title>
    {memData.map((item, id) => {
          return <MemFAQ key={id} memData={item} />;
          })}
    <Title>PRESALE BOTS</Title>
    {preData.map((item, id) => {
          return <PreFAQ key={id} preData={item} />;
          })}
    <Title>NFT BOTS</Title>
    {nftData.map((item, id) => {
          return <NftFAQ key={id} nftData={item} />;
          })}
    <Title>WHALEBOTS</Title>
    <motion.div>
  <AnimatePresence>
    <Question as={motion.div}
    key={'whale'}
      onClick={() => setIsOpen(!isOpen)}
    >
      <motion.div> 
        <span>
        Can I change my wallet when a whale noticed I track him?
        </span>
      </motion.div>

    </Question>

    {!isOpen && (
      <motion.div
      key={'whaleText'}
      variants={answerAnim}
      initial='initial'
      animate='animate'
      exit='exit'
      >
      <Answer>
      <span>
      Yes. You can send your NFT to another wallet and keep going from there.
      </span>
      </Answer>
      </motion.div>
    )}
  </AnimatePresence>
</motion.div>
          </MobWrapper>
          </AnimatedPage3>
  </BrowserView>
  </>
    );
}

export default FAQ