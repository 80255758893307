import styled from 'styled-components';

export const MobWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100% - 54px;
    padding: 0 0 54px 0;
    display: flex;
    align-items: center; 
    justify-content: top;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
    
    &::-webkit-scrollbar {
      display: none;
    }
`

export const Title = styled.h1`
 @media screen and (max-width: 800px){
  text-align: center;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  color: rgb(255, 165, 0, 0.8);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
 }
 @media screen and (min-width: 800px){
  text-align: center;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  color: rgb(255, 165, 0, 0.8);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3),
    0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35,0.35),
    0 0 15px rgb(246, 161, 35,0.3);
 }
`

export const Question = styled.div`
transition: all 0.3s linear;
z-index: 125;
   @media screen and (max-width: 800px){
     position: relative;
   max-width: 90vw;
   min-width: 90vw;
   min-height: 4rem;
   margin-bottom: 10px;
   padding: 8px;
   display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(68,68,68,1) 0%, rgba(1,1,1,1) 100%);
    border-radius: 1rem;
    background-clip: padding-box;
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-bottom: none;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    cursor: pointer;
    transition: all 0.3s linear;
      & span {
        color: rgb(255, 165, 0);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    font-size: 16px;
    font-weight: 600;
      }
   }
   @media screen and (min-width: 800px){
     position: relative;
     max-width: 60vw;
   min-width: 60vw;
   min-height: 4rem;
   margin-bottom: 10px;
   padding: 8px;
   display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(68,68,68,1) 0%, rgba(1,1,1,1) 100%);
    border-radius: 1rem;
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-bottom: none;
    cursor: pointer;
    transition: all 0.3s linear;
      & span {
        color: rgb(255, 165, 0);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    font-size: 16px;
    font-weight: 600;
      }
   }
`

export const Disclaimer = styled.div`
     position: relative;
     z-index: 125;
   max-width: 60vw;
   min-width: 60vw;
   min-height: 4rem;
   margin-bottom: 10px;
   padding: 8px;
   display: flex;
    justify-content: center;
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-bottom: none;
    align-items: center;
    background: linear-gradient(90deg, rgba(68,68,68,1) 0%, rgba(1,1,1,1) 100%);
    border-radius: 1rem;
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    cursor: pointer;
    transition: all 0.3s linear;
      & span {
        color: rgb(255, 165, 0);
        text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    font-size: 16px;
    font-weight: 600;
      }
`

export const Answer = styled.div`
transition: all 0.3s linear;
   @media screen and (max-width: 800px){
     position: relative;
   width: 85vw;
   min-height: 4rem;
   margin-top: -12px;
   margin-bottom: 10px;
   margin-left: 2.5vw;
   padding: 10px;
   color: #fff;
   display: flex;
    justify-content: center;
    align-items: top;
    background: linear-gradient(30deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    transition: all 0.3s linear;
    & span {
      font-size: 14px;
      & b {
        font-weight: 600;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
   }
   @media screen and (min-width: 800px){
     position: relative;
     max-width: 55vw;
   min-width: 55vw;
   min-height: 2rem;
   margin-top: -12px;
   margin-bottom: 10px;
   margin-left: 2.5vw;
   padding: 12px;
   color: #fff;
   display: flex;
    justify-content: center;
    align-items: top;
    background: linear-gradient(30deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    transition: all 0.3s linear;
    & span {
      font-size: 14px;
      & b {
        font-weight: 600;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
   }
`

export const DiscAnswer = styled.div`
     position: relative;
   width: 55vw;
   min-height: 4rem;
   margin-top: -12px;
   margin-bottom: 10px;
   margin-left: 2.5vw;
   padding: 12px;
   color: #fff;
   display: flex;
    justify-content: center;
    align-items: top;
    background: linear-gradient(30deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);   
    transition: all 0.3s linear;
    & span {
      font-size: 14px;
      & b {
        font-weight: 600;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
`