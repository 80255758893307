import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Backdrop from "../elements/Backdrop";
import { motion } from "framer-motion";
import { GiArrowDunk, GiTrade, GiHammerDrop } from "react-icons/gi";
import { CgCloseO } from "react-icons/cg";
import { ImCog, ImPower } from "react-icons/im";
import { FaTools } from "react-icons/fa";
import { FiDownloadCloud } from "react-icons/fi";
import { MdUpdate } from "react-icons/md";

const LeftMenuStyled = styled.div`
  position: absolute;
  left: -1vw;
  top: 9rem;
  width: 13rem;
  min-height: 29rem;
  border-radius: 0 2rem 2rem 0;
  background: rgb(68, 68, 68);
  background: linear-gradient(
    270deg,
    rgba(68, 68, 68, 1) 0%,
    rgba(1, 1, 1, 1) 100%
  );
  box-shadow: 3px 1px 30px 8px rgba(255, 255, 255, 0.35);
  z-index: 150;

  @media screen and (max-width: 800px) {
    height: 60%;
  }
`;

const MenuList = styled.ul`
  position: relative;
  margin-top: 1rem;
  overflow: none;
  left: 0;
  max-height: 100% - 5rem;
  padding-inline-start: 0;
  display: flex;
  align-items: left;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: rgb(225, 225, 225, 0.7);
  list-style: none;
  text-decoration: none;

  & a {
    color: rgb(225, 225, 225, 0.7);
    text-decoration: none;
    display: flex;
  }
`;

const Item = styled.li`
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 10px 0 10px 25px;
  height: 3rem;
  transition: all 0.3s linear;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.3),
    0 0 5px rgba(255, 255, 255, 0.2), 0 0 7px rgba(255, 255, 255, 0.1),
    0 0 11px rgb(246, 161, 35, 0.25), 0 0 15px rgb(246, 161, 35, 0.2);
  padding-bottom: 4px;

  @media screen and (min-width: 800px) {
    &:hover {
      background: rgb(225, 225, 225, 0.05);
      transition: all 0.3s linear;
    }
  }

  @media screen and (max-width: 800px) {
    &:hover {
      background: rgb(225, 225, 225, 0.05);
      transition: all 0.3s linear;
    }
  }
`;
const MenuItem = styled.li`
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 10px 0 10px 25px;
  height: 3rem;
  transition: all 0.3s linear;
  padding-bottom: 4px;
  background: rgb(225, 225, 225, 0.05);

  @media screen and (min-width: 800px) {
    &:hover {
      background: rgb(225, 225, 225, 0.05);
      transition: all 0.3s linear;
    }
  }
`;

const BottomMenuList = styled.ul`
  position: absolute;
  overflow: none;
  left: 0;
  bottom: 1rem;
  padding-inline-start: 0;
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: rgb(225, 225, 225, 0.7);
  list-style: none;
  text-decoration: none;

  & a {
    color: rgb(225, 225, 225, 0.7);
    text-decoration: none;
    display: flex;
  }
`;

const CloseBtnStyled = styled.button`
  position: relative;
  right: -10rem;
  top: 8px;
  color: rgba(255, 225, 225, 0.7);
  padding-right: 6rem;
  border: none;
  background: transparent;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    color: rgba(218, 14, 7, 0.8);
    transition: all 0.3s linear;
  }

  & svg {
    width: 24px;
  }
`;

const Icon = styled.svg`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  width: 32px;
  color: rgb(255, 153, 0, 0.7);
  transition: all 0.3s linear;
`;
const dropIn = {
  hidden: {
    translateX: "-208px",
    opacity: 0,
  },
  visible: {
    translateX: "0px",
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.15,
      duration: 0.4,
    },
  },
  exit: {
    translateX: "-208px",
    opacity: 0,
  },
};

const LeftMenu = ({ handleClose }) => {
  return (
    <>
      <Backdrop onClick={handleClose}>
        <LeftMenuStyled
          as={motion.div}
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <CloseBtnStyled onClick={handleClose}>
            <CgCloseO />
          </CloseBtnStyled>
          <MenuList>
            <MenuItem>
              <Icon>
                <GiArrowDunk />
              </Icon>
              &nbsp; About{" "}
            </MenuItem>
            <Link to="/swap/changelog" onClick={handleClose}>
              <Item>
                <Icon>
                  <MdUpdate />
                </Icon>
                &nbsp; Changelog
              </Item>
            </Link>
            <Link to="/swap/swapInfo" onClick={handleClose}>
              <Item>
                <Icon>
                  <GiTrade />
                </Icon>
                &nbsp; Swap
              </Item>
            </Link>
            <Link to="/swap/setupInfo" onClick={handleClose}>
              <Item>
                <Icon>
                  <ImCog />
                </Icon>
                &nbsp; Setup
              </Item>
            </Link>
            <Link to="/swap/toolsInfo" onClick={handleClose}>
              <Item>
                <Icon>
                  <FaTools />
                </Icon>
                &nbsp; Tools
              </Item>
            </Link>
            <Link to="/swap/advancedInfo" onClick={handleClose}>
              <Item>
                <Icon>
                  <ImPower />
                </Icon>
                &nbsp; Advanced
              </Item>
            </Link>
          </MenuList>
          <BottomMenuList>
            <Link to="/swap/mint" onClick={handleClose}>
              <Item>
                <Icon>
                  <GiHammerDrop />
                </Icon>
                &nbsp; Mint BSC
              </Item>
            </Link>
            <Link to="/swap/downloads" onClick={handleClose}>
              <Item>
                <Icon>
                  <FiDownloadCloud />
                </Icon>
                &nbsp; Download
              </Item>
            </Link>
          </BottomMenuList>
        </LeftMenuStyled>
      </Backdrop>
    </>
  );
};

export default LeftMenu;
