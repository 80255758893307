import React from "react";

const List = ({ children }) => {
  return (
    <div className='promoDiv'>
      {children}
    </div>
  );
};

export default List;