import React, { useState, useEffect } from "react";
import { Contract, ethers } from "ethers";
import {
  cleanCheck,
  checkAddress,
  getPrices,
  getMaxChars,
  initProxy,
  initPricebot,
} from "../helpers/helpers";

import {
  Title,
  Container,
  PromoInput,
  Input,
  Input2,
  Row,
  Select,
  ConfirmBtn,
  Pad,
  RefLink,
} from "../Styles";
import { Notyf } from "notyf";
import "../../bots/components/notification.css";
import { VscCheckAll } from "react-icons/vsc";
import ConnectBtn from "./ConnectBtn";

const CreatePromo = ({ isConnected, signer }) => {
  const emptyRef = {
    referrerName: "0",
    refAddress: ethers.constants.AddressZero,
    tgHandle: "",
    customFee: "0",
    status: false,
  };

  const [refObject, setRef] = useState(emptyRef);
  const [showError, setShowError] = useState(false);
  const [prices, setPrices] = useState({
    price1: "",
    price2: "",
    price3: "",
    price4: "",
  });
  const [maxChars, setMaxChars] = useState(55);

  var fullLink = window.location.href;
  var refName = fullLink.replace("http://localhost:3000/promo/ref/", "");
  // var refName = fullLink.replace('https://preview-cacamoto.vercel.app/promo/ref/', '');

  const getReferrer = async () => {
    if (refName.length < 14) {
      let pricebotContract = await initPricebot(signer);
      try {
        let referrer = await pricebotContract.refMap(refName);
        setRef({
          referrerName: refName,
          refAddress: referrer.refAddress,
          tgHandle: referrer.tgHandle,
          customFee: referrer.customFee,
          status: referrer.status,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (signer) {
      getPrices(signer, setPrices);
      getMaxChars(signer, setMaxChars);
    }
    getReferrer();
  }, [signer]);

  const notyf = new Notyf({
    duration: 8000,
    position: {
      x: "right",
      y: "top",
    },
    dismissible: true,
    ripple: true,
    types: [
      {
        type: "error",
        background: "#262626e1",
      },
      {
        type: "warning",
        background: "#262626e1",
        icon: false,
        duration: 20000,
      },
      {
        type: "success",
        background: "#262626e1",
        duration: 300000,
      },
    ],
  });

  const createNewPromo = async () => {
    if (showError && document.getElementById("contractAddress").value !== "") {
      return notyf.error("Invalid contract address! Try again");
    }
    if (document.getElementById("contractAddress").value === "") {
      return notyf.error("Type in contract address!");
    }
    if (refObject.status === false && refObject.referrerName !== "0") {
      return notyf.error(
        "Wrong referrer link! Contact your referrer or administration."
      );
    }
    if (
      document.getElementById("website").value === "" ||
      document.getElementById("website").value === null
    ) {
      return notyf.error("You must enter link of your socials or website!");
    }
    if (
      document.getElementById("message").value === "" ||
      document.getElementById("message").value === null
    ) {
      return notyf.error("Input your promotion message!");
    }

    let message = document.getElementById("message").value;
    let website = document.getElementById("website").value;
    let contractAddress = ethers.utils.getAddress(
      document.getElementById("contractAddress").value
    );
    let duration = document.getElementById("duration").value;
    let ref = refObject.referrerName;
    let priority;

    if (document.getElementById("priority").value !== "") {
      priority = ethers.utils.parseUnits(
        document.getElementById("priority").value
      );
    } else {
      priority = ethers.utils.parseUnits("0");
    }
    let proxyContract = await initProxy(signer);
    let price;
    if (duration == 0) {
      price = ethers.utils.parseUnits(prices.price1);
      duration = 28800;
    }
    if (duration == 1) {
      price = ethers.utils.parseUnits(prices.price2);
      duration = 28800 * 2;
    }
    if (duration == 2) {
      price = ethers.utils.parseUnits(prices.price3);
      duration = 28800 * 3;
    }
    if (duration == 3) {
      price = ethers.utils.parseUnits(prices.price4);
      duration = 28800 * 7;
    }

    try {
      let tx = await proxyContract.initiatePromotion(
        priority,
        duration,
        message,
        website,
        contractAddress,
        ref,
        {
          value: price.add(priority),
          gasLimit: 1000000,
        }
      );
      var TxHash = tx.hash;
      var receipt = await tx.wait();
      if (receipt.status === 1) {
        const notification = notyf.success(
          "You have successfully ordered <b>Proficy Price Bot PROMOTION</b>! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
      if (receipt.status === 0) {
        const notification = notyf.error(
          "Transaction failed! Click on this notification to check transaction receipt."
        );
        notification.on("click", ({ target, event }) => {
          window.open("https://bscscan.com/tx/" + TxHash, "_blank");
        });
        return notification;
      }
    } catch (err) {
      if (err.code === 4001) {
        return notyf.error("User denied transaction signature.");
      }
      if (err.code === -32602) {
        return notyf.error("Invalid parameters.");
      }
      if (err.code === -32603) {
        return notyf.error("Internal error.");
      } else {
        console.log(err);
      }
    }
  };

  return (
    <Container>
      <Title>Create New Promotion</Title>
      <PromoInput
        textAlign="left"
        placeholder={"Promotion Message (max. " + maxChars + " characters.)"}
        spellCheck="false"
        maxLength={maxChars}
        id="message"
      ></PromoInput>
      <Row>
        <Input placeholder="Link to Your Project" id="website"></Input>
        <Input2
          placeholder="Contract Address"
          id="contractAddress"
          onChange={() => {
            checkAddress(
              document.getElementById("contractAddress").value,
              setShowError
            );
          }}
        ></Input2>
      </Row>
      <Row>
        <Input
          placeholder="Priority Fee (BNB)"
          id="priority"
          onChange={async () => {
            document.getElementById("priority").value = await cleanCheck(
              document.getElementById("priority").value
            );
          }}
        ></Input>
        <Select className="dropdown" id="duration">
          <option value="0" defaultValue={"0"}>
            1 Day - {prices.price1} BNB
          </option>
          <option value="1">2 Day - {prices.price2} BNB</option>
          <option value="2">3 Day - {prices.price3} BNB</option>
          <option value="3">1 Week - {prices.price4} BNB</option>
        </Select>{" "}
      </Row>
      {refObject.status === true && refObject.referrerName !== "0" ? (
        <RefLink>
          (Referred by:&nbsp;
          <b
            onClick={() => {
              window.open("https://t.me/" + refObject.tgHandle, "_blank");
            }}
          >
            {refObject.referrerName}
          </b>
          )
        </RefLink>
      ) : (
        <></>
      )}
      {isConnected ? (
        <ConfirmBtn
          onClick={() => {
            createNewPromo();
          }}
        >
          <VscCheckAll />
          &nbsp;Submit Promotion
        </ConfirmBtn>
      ) : (
        <ConnectBtn />
      )}
      <Pad />
    </Container>
  );
};

export default CreatePromo;
