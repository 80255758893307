import React, { useState, useEffect } from "react";
import CreatePromo from "./components/CreatePromo";
import UserPromos from "./components/UserPromos";
import { useWeb3React } from "@web3-react/core";
import { initPricebot } from "./helpers/helpers";

import { Dash, User, UserBtn, Scrollable, InfoDiv } from "./Styles";

const MakePromo = () => {
  const [showCreate, setShowCreate] = useState(true);
  const [showUser, setShowUser] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState();
  const [signer, setSigner] = useState();
  const [provider, setProvider] = useState();
  const [chainID, setChainID] = useState();
  const [userPromos, setUserPromos] = useState();

  const web3reactContext = useWeb3React();

  const connect = async () => {
    if (web3reactContext.active) {
      setIsConnected(true);
      setAccount(web3reactContext.account);
      setProvider(web3reactContext.library);
      setChainID(web3reactContext.chainId);
      setSigner(web3reactContext.library.getSigner().connectUnchecked());
    } else {
      setIsConnected(false);
      setAccount();
      setProvider();
      setChainID();
      setSigner();
    }
  };

  async function getUserPromos() {
    if (web3reactContext.active) {
      try {
        let pricebotContract = await initPricebot(
          web3reactContext.library.getSigner().connectUnchecked()
        );
        let newPromos = await pricebotContract.getUserPromos(
          web3reactContext.account
        );
        setUserPromos(newPromos);
        return newPromos;
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    getUserPromos();
  }, [
    web3reactContext.account,
    web3reactContext.activate,
    web3reactContext.chainId,
    web3reactContext.deactivate,
    showCreate,
    showUser,
  ]);

  useEffect(() => {
    connect();
  }, [web3reactContext.account]);

  return (
    <>
      <Scrollable>
        <Dash>
          <User>
            <UserBtn
              onClick={() => {
                setShowUser(true);
                setShowCreate(false);
              }}
            >
              My Promos
            </UserBtn>
            <UserBtn
              onClick={() => {
                setShowUser(false);
                setShowCreate(true);
              }}
            >
              Create New
            </UserBtn>
          </User>
          {showCreate && (
            <CreatePromo isConnected={isConnected} signer={signer} />
          )}
          {showUser && (
            <UserPromos
              userPromos={userPromos}
              account={account}
              provider={provider}
              signer={signer}
            />
          )}
          <InfoDiv>
            <ul>
              <li>Priority works like this blabla bla bla blabla bla ble</li>
              <li>We will vet it</li>
              <li>Depending on your priority etc</li>
            </ul>
          </InfoDiv>
        </Dash>
      </Scrollable>
    </>
  );
};

export default MakePromo;
