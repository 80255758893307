import React from "react";
import SwapPanel from './SwapPanel';
import BotsPanel from './BotsPanel';
import PromoPanel from "./PromoPanel";
import styled from "styled-components";
import AnimatedPage from '../../elements/AnimatedPage';
import AnimatedPage2 from '../../elements/AnimatedPage2';

const ChooseApp = styled.div`
    position: relative;
    left: 0;
    top: 4.9rem;
    width: 100%;
    height: calc(100% - 8rem);
    padding: 2rem 12% 2rem 12%;
    scrollbar-width: none;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 20;

    &::-webkit-scrollbar {
      display: none;
    }

@media screen and (max-width: 480px) {
    .choose-app {
        height: 90%;
    }
}

@media screen and (min-width: 1400px) {
    .choose-app {
        overflow-y: hidden;
        height: 80%;
    }
}
`

function AppSelect() {
    return (
       <ChooseApp>
    <AnimatedPage><BotsPanel /></AnimatedPage>
    <AnimatedPage><SwapPanel /></AnimatedPage>
    {/* <AnimatedPage2><PromoPanel /></AnimatedPage2> */}
        </ChooseApp>
    );
}

export default AppSelect