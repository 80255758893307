import styled from "styled-components";

export const BotCard = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background: linear-gradient(
    210deg,
    rgba(68, 68, 68, 0.9) 0%,
    rgba(11, 11, 11, 0.9) 100%
  );
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-radius: 2em;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  height: 30rem;
  z-index: 100;
  transition: all 0.3s linear;

  &:hover {
    & h1 {
      opacity: 1;
      transform: scale(1.05);
      transition: all 0.3s linear;
    }

    & img {
      opacity: 1;
      transition: all 0.3s linear;
    }

    & span {
      opacity: 0.9;
      transition: all 0.3s linear;
    }
  }

  @media screen and (max-width: 820px) {
    position: absolute;
    width: 90vw;
    left: 5vw;
    max-height: 30rem;
    & h1 {
      opacity: 1;
      transform: scale(1.05);
      transition: all 0.3s linear;
    }

    & img {
      opacity: 1;
      transition: all 0.3s linear;
    }

    & span {
      opacity: 0.9;
      transition: all 0.3s linear;
    }
  }

  @media screen and (min-width: 800px) {
    width: 40vw;
  }
`;
export const CardTitle = styled.h1`
  display: flex;
  text-transform: uppercase;
  color: rgb(255, 225, 225, 0.8);
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6),
    0 0 5px rgba(255, 255, 255, 0.4), 0 0 7px rgba(255, 255, 255, 0.2),
    0 0 11px rgb(246, 161, 35, 0.5), 0 0 15px rgb(246, 161, 35, 0.4);
  font-weight: 800;
  font-size: 24px;
  line-height: 0;
  letter-spacing: 0.1rem;
  z-index: 100;
  opacity: 0.6;
  transform: scale(1);
  transition: all 0.3s linear;
`;

export const CardTitle2 = styled.div`
  display: flex;
  color: rgb(255, 225, 225, 0.8);
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.6),
    0 0 5px rgba(255, 255, 255, 0.4), 0 0 7px rgba(255, 255, 255, 0.2),
    0 0 11px rgb(246, 161, 35, 0.5), 0 0 15px rgb(246, 161, 35, 0.4);
  font-weight: 800;
  font-size: 24px;
  letter-spacing: 0.1rem;
  z-index: 100;
  opacity: 0.6;
  transform: scale(1);
  transition: all 0.3s linear;

  @media screen and (max-width: 820px) {
    width: 70%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 92%;
  border: 2px solid rgb(225, 225, 225, 0.2);
  border-radius: 20px;
  border-left: none;
  border-right: none;
  background: rgb(0, 0, 0, 0.2);
`;
export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 12%;
  width: 92%;
`;
export const Row3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  height: 40%;
  width: 92%;
  background: rgb(225, 225, 225, 0.05);
  color: rgb(255, 165, 0, 0.9);
  border-radius: 20px;
  border: solid 2px rgb(255, 165, 0, 0.2);
  border-bottom: none;
  border-top: none;

  @media screen and (max-width: 820px) {
    font-size: 14px;
    padding-left: 2%;
    padding-right: 2%;
  }
`;

export const Row4 = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 90%;
  padding: 15px 5px 0 10px;
  margin-right: 4%;
  border: solid 2px rgb(255, 165, 0, 0.2);
  border-radius: 20px;
  border-left: none;
  border-right: none;
  background: rgb(225, 225, 225, 0.01);
`;

export const RefInfo = styled.div`
  position: absolute;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 82%;
  padding: 15px 0 0 10px;
  margin-right: 4%;
  border-radius: 30px;
  border: none;
  background: rgb(68, 68, 68);
  background: linear-gradient(
    270deg,
    rgba(68, 68, 68, 1) 0%,
    rgba(1, 1, 1, 1) 100%
  );
  box-shadow: 2px 2px 2px 2px rgba(255, 255, 255, 0.08);
  z-index: 100;

  & ul {
    display: flex;
    justify-content: top;
    align-items: center;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 8px;
    padding-top: 8px;
    width: 95%;
    height: 70%;
    font-size: 15px;
    font-weight: 400;
    color: rgb(225, 225, 225, 0.9);
    text-decoration: none;

    @media screen and (max-width: 820px) {
      font-size: 14px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & li {
    list-style: url(/img/bullet.svg);
    list-style-position: inside;
    width: 100%;
    padding: 5px 0 0 10px;
    transition: all 0.3s linear;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 5px rgba(255, 255, 255, 0.07), 0 0 7px rgba(255, 255, 255, 0.03),
      0 0 11px rgb(246, 161, 35, 0.08), 0 0 15px rgb(246, 161, 35, 0.07);
  }

  & b {
    font-weight: 600;
    color: rgb(255, 165, 0, 0.8);
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 2rem;
  width: 2rem;
  border: none;
  background: none;
  color: rgb(255, 2, 2, 0.7);
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 820px) {
    top: 10px;
    right: 10px;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  color: rgb(225, 225, 225, 0.6);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transform: scale(1);
  transition: all ease-in 0.3s;

  &:hover {
    color: rgb(255, 165, 0, 0.8);
    transform: scale(1.05);
    transition: all ease-in 0.3s;
  }

  @media screen and (max-width: 820px) {
    font-size: 14px;
  }
`;

export const Checkbox = styled.input.attrs({
  type: "radio",
})`
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  opacity: 0;
`;

export const Checkmark = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: solid 2px rgb(255, 165, 0, 0.2);
  background: rgb(225, 225, 225, 0.1);
  pointer-events: none;

  ${Checkbox}:checked + && {
    background: rgb(255, 165, 0, 0.9);
  }

  @media screen and (max-width: 820px) {
    width: 16px;
    height: 16px;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 90%;
  border: solid 2px rgb(255, 165, 0, 0.2);
  border-radius: 20px;
  border-left: none;
  border-right: none;
  background: rgb(225, 225, 225, 0.01);
  font-size: 12rem;
  color: rgb(255, 165, 0, 0.6);
`;

export const Price = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 165, 0, 0.4);
  @media screen and (max-width: 820px) {
    font-size: 12px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 165, 0, 0.4);
`;

export const Text2 = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: rgb(255, 165, 0, 0.4);
`;

export const Minted = styled.div`
  align-self: center;
  font-size: 14px;
  color: rgb(225, 225, 225, 0.4);
`;

export const Input = styled.input`
  resize: none;
  min-width: 38%;
  min-height: 3rem;
  max-width: 38%;
  max-height: 3rem;
  padding: 5px;
  padding-left: 10px;
  background: rgb(0, 0, 0, 0.2);
  border: solid 2px rgb(255, 165, 0, 0.4);
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  color: rgb(225, 225, 225, 0.8);
  & :focus {
    background-color: rgb(0, 0, 0, 0.2);
  }
`;

export const MintBtn = styled.button`
  align-items: center;
  min-width: 40%;
  min-height: 3rem;
  max-width: 40%;
  max-height: 3rem;
  padding: 12px;
  margin-left: 8%;
  border: solid 2px rgb(255, 165, 0, 0.4);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    padding: 10px;
  }
`;

export const RenewBtn = styled.button`
  margin-top: 1rem;
  align-items: center;
  min-width: 60%;
  min-height: 3rem;
  max-width: 80%;
  max-height: 3rem;
  border: solid 2px rgb(255, 165, 0, 0.4);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    padding: 10px;
  }
`;

export const QuestionBtn = styled.button`
  width: 10%;
  height: 60%;
  color: rgb(255, 153, 0, 0.8);
  font-size: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transform: scale(1);
  transition: all ease-in 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all ease-in 0.3s;
  }
`;
export const QuestionBtn2 = styled.button`
  width: 10%;
  height: 60%;
  color: rgb(255, 153, 0, 0.8);
  font-size: 14px;
  border: none;
  background: none;
  cursor: pointer;
  transform: scale(1);
  transition: all ease-in 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all ease-in 0.3s;
  }
`;
export const SubText = styled.div`
  position: absolute;
  color: rgba(225, 225, 225, 0.6);
  font-size: 12px;
  bottom: 5.1rem;
  left: 6%;
`;
