import React, { useState, useEffect} from "react";
import Tilt from 'react-parallax-tilt';
import { BotCard, CardImg, CardTitle, ETHPrice, ETHAmount, ETHMinted, InfoBtn, MintBtn } from "../StyledCard";
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import Abi from '../../web3/abi/Abi.json';
import { Notyf } from 'notyf';
import '../components/notification.css';
import { motion, AnimatePresence } from "framer-motion";
import { BrowserView, MobileView } from 'react-device-detect';
import styled from "styled-components";
import { CgCloseO } from 'react-icons/cg';
import { MdOutlineInfo } from 'react-icons/md';
import parse from 'html-react-parser';
import { HashLink } from "react-router-hash-link";

const PresaleProInfoMenu = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
    width: 18rem;
    height: 30rem;
    border-radius: 2em;
    background: rgb(68,68,68);
    background: linear-gradient(270deg, rgba(68,68,68,1) 0%, rgba(1,1,1,1) 100%);
    box-shadow: 3px 1px 30px 8px rgba(255,255,255,0.15);
    z-index: 150;

    & ul {
    position: relative;
    display: flex;
    justify-content: top;
    align-items: center;
    left: 0;
    padding-inline-start: 0;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    width: 88%;
    height: 78%;
    margin: 12% 6% 10% 6%;
    font-size: 15px;
    font-weight: 400;
    color: rgb(225, 225, 225, 0.9);
    text-decoration: none;

    &::-webkit-scrollbar {
      display: none;
    }

    }

    & li {
    list-style: url(/img/bullet.svg);
    list-style-position: inside;
    width: 100%;
    padding: 5px 0 0 10px;
    transition: all 0.3s linear;
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.1),
    0 0 5px rgba(255, 255, 255, 0.07),
    0 0 7px rgba(255, 255, 255, 0.03),
    0 0 11px rgb(246, 161, 35,0.08),
    0 0 15px rgb(246, 161, 35,0.07);
    padding-bottom: 4px;
    }
`
const CloseBtnStyled = styled.button`
    position: absolute;
    right: 20px;
    top: 8px;
    width: 30px;
    height: 26px;
    color: rgba(255, 225, 225, 0.7);
    border: none;
    background: transparent;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
        color: rgba(218, 14, 7, 0.8);
        transition: all 0.3s linear;
    }
`

const FullInfoBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.2rem;
    right: 15%;
    width: 70%;
    height: 3rem;
    margin-bottom: 1rem;
    background: rgba(225, 225, 225, 0.05);
    border: none;
    box-shadow: 0px -1px 35px 12px rgba(0,0,0,0.25);
    border-radius: 15px;
    color: rgba(225, 225, 225, 0.7);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s linear;

    & a {
      color: rgba(225, 225, 225, 0.7);
      text-decoration: none;
    }

    &:hover {
        background: rgba(225, 225, 225, 0.1);
        transition: all 0.3s linear;
    }

`

const Icon = styled.svg`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: -60px;
    font-size: 28px;
    width: 32px;
    color: rgb(255, 153, 0, 0.7);
    transition: all 0.3s linear;
`

const dropInInfo = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
        }
    },
    exit: {
        opacity: 0
    },
};


const BotCards = ({ cardData, id1, children }) => {

  const {
    title,
    contractAddress,
    logo,
    moreInfo,
    about
  } = cardData;

    const web3reactContext = useWeb3React(); 
    const [data, setData] = useState({ maxSupply: "...", minted: "...", price: "..." });
    const WSS = 'https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4';

    const provider = new ethers.providers.JsonRpcProvider(WSS);
    const contract = new ethers.Contract(contractAddress, Abi, provider);
    var ChainID = web3reactContext.chainId;
    const notyf = new Notyf({
        duration: 8000,
        position: {
          x: 'right',
          y: 'top',
        },
        dismissible: true,
        ripple: true,
        types: [
            {
                type: 'error',
                background: '#262626e1',
              },
              {
                type: 'warning',
                background: '#262626e1',
                icon: false,
                duration: 20000,
              },
              {
                type: 'success',
                background: '#262626e1',
                duration: 300000,
              }
            ]
    });

    const QuickInfo = ({ handleClose }) => {
      return (
          <>
          <PresaleProInfoMenu
          as={motion.div}
          onClick={(e) => e.stopPropagation()}
          variants={dropInInfo}
          initial="hidden"
          animate="visible"
          exit="exit"
          >    
           <CloseBtnStyled onClick={handleClose}><CgCloseO /></CloseBtnStyled> 
              {parse(moreInfo)}
              <FullInfoBtn>
                <Icon><MdOutlineInfo /></Icon><HashLink smooth to={about}>More Info</HashLink>
              </FullInfoBtn>
          </PresaleProInfoMenu>
          </>
      );
    };

    function MoreInfoBtn() {

      const [MoreInfoOpen, setMoreInfoOpen] = useState(false);
      const close = () => setMoreInfoOpen(false);
      const open = () => setMoreInfoOpen(true);
    
      return (
          <>
          <InfoBtn
          as={motion.button} 
        onClick={() => (MoreInfoOpen ? close() : open())} >
          ABOUT
          </InfoBtn>
          { MoreInfoOpen && <QuickInfo MoreInfoOpen={MoreInfoOpen} handleClose={close}/>}
          <AnimatePresence
        initial={false}
        exitBeforeEnter={false}
        onExitComplete={() => null}
        >
         { MoreInfoOpen && <QuickInfo MoreInfoOpen={MoreInfoOpen} handleClose={close}/>}
        </AnimatePresence>
    
          </>
      );
    }

    async function getData() {
        const maxSupply = await contract.MAX();
        const minted = await contract.totalSupply();
        const price = await contract.price();
        setData({
            maxSupply: Number(maxSupply),
            minted: Number(minted),
          price: ethers.utils.formatEther(price),
        });
      }

      var mint = async () => {

        var getContract = (library, account) => {
          var signer = library.getSigner(account).connectUnchecked();
          var contract = new ethers.Contract(contractAddress, Abi, signer);
          return contract;
        };
        try {
          var myContract = getContract(web3reactContext.library, web3reactContext.account);
          var response = await myContract.mintBIG('0',{
                value: ethers.utils.parseUnits (data.price),
                gasLimit: 300000,
              })
          var TxHash = response.hash;
          var receipt = await response.wait(); 
         if (receipt.status === 1) {       
          const notification = notyf.success("You successfully minted <b>YOUR OWN BOT NFT</b>! Click on this notification to check transaction receipt.");
          notification.on('click', ({target, event}) => {
           window.open('https://etherscan.io/tx/' + (TxHash), '_blank');
         });
         return (
          notification
         );
         }
         if (receipt.status === 0) {
          const notification = notyf.error('Transaction failed! Click on this notification to check transaction receipt.');
          notification.on('click', ({target, event}) => {
            window.open('https://etherscan.io/tx/' + (TxHash), '_blank');
          });
          return (
           notification
          );
         }
        } catch (err) {
          if (err.code === 4001) {
            return (
              notyf.error('User denied transaction signature.')
            );
          }
          if (err.code === -32602) {
            return (
              notyf.error('Invalid parameters.')
            );
          }
          if (err.code === -32603) {
            return (
              notyf.error('Internal error.')
            );
          }
          else {
          console.log(err);
          }
        }
      };

      useEffect(() => {
          getData();
      }, []);

      function ConnectionError() {
          return(
            notyf.error('Connect your wallet to ETH Mainnet first')
          );
      }

    return(

      <>
      <BrowserView>
        <React.Fragment key={`ETHcard_${id1}`}>
        <Tilt className="bot-card" tiltMaxAngleX={5} tiltMaxAngleY={5} glareEnable={false} glareMaxOpacity={0.15} glareBorderRadius="2rem" 
        transitionSpeed={600} transitionEasing={true} >
            <BotCard >
                <CardTitle>{title}</CardTitle>
                <CardImg src={logo} />
                <ETHPrice>PRICE:<ETHAmount>{data.price} ETH</ETHAmount></ETHPrice>
                <ETHMinted>AVAILABLE: <ETHAmount>{data.maxSupply - data.minted}</ETHAmount></ETHMinted>     
                <MoreInfoBtn />
                {web3reactContext.active && ChainID ===1 ? <MintBtn onClick={mint} >MINT</MintBtn> : <MintBtn onClick={ConnectionError} >MINT</MintBtn>}
             </BotCard>
        </Tilt>
        </React.Fragment>
        </BrowserView>
        <MobileView>
          <React.Fragment key={`ETHcardM_${id1}`}>
          <div className="bot-card">
            <BotCard >
                <CardTitle>{title}</CardTitle>
                <CardImg src={logo} />
                <ETHPrice>PRICE:<ETHAmount>{data.price} ETH</ETHAmount></ETHPrice>
                <ETHMinted>AVAILABLE: <ETHAmount>{data.maxSupply - data.minted}</ETHAmount></ETHMinted>     
                <MoreInfoBtn />
                {web3reactContext.active && ChainID ===1 ? <MintBtn onClick={mint} >MINT</MintBtn> : <MintBtn onClick={ConnectionError} >MINT</MintBtn>}
             </BotCard>   
             </div>
             </React.Fragment>     
        </MobileView>
        </>
    );
}

export default BotCards