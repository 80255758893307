import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { GiHammerDrop, GiMiner } from 'react-icons/gi';
import { MdOutlineInfo } from 'react-icons/md';
import { FaCodeBranch, FaQuestionCircle } from 'react-icons/fa';
import { FiDownloadCloud } from 'react-icons/fi';
import { SiGitbook } from 'react-icons/si';

const LeftMenuStyled = styled.div`
    position: fixed;
    left: -1vw;
    top: 9rem;
    width: 13rem;
    min-height: 23rem;
    border-radius: 0 2rem 2rem 0;
    background: rgb(68,68,68);
    background: linear-gradient(270deg, rgba(68,68,68,0.4) 0%, rgba(1,1,1,0.4) 100%);
    box-shadow: 3px 1px 30px 8px rgba(255,255,255,0.15);
    z-index: 150;

    @media screen and (max-width: 800px) {
        height: 60%;
    }
`

const MenuList = styled.ul`
    position: relative;
    margin-top: 1rem;
    overflow: none;
    left: 0;
    padding-inline-start: 0;
    display: flex;
    align-items: left;
    flex-direction: column;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: rgb(225, 225, 225, 0.7);
    list-style: none;
    text-decoration: none;


    & a {
        color: rgb(225, 225, 225, 0.7);
        text-decoration: none;
        display: flex;
    }
`

const Item = styled.li`
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 10px 0 10px 25px;
    height: 3rem;
    transition: all 0.3s linear;
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.3),
    0 0 5px rgba(255, 255, 255, 0.2),
    0 0 7px rgba(255, 255, 255, 0.1),
    0 0 11px rgb(246, 161, 35,0.25),
    0 0 15px rgb(246, 161, 35,0.2);
    padding-bottom: 4px;

    @media screen and (min-width: 800px) {
        &:hover {
        background: rgb(225, 225, 225, 0.05);
        transition: all 0.3s linear;
    }
    }

    @media screen and (max-width: 800px) {
        &:hover {
        background: rgb(225, 225, 225, 0.05);
        transition: all 0.3s linear;
        }
    }
`
const CloseBtnStyled = styled.button`
    position: relative;
    right: -10rem;
    top: 8px;
    color: rgba(255, 225, 225, 0.7);
    padding-right: 6rem;
    border: none;
    background: transparent;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
        color: rgba(218, 14, 7, 0.8);
        transition: all 0.3s linear;
    }

    & svg {
        width: 24px;
    }
`

const Icon = styled.svg`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    width: 32px;
    color: rgb(255, 153, 0, 0.7);
    transition: all 0.3s linear;
`

const Nav = () => {
    return (
        <>
        <LeftMenuStyled>    
            <MenuList>
                <Link to="/bots/" ><Item><Icon><GiHammerDrop /></Icon>&nbsp; Mint BSC</Item></Link>
                <Link to="/bots/mintEth" ><Item><Icon><GiMiner /></Icon>&nbsp; Mint ETH </Item></Link>
                <a href='https://github.com/proficy/documentation' target='_blank' rel="noreferrer" ><Item><Icon><SiGitbook /></Icon>&nbsp; Guides </Item></a>
                <Link to="/bots/downloads" ><Item><Icon><FiDownloadCloud /></Icon>&nbsp; Downloads </Item></Link>
                <Link to="/bots/FAQ" ><Item><Icon><FaQuestionCircle /></Icon>&nbsp; FAQ </Item></Link>
                <Link to="/bots/development" ><Item><Icon><FaCodeBranch /></Icon>&nbsp; Development </Item></Link>
                <Link to="/bots/about" ><Item><Icon><MdOutlineInfo /></Icon>&nbsp; About </Item></Link>
            </MenuList>
        </LeftMenuStyled>
        </>
    );
};

export default Nav