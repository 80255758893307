import styled from "styled-components";


export const BotCard = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(210deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 2em;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    width: 18rem;
    height: 30rem;
    z-index: 100;
    transition: all 0.3s linear;

    &:hover {
        & h1 {
            opacity: 1;
            transform: scale(1.05);
            transition: all 0.3s linear;
        }

        & img {
            opacity: 1;
            transition: all 0.3s linear;
        }

        & span {
            opacity: 0.9;
            transition: all 0.3s linear; 
        }
    }

    @media screen and (max-width: 800px) {
        max-height: 30rem;
        & h1 {
            opacity: 1;
            transform: scale(1.05);
            transition: all 0.3s linear;
        }

        & img {
            opacity: 1;
            transition: all 0.3s linear;
        }

        & span {
            opacity: 0.9;
            transition: all 0.3s linear; 
        }
    }
`
export const CardTitle = styled.h1`
    position: absolute;
    top: 0;
    padding-top: 5px;
    text-transform: uppercase;
    color: rgb(255, 225, 225, 0.8);
    text-shadow:
            0 0 2px rgba(255, 255, 255, 0.6),
            0 0 5px rgba(255, 255, 255, 0.4),
            0 0 7px rgba(255, 255, 255, 0.2),
            0 0 11px rgb(246, 161, 35,0.5),
            0 0 15px rgb(246, 161, 35,0.4);
    font-weight: 800;
    font-size: 24px;
    line-height: 0;
    letter-spacing: 0.1rem;
    z-index: 100;
    opacity: 0.6;
    transform: scale(1);
    transition: all 0.3s linear;
`
export const CardImg = styled.img`
    position: absolute;
    top: 40px;
    width: 14.5rem;
    height: 20.8rem;
    border-radius: 2rem;
    opacity: 0.8;
    transition: all 0.3s linear;
    box-shadow: 0px 0px 35px 20px rgba(0,0,0,0.2);

`
export const Price = styled.span`
    position: absolute;
    bottom: 72px;
    left: 65px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(255, 225, 225);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    transition: all 0.3s linear;
`

export const ETHPrice = styled.span`
    position: absolute;
    bottom: 72px;
    left: 65px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(0, 189, 240);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    transition: all 0.3s linear;
`

export const Minted = styled.span`
    position: absolute;
    bottom: 52px;
    left: 65px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(255, 225, 225);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    transition: all 0.3s linear;
`
export const ETHMinted = styled.span`
    position: absolute;
    bottom: 52px;
    left: 65px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(0, 189, 240);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    transition: all 0.3s linear;
`

export const Amount = styled.span`
    padding-left: 8px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(255, 165, 0);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    transition: all 0.3s linear;
`
export const ETHAmount = styled.span`
    padding-left: 8px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(0, 189, 240);
    text-shadow:
    0 0 2px rgba(255, 255, 255, 0.15),
    0 0 5px rgba(255, 255, 255, 0.1),
    0 0 7px rgba(255, 255, 255, 0.05),
    0 0 11px rgb(246, 161, 35,0.12),
    0 0 15px rgb(246, 161, 35,0.1);
    opacity: 0.7;
    transition: all 0.3s linear;
`

export const InfoBtn = styled.button`
    position: absolute;
    left: 2px;
    bottom: 1px;
    margin: 0 0 8px 8px;
    background: rgb(225, 225, 225, 0.2);
    border: none;
    box-shadow: 0px 0px 35px 20px rgba(0,0,0,0.35);
    border-radius: 10px 10px 10px 25px;
    background-clip: padding-box;
    width: 45%;
    height: 2.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(255, 225, 225, 0.7);
    cursor: pointer;
    z-index: 99;
    transform: scale(1);
    transition: all 0.3s linear;

    @media screen and (max-width: 800px) {
        &:hover {
            text-shadow: none;
            transition: all 0.3s linear;
        }
    }

    &:hover {
        transform: scale(1.05);
        transition: all 0.3s linear;
    }
`

export const MintBtn = styled.button`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 2px;
    bottom: 1px;
    margin: 0 8px 8px 0;
    background: rgb(225, 225, 225, 0.2);
    border: none;
    box-shadow: 0px 0px 35px 20px rgba(0,0,0,0.35);
    border-radius: 10px 10px 25px 10px;
    background-clip: padding-box;
    width: 45%;
    height: 2.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(255, 225, 225, 0.7);
    cursor: pointer;
    z-index: 99;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
        transform: scale(1.05);
        transition: all 0.3s linear;
    }
`