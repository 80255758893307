import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { RiMenu4Fill } from 'react-icons/ri';
import LeftMenu from "../LeftMenu";

const Btn1 = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 5px;
    top: 3rem;
    font-size: 26px;
    padding: 4px;
    color: rgb(225, 225, 225, 0.6);
    background: transparent;
    border: transparent;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
        color: rgb(255, 153, 0, 0.9);
        transform: rotate(180deg) scale(1.2);
    }
`

function LeftMenuBtn() {

    const [LeftMenuOpen, setLeftMenuOpen] = useState(false);
    const close = () => setLeftMenuOpen(false);
    const open = () => setLeftMenuOpen(true);

    return (
        <>
        <Btn1
        as={motion.button} 
      onClick={() => (LeftMenuOpen ? close() : open())} >
            <RiMenu4Fill />
        </Btn1>
        { LeftMenuOpen && <LeftMenu LeftMenuOpen={LeftMenuOpen} handleClose={close}/>}
        <AnimatePresence
      initial={false}
      exitBeforeEnter={false}
      onExitComplete={() => null}
      >
       { LeftMenuOpen && <LeftMenu LeftMenuOpen={LeftMenuOpen} handleClose={close}/>}
      </AnimatePresence>

        </>
    );
}

export default LeftMenuBtn