import styled from "styled-components";

export const ChoosePanel = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16rem;
    height: 20rem;
    margin: 1rem;
    transition: all 0.3s linear;
`

export const LandingPanel = styled.div`
@media screen and (min-width: 800px) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(360deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 2em;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    width: 16rem;
    height: 20rem;
    z-index: 100;
    transition: all 0.3s linear;

    &:hover {
        & h1 {
            transform: scale(1.05);
            opacity: 1;
            transition: all 0.3s linear;
        }

        & div {
            color: rgb(255, 165, 0, 0.6);
            transition: all 0.3s linear;
        }

        & span {
            color: rgb(255, 225, 225, 0.8);
            transition: all 0.3s linear;
        }
    }
}

@media screen and (max-width: 800px) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(360deg, rgba(68,68,68,0.9) 0%, rgba(11,11,11,0.9) 100%);
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 2em;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    width: 16rem;
    height: 20rem;
    z-index: 100;
    transition: all 0.3s linear;

    & h1 {
            transform: scale(1.05);
            opacity: 1;
            transition: all 0.3s linear;
        }

        & div {
            color: rgb(255, 165, 0, 0.6);
            transition: all 0.3s linear;
        }

        & span {
            color: rgb(255, 225, 225, 0.8);
            transition: all 0.3s linear;
        }
}
`
export const LandingLogo = styled.div`
    position: absolute;
    top: 10px;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    font-size: 6rem;
    color: rgb(255, 165, 0, 0.3);
    z-index: 100;
    transition: all 0.3s linear;
`

export const LandingLogo2 = styled.div`
    position: absolute;
    top: 1.5rem;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    font-size: 6rem;
    color: rgb(255, 165, 0, 0.3);
    z-index: 100;
    transition: all 0.3s linear;
`
export const LandingName = styled.h1`
    position: absolute;
    text-transform: uppercase;
    top: 3rem;
    margin-top: 5px;
    left: 4.8rem;
    text-align: center;
    width: 200px;
    color: rgb(255, 225, 225, 0.8);
    text-shadow:
            0 0 2px rgba(255, 255, 255, 0.6),
            0 0 5px rgba(255, 255, 255, 0.4),
            0 0 7px rgba(255, 255, 255, 0.2),
            0 0 11px rgb(246, 161, 35,0.5),
            0 0 15px rgb(246, 161, 35,0.4);
    font-weight: 800;
    font-size: 28px;
    line-height: 0;
    letter-spacing: 0.1rem;
    z-index: 100;
    opacity: 0.6;
    transform: scale(1);
    transition: all 0.3s linear;
`

export const LandingName2 = styled.h1`
    position: absolute;
    text-transform: uppercase;
    top: 5rem;
    margin-top: 5px;
    left: 4.8rem;
    text-align: center;
    width: 200px;
    color: rgb(255, 225, 225, 0.8);
    text-shadow:
            0 0 2px rgba(255, 255, 255, 0.6),
            0 0 5px rgba(255, 255, 255, 0.4),
            0 0 7px rgba(255, 255, 255, 0.2),
            0 0 11px rgb(246, 161, 35,0.5),
            0 0 15px rgb(246, 161, 35,0.4);
    font-weight: 800;
    font-size: 28px;
    line-height: 0;
    letter-spacing: 0.1rem;
    z-index: 100;
    opacity: 0.6;
    transform: scale(1);
    transition: all 0.3s linear;
`

export const LandingInfo = styled.span`
    position: absolute;
    padding: 20px 5px 2px 5px;
    width: 80%;
    height: 42%;
    font-size: 200;
    font-size: 14px;
    color: rgb(255, 225, 225, 0.6);
    transition: all 0.3s linear;
`
export const LandingInfo2 = styled.span`
    position: absolute;
    padding: 2rem 5px 2px 5px;
    width: 80%;
    height: 42%;
    font-size: 200;
    font-size: 14px;
    color: rgb(255, 225, 225, 0.6);
    transition: all 0.3s linear;
`

export const LandingBtn = styled.button`
    position: absolute;
    right: 4rem;
    bottom: 0;
    margin-bottom: 1rem;
    background: rgb(225, 225, 225, 0.3);
    border: none;
    box-shadow: 0px 0px 35px 20px rgba(0,0,0,0.35);
    border-radius: 2rem;
    background-clip: padding-box;
    width: 8rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(255, 225, 225, 0.7);
    cursor: pointer;
    z-index: 99;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
        background: rgba(167, 167, 167, 0.3);
        transform: scale(1.05);
        transition: all 0.3s linear;
    }

    & a {
        color: rgb(255, 225, 225, 0.7);
        text-decoration: none;
    }
`