import React, { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import WalletModal from '../WalletModal';
import { useWeb3React } from '@web3-react/core';
import { FaUserAstronaut } from 'react-icons/fa';
import { BiWallet } from 'react-icons/bi';


const ConnectButton = styled.button`
  position: absolute; 
  display: inline-flex;
  align-items: center;
  right: 0;
  bottom: 15px;
  padding: 12px;
  margin: 0 15px 0 0;
  border: 2px solid rgb(90, 90, 90);
  border-radius: 8px 8px 15px 8px;
  background: rgba(0, 0, 0, 0.05);
  color: rgb(170, 170, 170);
  font-weight: 600;
  cursor: pointer;
  font-size: 19px;
  transform: scale(1);
  transition: all 0.3s linear;

  &:hover {
    color: rgb(255, 153, 0, 0.9);
    transform: scale(1.05);
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    padding: 10px;
  }
`

function ConnectBtn() {

  const [WalletModalOpen, setWalletModalOpen] = useState(false);
  const close = () => setWalletModalOpen(false);
  const open = () => setWalletModalOpen(true);
  const web3reactContext = useWeb3React(); 

    return(
      <div>
      <ConnectButton as={motion.button} 
      onClick={() => (WalletModalOpen ? close() : open())} >
        {web3reactContext.active ? <><FaUserAstronaut color='rgb(255, 153, 0, 0.7)' transform='scale(1.15)' />&nbsp;Connected</>
         : <> <BiWallet transform='scale(1.5)' color='rgb(255, 153, 0, 0.7)' />&nbsp;Connect</>}
      </ConnectButton>  
      { WalletModalOpen && <WalletModal WalletModalOpen={WalletModalOpen} handleClose={close}/>}
      <AnimatePresence
      initial={false}
      exitBeforeEnter={false}
      onExitComplete={() => null}
      >
       { WalletModalOpen && <WalletModal WalletModalOpen={WalletModalOpen} handleClose={close}/>}
      </AnimatePresence>
      </div>
    );
}

export default ConnectBtn