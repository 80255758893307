import styled from "styled-components";

export const Wrapper = styled.div`
  @media screen and (max-width: 800px) {
    position: absolute;
    top: 0;
    width: 95%;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 0 0 74px 0;
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
  @media screen and (min-width: 800px) {
    position: absolute;
    top: 0;
    left: 13rem;
    margin: auto;
    min-height: 100vh;
    width: calc(100vw - 13rem);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 0 74px 0;
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    overflow: hidden;
    z-index: 4;
  }
`;

export const Pager = styled.div`
  position: absolute;
  left: 14rem;
  top: 1rem;
  width: calc(100% - 16rem);
  height: calc(100% - 6rem);
  @media screen and (max-width: 820px) {
    width: 90%;
    height: 90%;
    left: 5%;
  }
`;

export const BG = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.background})`};

  @media screen and (min-width: 1200px) {
    height: 85%;
    width: 100%;
  }
  @media screen and (min-width: 820px) and (max-width: 1200px) {
    height: 85%;
    width: 100%;
  }
  @media screen and (max-width: 820px) {
    height: 80%;
  }
`;

export const PageTitle = styled.h1`
  @media screen and (max-width: 800px) {
    position: relative;
    margin: 0;
    padding: 1rem;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 165, 0, 0.8);
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.45),
      0 0 5px rgba(255, 255, 255, 0.3), 0 0 7px rgba(255, 255, 255, 0.15),
      0 0 11px rgb(246, 161, 35, 0.35), 0 0 15px rgb(246, 161, 35, 0.3);
    opacity: 0.9;
  }
  @media screen and (min-width: 800px) {
    position: relative;
    margin: 0;
    padding: 1rem;
    text-align: center;
    position: relative;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(255, 165, 0, 0.8);
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.45),
      0 0 5px rgba(255, 255, 255, 0.3), 0 0 7px rgba(255, 255, 255, 0.15),
      0 0 11px rgb(246, 161, 35, 0.35), 0 0 15px rgb(246, 161, 35, 0.3);
    opacity: 0.9;
  }
`;

export const Info = styled.div`
  @media screen and (max-width: 800px) {
    position: relative;
    width: 85vw;
    min-height: 4rem;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;

    & b {
      font-weight: 600;
    }

    & a {
      color: rgb(255, 225, 225, 0.9);
      text-decoration: none;
      border-bottom: 1px dotted;
    }

    & ul {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0 0 20px 1rem;
      color: rgb(225, 225, 225, 0.7);
      font-size: 14px;
    }
    & img {
      width: 100%;
      border-radius: 8px;
      margin: 8px 0;
      align-self: center;
    }
  }

  @media screen and (min-width: 800px) {
    position: relative;
    width: 60%;
    min-height: 4rem;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;

    & b {
      font-weight: 600;
    }

    & a {
      color: rgb(255, 225, 225, 0.9);
      text-decoration: none;
      border-bottom: 1px dotted;
    }

    & ul {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0 0 20px 1rem;
      color: rgb(225, 225, 225, 0.7);
      font-size: 14px;
    }
    & img {
      width: 100%;
      border-radius: 8px;
      margin: 8px 0;
      align-self: center;
    }
  }
`;

export const Abouts = styled.div`
  @media screen and (max-width: 800px) {
    position: relative;
    width: 85vw;
    min-height: 4rem;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;

    & b {
      font-weight: 600;
    }

    & a {
      color: rgb(255, 87, 51, 0.9);
      text-decoration: none;
      border-bottom: 1px dotted;
    }

    & ul {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0 0 20px 1rem;
      color: rgb(225, 225, 225, 0.7);
      font-size: 14px;
    }
    & img {
      width: 100%;
      border-radius: 8px;
      margin: 8px 0;
      align-self: center;
    }
  }

  @media screen and (min-width: 800px) {
    position: relative;
    width: 60%;
    min-height: 4rem;
    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      210deg,
      rgba(68, 68, 68, 0.9) 0%,
      rgba(11, 11, 11, 0.9) 100%
    );
    border: solid 2px rgb(255, 165, 0, 0.4);
    border-radius: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;

    & b {
      font-weight: 600;
    }

    & a {
      color: rgb(250, 159, 0, 0.7);
      text-decoration: none;
      border-bottom: 1px dotted;
    }

    & ul {
      position: relative;
      width: 100%;
      margin: 0 0 20px 0;
      padding: 0 0 0 1rem;
      color: rgb(225, 225, 225, 0.7);
      font-size: 14px;
    }
    & img {
      width: 80%;
      border-radius: 20px;
      margin: 8px 0;
      align-self: center;
    }

    & span {
      width: 100%;
      align-self: left;
      margin-bottom: 10px;
    }
  }
`;

export const Spacer = styled.div`
  width: 100%;
  height: 1rem;
`;

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const RightArrow = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 50%;
  right: 1rem;
  color: rgb(225, 225, 225, 0.6);
  font-size: 50px;
  transform: scale(1);
  cursor: pointer;
  transition: all 0.3s ease-in;
  z-index: 100;

  &:hover {
    transition: all 0.3s ease-in;
    color: rgb(255, 165, 0, 0.7);
    transform: scale(1.05);
  }
`;

export const LeftArrow = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 50%;
  left: 1rem;
  color: rgb(225, 225, 225, 0.6);
  font-size: 50px;
  transform: scale(1);
  cursor: pointer;
  transition: all 0.3s ease-in;
  z-index: 100;

  &:hover {
    transition: all 0.3s ease-in;
    color: rgb(255, 165, 0, 0.7);
    transform: scale(1.05);
  }
`;

export const DotsDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - 10rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 20rem;
  height: 4rem;
  padding-left: 5rem;
  padding-right: 5rem;
`;

export const Dot = styled.button`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background: rgb(255, 225, 225, 0.4);
  transform: scale(1);
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover {
    transition: all 0.3s ease-in;
    background: rgb(255, 165, 0, 0.7);
    transform: scale(1.05);
  }
`;

export const DotHighlighted = styled.button`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background: rgb(255, 165, 0, 0.7);
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.45),
    0 0 5px rgba(255, 255, 255, 0.3), 0 0 7px rgba(255, 255, 255, 0.15),
    0 0 11px rgb(246, 161, 35, 0.35), 0 0 15px rgb(246, 161, 35, 0.3);
  cursor: pointer;
`;

export const Title = styled.h1`
  text-align: center;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  color: rgb(255, 165, 0, 0.6);
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.18),
    0 0 5px rgba(255, 255, 255, 0.11), 0 0 7px rgba(255, 255, 255, 0.07),
    0 0 11px rgb(246, 161, 35, 0.11), 0 0 15px rgb(246, 161, 35, 0.11);
  opacity: 0.6;
`;

export const Single = styled.div`
  @media screen and (min-width: 800px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const Cut = styled.div`
  width: 80%;
  margin-left: 10%;
`;

export const Row = styled.div`
  flex-direction: row;
`;
