import React, { Suspense, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Html, Icosahedron, useCubeTexture, MeshDistortMaterial, CubeCamera
} from "@react-three/drei";
import '../style.css';

function MainSphere({ material }) {
  const main = useRef();

  useFrame(({ clock }) => {
    main.current.rotation.z = clock.getElapsedTime();
    main.current.rotation.y = 3;
    main.current.rotation.x = 2;
  });
  
  return (
    <Icosahedron frames={1} args={[1, 8]} ref={main} material={material} position={[0, 0, 0]} />
  );
}

function Instances({ material }) {
  const [sphereRefs] = useState(() => []);
  const initialPositions = [
    [-4, 20, -12],
    [-10, 12, -4],
    [-11, -12, -23],
    [-16, -6, -10],
    [12, -2, -3],
    [13, 4, -12],
    [14, -2, -23],
    [8, 10, -20]
  ];
  useFrame(() => {
    sphereRefs.forEach((el) => {
      el.position.y +=  0.02;
      if (el.position.y > 19) el.position.y = -18;
      el.rotation.x +=  0.06;
      el.rotation.y += 0.06;
      el.rotation.z += 0.02;
    });
  });
  return (
    <>
      <MainSphere material={material} />
      {initialPositions.map((pos, i) => (
        <Icosahedron
          args={[1, 4]}
          position={[pos[0], pos[1], pos[2]]}
          material={material}
          key={i}
          ref={(ref) => (sphereRefs[i] = ref)}
        />
      ))}
    </>
  );
}

function Scene() {
  const envMap = useCubeTexture(
    ["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"],
    { path: "/img/cube/" }
  );
  const [material, set] = useState();

  return (
    <>
      <MeshDistortMaterial
        ref={set}
        envMap={envMap}
        color={"#010101"}
        roughness={0.05}
        metalness={1}
        clearcoat={1}
        clearcoatRoughness={1}
        radius={1}
        distort={0.4}
      />
      {material && <Instances material={material} />}
    </>
  );
}


function Background() {
  return (
    <Canvas
      camera={{ position: [0, 0, 3], }}
        gl={{  powerPreference: "high-performance", antialias: false, stencil: false,
        depth: false }}>    
      <Suspense fallback={<Html center>...</Html>}>
        <Scene />
      </Suspense>
    </Canvas>
  );
}

export default Background